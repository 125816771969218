import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import TableChart from "@material-ui/icons/TableChart";
import Map from "@material-ui/icons/Map";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import { CSVLink } from "react-csv";
import { API_view_all_project } from "config/API.jsx";
import CryptoJS from "crypto-js";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setHouseSection1 from "DataControl/actions/setHouseSection1.js";
import setHouseSection2 from "DataControl/actions/setHouseSection2.js";
import setNoOfProjects from "DataControl/actions/setNoOfProjects.js";
import setShowModal from "DataControl/actions/setShowModal.js";
import setModalType from "DataControl/actions/setModalType.js";
import setProjectIDHome from "DataControl/actions/setProjectIDHome.js";
import setApproved from "DataControl/actions/setApproved.js";
// switch language function
import switchIntl from "translate/switchIntl.js";
import { API_deleteProject, API_gen_secretkey } from "config/API.jsx";

const white = {
  color: "white"
};

const right = {
  float: "right"
};

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const translations = {
  previousText: "ก่อนหน้า",
  nextText: "ต่อไป",
  loadingText: "กำลังโหลด...",
  noDataText: "ไม่พบข้อมูล",
  pageText: "หน้า",
  ofText: "จาก",
  rowsText: "แถว",
  // Accessibility Labels
  pageJumpText: "ไปที่หน้า",
  rowsSelectorText: "จำนวนแถวต่อหน้า"
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      data: [],
      alert: null
    };

    this.formatData = this.formatData.bind(this);
    this.formatApproval = this.formatApproval.bind(this);
  }

  componentDidMount() {
    this.addFilterPlaceholder();
    this.getToken();
  }

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData
          });
          this.loadProject();
        }
      })
      .catch(err => {});
  };

  loadProject = () => {
    var param = {
      srp_name: 0
    };

    let self = this;
    var request = new Request(API_view_all_project, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data);
          switch (data.code) {
            case 200:
              //success
              let rawData = data.data[0].jsonb_build_object.features;
              self.formatData(rawData);
              break;
            default:
              //failed

              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
    return false;
  };

  formatData(rawdata) {
    let arr = [];
    for (var i = 0; i < rawdata.length; i++) {
      let data = rawdata[i].properties;
      let saleDate = moment(data.srp_selldate).format("DD/MM/YYYY");
      if (data.srp_approved === 4) {
        arr.push([
          data.srp_projectid,
          data.srp_name,
          data.srp_phase,
          data.province_t,
          data.amphoe_t,
          data.tambon_t,
          saleDate,
          data.cstats_name,
          data.srp_approved,
          data.srp_cat
        ]);
      }
    }

    const dataTable = {
      headerRow: [
        "project_id",
        "project_name",
        "phrase",
        "prov",
        "amphoe",
        "tambon",
        "sales_date",
        "project_status",
        "approval"
      ],
      footerRow: ["Name", "Position", "Office", "Age", "Actions"],
      dataRows: arr
    };

    let data = dataTable.dataRows.map((prop, key) => {
      let no = key + 1;
      return {
        no: no,
        id: key,
        project_id: prop[0],
        project_name: prop[1],
        phrase: prop[2],
        prov: prop[3],
        amphoe: prop[4],
        tambon: prop[5],
        sales_date: prop[6],
        //project_status: this.checkProjectStatus(prop[7]),
        project_status: prop[7],
        approval: this.formatApproval(prop[8]),
        approval_id: prop[8],
        type: prop[9],
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                let obj = this.state.data.find(o => o.id === key);
                console.log(obj);
                let projectID = obj.project_id;
                let approved = obj.approval_id;
                this.props.setProjectIDHome(projectID);
                this.props.setApproved(approved);

                let modaltype = "";
                if (obj.type === 1) {
                  //Condo
                  modaltype += "ec_" + new Date().getTime();
                } else if (obj.type === 2) {
                  //Home
                  modaltype += "eh_" + new Date().getTime();
                } else {
                  //Villa
                  modaltype += "ev_" + new Date().getTime();
                }
                this.props.setModalType(modaltype);
              }}
              color={this.colorStatusEdit(prop[8])}
              disabled={this.isDisabled(prop[8])}
              className="like"
            >
              <Edit />
            </Button>{" "}
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                let obj = this.state.data.find(o => o.id === key);
                this.setState({
                  alert: (
                    <SweetAlert
                      input
                      style={{ display: "block", marginTop: "-100px" }}
                      title={
                        switchIntl(this.props.toggleLng).project
                          .home_project_list.title_name
                      }
                      placeHolder={
                        switchIntl(this.props.toggleLng).project
                          .home_project_list.msg2
                      }
                      inputType="password"
                      validationMsg={
                        switchIntl(this.props.toggleLng).project
                          .home_project_list.msg2
                      }
                      onConfirm={inputValue =>
                        this.deleteProject(
                          inputValue,
                          obj.project_id,
                          obj.approval_id
                        )
                      }
                      onCancel={() => this.hideAlert()}
                      confirmBtnText={
                        switchIntl(this.props.toggleLng).basic_word
                          .delete_project
                      }
                      cancelBtnText={
                        switchIntl(this.props.toggleLng).basic_word.cancel
                      }
                      showCancel
                    >
                      {" "}
                      {
                        switchIntl(this.props.toggleLng).project
                          .home_project_list.msg2
                      }
                    </SweetAlert>
                  )
                });
              }}
              color={prop[8] === 4 ? "danger" : "default"}
              disabled={prop[8] === 4 ? false : true}
              className="delete"
            >
              <Delete />
            </Button>{" "}
            {/* use this button to remove the data row */}{" "}
          </div>
        )
      };
    });
    this.setState({
      data: data
    });
  }

  formatApproval(data) {
    if (data === 4) {
      return "บันทึกชั่วคราว";
    } else {
      return "ส่งข้อมูลแล้ว";
    }
  }

  checkProjectStatus(data) {
    let status = [
      {
        id: 1,
        name: "ต่ำกว่า 6 ยูนิต"
      },
      {
        id: 2,
        name: "อยู่ระหว่างขาย"
      },
      {
        id: 3,
        name: "เปิดขายใหม่ในช่วงสำรวจ"
      },
      {
        id: 4,
        name: "โครงการอนาคต"
      },
      {
        id: 5,
        name: "โครงการชะลอการขาย"
      },
      {
        id: 6,
        name: "โครงการเปิดและปิดการขายในช่วงสำรวจ"
      }
    ];
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  deleteProject(inputValue, projectID, approval_id) {
    let that = this;
    var param = {
      srp_projectid: projectID,
      member_passwd: inputValue,
      srp_approved: approval_id
    };

    var request = new Request(API_deleteProject, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: that.state.tokenData
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data);
          if (data.code === 200) {
            that.loadProject();
            that.setState({
              alert: (
                <SweetAlert
                  success
                  style={{ display: "block", marginTop: "-100px" }}
                  title="ลบโครงการสำเร็จ"
                  onConfirm={() => that.hideAlert()}
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                  cancelBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.danger
                  }
                  confirmBtnText={
                    switchIntl(that.props.toggleLng).basic_word.ok
                  }
                ></SweetAlert>
              )
            });
          } else if (data.code === 402) {
            that.setState({
              alert: (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="รหัสไม่ถูกต้อง"
                  onConfirm={() => that.hideAlert()}
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                  cancelBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.danger
                  }
                  confirmBtnText={
                    switchIntl(that.props.toggleLng).basic_word.ok
                  }
                ></SweetAlert>
              )
            });
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  colorStatus(data) {
    let status = parseInt(data);
    if (status === 1 || status === 3) {
      return "white";
    } else {
      return "danger";
    }
  }

  colorStatusEdit(data) {
    let status = parseInt(data);
    if (status === 1 || status === 3) {
      return "white";
    } else {
      return "info";
    }
  }

  isDisabled(data) {
    let status = parseInt(data);
    if (status === 1 || status === 3) {
      return true;
    } else {
      return false;
    }
  }

  addFilterPlaceholder = () => {
    const filters = document.querySelectorAll("div.rt-th > input");
    for (let filter of filters) {
      filter.placeholder = "ค้นหาที่นี่..";
    }
  };

  projectType = type => {
    if (type === 1) {
      return "อาคารชุด";
    } else if (type === 2) {
      return "บ้านจัดสรร";
    } else {
      return "วิลล่า";
    }
  };

  render() {
    const { classes } = this.props;
    let data = this.state.data;
    if (this.state.search) {
      data = data.filter(row => {
        return (
          row.firstName.includes(this.state.search) ||
          row.lastName.includes(this.state.search) ||
          String(row.age).includes(this.state.search)
        );
      });
    }
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <ReactTable
            {...translations}
            data={this.state.data}
            filterable
            columns={[
              {
                Header: "ลำดับ",
                accessor: "no",
                width: 70,
                sortable: true,
                filterable: false,
                Cell: row => (
                  <div style={{ textAlign: "center" }}>{row.value}</div>
                )
              },
              {
                Header: "ชื่อโครงการ",
                accessor: "project_name",
                width: 200
              },
              {
                Header: "ประเภท",
                accessor: "type",
                Cell: row => this.projectType(row.value)
              },
              {
                Header: "เฟส",
                accessor: "phrase",
                width: 70
              },
              {
                Header: "จังหวัด",
                accessor: "prov",
                width: 100
              },
              {
                Header: "อำเภอ",
                accessor: "amphoe",
                width: 100
              },
              {
                Header: "ตำบล",
                accessor: "tambon",
                width: 100
              },
              {
                Header: "วันที่เปิดขาย (วัน/เดือน/ปี)",
                accessor: "sales_date",
                width: 100,
                Cell: row => (
                  <div style={{ textAlign: "center" }}>{row.value}</div>
                )
              },
              {
                Header: "สถานะโครงการ",
                accessor: "project_status",
                show: false,
                width: 100
              },
              {
                Header: "สถานะการส่งข้อมูล",
                accessor: "approval",
                sortable: false,
                filterable: false,
                width: 100
              },
              {
                Header: "การจัดการ",
                accessor: "actions",
                sortable: false,
                filterable: false
              }
            ]}
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={true}
            className="-striped -highlight"
          />
        </GridItem>
      </GridContainer>
    );
  }
}

ReactTables.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setHouseSection1: payload => dispatch(setHouseSection1(payload)),
  setHouseSection2: payload => dispatch(setHouseSection2(payload)),
  setNoOfProjects: payload => dispatch(setNoOfProjects(payload)),
  setShowModal: payload => dispatch(setShowModal(payload)),
  setModalType: payload => dispatch(setModalType(payload)),
  setProjectIDHome: payload => dispatch(setProjectIDHome(payload)),
  setApproved: payload => dispatch(setApproved(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReactTables));
