/*eslint-disable*/

import React from "react";
import PropTypes from "prop-types";

//External Lib
import CryptoJS from "crypto-js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import HomeType from "views/Project/Home/HomeType.jsx";
import CondoType from "views/Project/Home/CondoType.jsx";
import ProjectStatus from "views/Project/ProjectStatus.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import FormLabel from "@material-ui/core/FormLabel";
import Map from "views/Map/MapEditProject.jsx";
import ProvAmphoeTambon from "views/Project/ProvAmphoeTambon.jsx";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.jsx";
//redux
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setHomeType from "DataControl/actions/setHomeType.js";
import setLatlon from "DataControl/actions/setLatlon.js";
import setProv from "DataControl/actions/setProvAmphoeTambon.js";
import setProjectStatus from "DataControl/actions/setProjectStatus.js";
import setHouseSection1 from "DataControl/actions/setHouseSection1.js";
import setHouseSection2 from "DataControl/actions/setHouseSection2.js";
import setProjectIDHome from "DataControl/actions/setProjectIDHome.js";
import setDataForSalesTable from "DataControl/actions/setDataForSalesTable.js";

import moment from "moment";
import momentTimezone from 'moment-timezone';
import numeral from "numeral";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';

//API
import switchIntl from "translate/switchIntl.js";
import { URL_SERVER, API_saveSRP, API_gen_secretkey, API_get_project_detail, API_add_file, API_add_image } from "config/API.jsx";
import defaultImage from "assets/img/image_placeholder.png";
import defaultFile from "assets/img/image_fileholder.jpg";
import fileSuccess from "assets/img/image_fileSuccess.jpg";

import Info from "@material-ui/icons/Info";
import { API_editSRP } from "config/API";
import { BeatLoader } from "react-spinners";

const loadingCard = (
  <div className="card_loading" style={{ top: 0 }}>
    <BeatLoader
      sizeUnit={"px"}
      size={15}
      color={"#ffffff"}
      loading={true}
    />
  </div>
);

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

const initialState = {
  place: "tr",
  color: "success",
  message: "",
  project_id: null,
  project_name: "",
  project_name_state: "",
  project_phrase: "",
  project_phrase_state: "",
  salesDate: "",
  salesDate_state: "",
  assetType: 2, // 2 === house
  assetType_state: "",
  assetSubType: {
    home: 0,
    townhouse: 0,
    twinhouse: 0,
    shophouse: 0,
    land: 0
  },
  assetSubType_state: "",
  price: {
    //[min, max]
    home: [0, 0],
    townhouse: [0, 0],
    twinhouse: [0, 0],
    shophouse: [0, 0],
    land: [0, 0]
  },
  status: "",
  status_state: "",
  prov: null,
  amphoe: null,
  tambon: null,
  validate: {
    code: ""
  },
  project_lat: 0.0,
  project_lon: 0.0,
  project_lat_state: 0.0,
  project_lon_state: 0.0,
  project_road: "",
  project_road_state: "",
  project_avenue: "",
  project_avenue_state: "",
  memberID: null,
  tokenData: null,
  fileBorchure: "",
  fileBorchurePreview: defaultFile,
  fileBorchureName: "",
  imgPlace: [],
  imgPlacePreview: defaultImage,
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.setDate = this.setDate.bind(this);
    this.formatData = this.formatData.bind(this);
    this.saveData = this.saveData.bind(this);
    this.getToken = this.getToken.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }


  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  fileInput = React.createRef();
  fileimgPlace = React.createRef();
  fileInputBorchure = React.createRef();

  handleClick = () => {
    this.fileInput.current.click();
  };
  handleClickSection = refname => {
    switch (refname) {
      case "imgPlace":
        this.fileimgPlace.current.click();
        break;
      case "fileBorchure":
        this.fileInputBorchure.current.click();
        break;
      default:
        break;
    }
  };
  getFilePath = (files, name, nameState) => {
    console.log("files --- ");
    console.log(files);
    this.setState({
      [nameState + "Loading"]: true
    });
    let proId = this.props.projectIDHome;
    console.log(proId);
    var formData = new FormData();
    formData.append("id", proId);
    formData.append("attr", files);
    formData.append("form", "srp");
    console.log(formData)
    for (var pair of formData.entries()) {
      console.log(pair[0]); 
      console.log(pair[1]); 
    }
    var request = new Request(API_add_file, {
      method: "POST",
      body: formData
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson);

        this.setState({
          [name]: responseJson.data,
          [nameState + "Loading"]: false
        });
        if(responseJson.code === 200){
          this.setState({srp_file: responseJson.data});
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };
  handleFileSection = (event, name, state) => {
    event.preventDefault();
    var format = /[ !@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
      let reader = new FileReader();
      let file = event.target.files[0];

      if (event.target.files.length == 0) {
        //
      } else {
        if (format.test(file.name)) {
          this.showNotification("warning","ชื่อไฟล์ห้ามประกอบด้วยอักขระพิเศษ");
        } else {
          if (file.size < 10485760) {
            this.setState({
              [name]: file,
              [name + "Preview"]: fileSuccess,
              [name + "Name"]: file.name,
              srp_file: fileSuccess
            });
            this.getFilePath(file, name, state);
          } else {
            this.showNotification("warning","ขนาดไฟล์ต้องน้อยกว่า 10 Mb");
           // this.showNotification("success","success");
          }
        }
      }
    
  };
  handleClickSection = refname => {
    switch (refname) {
      case "imgPlace":
        this.fileimgPlace.current.click();
        break;
      case "fileBorchure":
        this.fileInputBorchure.current.click();
        break;
      default:
        break;
    }
  };

  handleImageSection = (event, name, state) => {
    event.preventDefault();
      let reader = new FileReader();
      let file = event.target.files[0];
      if (event.target.files.length == 0) {
        //
      } else {
        reader.onloadend = () => {
          let base64 = reader.result;
          this.setState({
            [name]: file,
            [name + "Preview"]: reader.result
          });
          this.getImgPath(base64.split(",")[1], state);
        };
        reader.readAsDataURL(file);
      }
  };

  getImgPath = (base64, currentState) => {
    this.setState({
      [currentState + "Loading"]: true
    });
    let body = {
      projectid: this.props.projectIDHome,
      img: [base64],
      cat: currentState,
      form: "household"
    };
    var request = new Request(API_add_image, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body)
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState({
            [currentState + "Loading"]: false,
            [currentState]: responseJson.data[0],
            srp_img: responseJson.data[0]
          });
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };


  handleRemoveSection = refname => {
    switch (refname) {
      case "imgPlace":
        this.setState({
          imgPlace: [],
          imgPlacePreview: defaultImage,
          household_saleofficeimg: ""
        });
        this.fileimgPlace.current.value = null;
        break;
      case "fileBorchure":
        this.setState({
          fileBorchure: "",
          fileBorchurePreview: defaultFile,
          file_borchure: "",
          fileBorchureName: ""
        });
        this.fileInputBorchure.current.value = null;
        break;
      default:
        break;
    }
  };

  resetState = () => {
    this.setState(initialState);
  }

  sendState() {
    return this.state;
  }

  verifyText(value) {
    var textRex = /^[A-Za-zก-๙ 0-9&*@#_-]*$/;
    if (textRex.test(value)) {
      if (value.trim().length !== 0) return true;
      else return false;
    }
    return false;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyDigit(value) {
    if (value > 0 && value < 1000) {
      return true;
    }
    return false;
  }

  isFloat(number) {
    return /^[0-9]+(\.)?[0-9]*$/.test(number);
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "checkbox":
        break;
        case "text":
          if (this.verifyText(event.target.value)) {
            this.setState({ [stateName + "_state"]: "success" });
          } else {
            this.setState({ [stateName + "_state"]: "error" });
          }
          break;
      case "text2":
        if (this.verifyText2(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "digit":
        if (this.verifyDigit(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "latlon": 
        if (event.target.value != 0) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "float":
        if (this.isFloat(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      default:
        break;
    }
    if(type==="latlon" && !/^[0-9]+(\.)?[0-9]*$/.test(event.target.value)){
    }else{
      this.setState({ [stateName]: event.target.value });
    }
  }
  isValidated = () => { //check before go to the next section
    let allHome = this.props.hometype;
    if (!allHome) {
      //if user is not select any checkbox of assets type
      this.setState({ hometype_state: "error" });
    }else {
      for (let type in allHome) {
        if(allHome[type] === 1) {
          this.setState({ hometype_state: "success" });
        }
      }
    }

    if (this.state.project_name === ""){
      this.setState({
        project_name_state: "error"
      })
    }

    if (
      this.state.project_name_state !== "error" &&
      this.state.project_lat !== 0 &&
      this.state.project_lon !== 0 &&
      this.state.hometype_state !== "error" &&
      this.state.project_road_state !== "error"
    ) {
      this.formatData();
      if (this.state.project_name === "") {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.state.project_name_state !== "success") {
        this.setState({ project_name_state: "error" });
      }
      if (this.state.assetType_state !== "success") {
        this.setState({ assetType_state: "error" });
      }
      if (this.state.project_lat == 0) {
        this.setState({ project_lat_state: "error" });
      }
      if (this.state.project_lon == 0) {
        this.setState({ project_lon_state: "error" });
      }

      if(this.state.project_road_state === "error") {
        this.setState({ project_road_state: "error" });
      }

      alert("กรุณาตรวจสอบข้อมูลก่อนบันทึกส่วนถัดไป");
      return false;
    }
    
  }
  verifyText2(value) {
    var textRex = /^[\/A-Za-zก-๙ 0-9&*@#_-]*$/;
    if (textRex.test(value)) {
      if (value.trim().length !== 0) return true;
      else return false;
    }
    return false;
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }

  updateLatlng(event, stateName) {
    this.setState({ [stateName]: event.target.value });
    var location = {
      lat: this.state.project_lat,
      lng: this.state.project_lon
    };
    this.props.setLatlon(location);
  }

  getProjectDetail = (token) => {
    let self = this;
    let projectID = self.props.projectIDHome;
    let approved = self.props.approved;
    let param = JSON.stringify({
      srp_projectid: projectID,
      srp_approved: approved
    });

    console.log(param);
    var request = new Request(API_get_project_detail, {
      method: "POST",
      headers: new Headers({ "Content-type": "application/json", token: token }),
      body: param
    });
    fetch(request)
        .then(function(response) {
         
          response.json().then(function(data) {
            console.log(data);
            if(data.code === 200) {
              if(data.datapart1.length !== 0) {
              let data_part1 = data.datapart1[0];
              let data_part2 = data.datapart2;

              self.props.setDataForSalesTable(data_part2);

              //let salesDate = moment.tz(data_part1.srp_selldate, "Asia/Bangkok").format("DD/MM/YYYY");
              //let salesDate = moment(data_part1.srp_selldate).format("DD/MM/YYYY");
              let salesDate = data_part1.srp_selldate;
              self.setDate(moment(data_part1.srp_selldate));
              self.props.setProv({
                prov_id: data_part1.srp_province,
                amp_id: data_part1.srp_district,
                tam_id: data_part1.srp_subdistrict,
                prov_name: data_part1.province_t,
                amp_name: data_part1.amphoe_t,
                tam_name: data_part1.tambon_t
              });

              var popLocation = {
                lat: data_part1.lat,
                lng: data_part1.lon
              };

              let price_data = data.datapart3;

              self.props.setLatlon(popLocation);

              self.setState({
                project_name: data_part1.srp_name,
                project_phrase: data_part1.srp_phase,
                salesDate: salesDate,
                project_lat: data_part1.lat,
                project_lon: data_part1.lon,
                project_avenue: data_part1.srp_soi,
                project_road: data_part1.srp_road,
                project_status: data_part1.srp_status,
                //chk1: บ้านจัดสรร / ห้องสตูดิโอ
                //chk2: 
                subtype: {
                  chk1: data_part1.srp_chk1,
                  chk2: data_part1.srp_chk2,
                  chk3: data_part1.srp_chk3,
                  chk4: data_part1.srp_chk4,
                  chk5: data_part1.srp_chk5
                },
                price: price_data,
                srp_img: data_part1.srp_img,
                srp_file: data_part1.srp_file
              });

              if(data_part1.srp_img[0] !== "undefined") {
                self.setState({imgPlacePreview : data_part1.srp_img[0] })
              }
              }
            }
      })
    })
      .catch(err => {
        console.log(err);
      });
  }

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
            memberID : jsonDec.member_id
          });

          this.getProjectDetail(tokenData);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  formatData() {
    let formatted_plen_from = [];
    let all_min = [];
    let all_max = [];

    let minmax = this.props.minmax_house;
    let allHome = this.props.hometype;

    if (!allHome) {
      //if user is not select any checkbox of assets type
      this.setState({ hometype_state: "error" });
    }else {
      for (let type in allHome) {
        if(allHome[type] === 1) {
          this.setState({ hometype_state: "success" });
        }
      }

      let lookup_asset = [
        { name: "บ้านเดี่ยว", value: 1,min: 0, max:0 },
        { name: "บ้านแฝด", value: 2,min: 0, max:0 },
        { name: "ทาวน์เฮาส์ หรือ ทาวน์โฮม", value: 3,min: 0, max:0 },
        { name: "อาคารพาณิชย์", value: 4 ,min: 0, max:0},
        { name: "ที่ดินเปล่า", value: 5,min: 0, max:0 }
      ];

      if (allHome.home === 1) {
        
        // all_min.push(minmax.house[0]);
        // all_max.push(minmax.house[1]);
        all_min.push(numeral(minmax.house[0]).value());
        all_max.push(numeral(minmax.house[1]).value());
        formatted_plen_from.push(1);
        lookup_asset[0].min = numeral(minmax.house[0]).value();
        lookup_asset[0].max = numeral(minmax.house[1]).value();
      } else {
        lookup_asset.splice(0,1)
      }

      if (allHome.twinhouse === 1) {
        all_min.push(numeral(minmax.twinhouse[0]).value());
        all_max.push(numeral(minmax.twinhouse[1]).value());
        formatted_plen_from.push(2);
      } else {
        lookup_asset.splice(1,1)
      }

      if (allHome.townhouse === 1) {
        all_min.push(numeral(minmax.townhouse[0]).value());
        all_max.push(numeral(minmax.townhouse[1]).value());
        formatted_plen_from.push(3);
      } else {
        lookup_asset.splice(2,1)
      }

      if (allHome.shophouse === 1) {
        all_min.push(numeral(minmax.shophouse[0]).value());
        all_max.push(numeral(minmax.shophouse[1]).value());
        formatted_plen_from.push(4);
      } else {
        lookup_asset.splice(3,1)
      }

      if (allHome.land === 1) {
        all_min.push(numeral(minmax.land[0]).value());
        all_max.push(numeral(minmax.land[1]).value());
        formatted_plen_from.push(5);
      } else {
        lookup_asset.splice(4,1);
      }

      let srp_name = this.state.project_name;
      let srp_phase = this.state.project_phrase;

      let date = moment(this.state.salesDate).format(
        "MM/DD/YYYY"
      );
      let srp_selldate = date;

      let srp_cat = this.getIDAssetType(this.props.stepProps.assetType);

      let srp_house = this.props.hometype.home;
      let srp_twinhouse = this.props.hometype.twinhouse;
      let srp_townhouse = this.props.hometype.townhouse;
      let srp_commercialbuilding = this.props.hometype.shophouse;
      let srp_land = this.props.hometype.land;
      let srp_lat = this.props.latlon.lat;
      let srp_lon = this.props.latlon.lng;
      let srp_subdistrict = this.props.prov_amphoe_tambon.tam_id;
      let srp_district = this.props.prov_amphoe_tambon.amp_id;
      let srp_province = this.props.prov_amphoe_tambon.prov_id;
      let plen_minprice = all_min;
      let plen_maxprice = all_max;
      let plen_form = formatted_plen_from;
      let srp_status = this.props.project_status;
      let userid = this.state.memberID;
      let srp_road = this.state.project_road;
      let srp_soi = this.state.project_avenue;
      let srp_map = this.props.mapImagePath;
      let srp_address = this.props.prov_amphoe_tambon.tam_name+", "+ this.props.prov_amphoe_tambon.amp_name + ", "+ this.props.prov_amphoe_tambon.prov_name;
      let srp_img = this.state.srp_img;
      let srp_file = this.state.srp_file;
      //save section1 info to state;
      let info_section1 = {
        srp_name: srp_name,
        srp_phase: srp_phase,
        srp_selldate: srp_selldate,
        srp_cat: srp_cat,
        srp_chk1: srp_house,
        srp_chk2: srp_twinhouse,
        srp_chk3: srp_townhouse,
        srp_chk4: srp_commercialbuilding,
        srp_chk5: srp_land,
        srp_lat: srp_lat,
        srp_lon: srp_lon,
        srp_subdistrict: srp_subdistrict,
        srp_district: srp_district,
        srp_province: srp_province,
        plen_minprice: plen_minprice,
        plen_maxprice: plen_maxprice,
        plen_form: plen_form,
        srp_status: srp_status,
        srp_userid: userid,
        srp_projectid: this.props.projectIDHome,
        lookup_asset: lookup_asset,
        srp_address: srp_address,
        srp_road: srp_road,
        srp_soi: srp_soi,
        srp_map: srp_map,
        srp_img: srp_img,
        srp_file: srp_file
      };

      this.props.setHouseSection1(info_section1);
    }
  }

  getIDAssetType = (code) => {
    if(code.includes("c")) {
      return 1;
    }else if(code.includes("h")) {
      return 2;
    } else {
      return 3;
    }
  }

  saveData = e => {
    let self = this;
    
    let srp_phrase = this.state.project_phrase;
    if (!srp_phrase) {
      srp_phrase = "";
    }

    let formatted_plen_from = [];
    let all_min = [];
    let all_max = [];

    let minmax = this.props.minmax_house;
    let allHome = this.props.hometype;

    if (!allHome) {
      //if user is not select any checkbox of assets type
      this.setState({ hometype_state: "error" });
    } else {
      this.setState({ hometype_state: "success" });
      this.setState( {salesDate_state: "success" } );

      let lookup_asset = [
        { name: "บ้านเดี่ยว", value: 1,min: 0, max:0 },
        { name: "บ้านแฝด", value: 2,min: 0, max:0 },
        { name: "ทาวน์เฮาส์ หรือ ทาวน์โฮม", value: 3,min: 0, max:0 },
        { name: "อาคารพาณิชย์", value: 4 ,min: 0, max:0},
        { name: "ที่ดินเปล่า", value: 5,min: 0, max:0 }
      ];

      if (allHome.home === 1) {
        all_min.push(numeral(minmax.house[0]).value());
        all_max.push(numeral(minmax.house[1]).value());
        formatted_plen_from.push(1);
        lookup_asset[0].min = numeral(minmax.house[0]).value();
        lookup_asset[0].max = numeral(minmax.house[1]).value();
      } else {
        lookup_asset.splice(0,1)
      }

      if (allHome.twinhouse === 1) {
        all_min.push(numeral(minmax.twinhouse[0]).value());
        all_max.push(numeral(minmax.twinhouse[1]).value());
        formatted_plen_from.push(2);
      } else {
        lookup_asset.splice(1,1)
      }

      if (allHome.townhouse === 1) {
        all_min.push(numeral(minmax.townhouse[0]).value());
        all_max.push(numeral(minmax.townhouse[1]).value());
        formatted_plen_from.push(3);
      } else {
        lookup_asset.splice(2,1)
      }

      if (allHome.shophouse === 1) {
        all_min.push(numeral(minmax.shophouse[0]).value());
        all_max.push(numeral(minmax.shophouse[1]).value());
        formatted_plen_from.push(4);
      } else {
        lookup_asset.splice(3,1)
      }

      if (allHome.land === 1) {
        all_min.push(numeral(minmax.land[0]).value());
        all_max.push(numeral(minmax.land[1]).value());
        formatted_plen_from.push(5);
      } else {
        lookup_asset.splice(4,1)
      }

      let date = moment(this.state.salesDate).format(
        "MM/DD/YYYY"
      );

      let srp_selldate = date;
      let srp_cat = this.getIDAssetType(this.props.stepProps.assetType);
      let srp_house = this.props.hometype.home;
      let srp_twinhouse = this.props.hometype.twinhouse;
      let srp_townhouse = this.props.hometype.townhouse;
      let srp_commercialbuilding = this.props.hometype.shophouse;
      let srp_land = this.props.hometype.land;
      let srp_lat = this.props.latlon.lat;
      let srp_lon = this.props.latlon.lng;
      let srp_subdistrict = this.props.prov_amphoe_tambon.tam_id;
      let srp_district = this.props.prov_amphoe_tambon.amp_id;
      let srp_province = this.props.prov_amphoe_tambon.prov_id;
      let plen_minprice = all_min;
      let plen_maxprice = all_max;
      let plen_form = formatted_plen_from;
      let srp_status = this.props.project_status;
      let userid = this.state.memberID
      let srp_road = this.state.project_road;
      let srp_soi = this.state.project_avenue;
      let srp_map = this.props.mapImagePath;
      let srp_img = this.props.srp_img;
      let srp_file = this.props.srp_file;

      let tmpdata_section1 = {
        srp_name: this.state.project_name,
        srp_phase: srp_phrase,
        srp_selldate: srp_selldate,
        srp_cat: srp_cat,
        srp_chk1: srp_house,
        srp_chk2: srp_twinhouse,
        srp_chk3: srp_townhouse,
        srp_chk4: srp_commercialbuilding,
        srp_chk5: srp_land,
        srp_lat: srp_lat,
        srp_lon: srp_lon,
        srp_subdistrict: srp_subdistrict,
        srp_district: srp_district,
        srp_province: srp_province,
        plen_minprice: plen_minprice,
        plen_maxprice: plen_maxprice,
        plen_form: plen_form,
        srp_status: srp_status,
        srp_userid: userid,
        srp_projectid: this.props.projectIDHome,
        lookup_asset: lookup_asset,
        srp_address: "",
        srp_soi: srp_soi,
        srp_road: srp_road,
        srp_map: srp_map,
        srp_img: srp_img,
        srp_file: srp_file
      };

      let tmpdata_section2 = this.props.houseInfoSection2;
      if (!tmpdata_section2) {
        tmpdata_section2 = {
          srpunit_cat: [0],
          srpunit_pricelenid: [0],
          srpunit_all: [0],
          srpunit_sellaccum: [0],
          srpunit_sell: [0],
          srpunit_transfer: [0],
          srpunit_leftselltransfer: [0],
          srpunit_leftsell: [0]
        };
      }
      let tmpdata_all = Object.assign({}, tmpdata_section1, tmpdata_section2);
      console.log(tmpdata_all);

      let URL = API_saveSRP;
      if(srp_status != 4 ) {
        URL = API_editSRP;
      } 

      var request = new Request(URL, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: self.state.tokenData
        }),
        body: JSON.stringify(tmpdata_all)
      });
      fetch(request)
        .then(function(response) {
          response.json().then(function(data) {
            console.log(data);
            switch (data.code) {
              case 200:
                //success
                self.setState({
                  alert: (
                    <SweetAlert
                      success
                      style={{ display: "block", marginTop: "-100px" }}
                      title={""}
                      onConfirm={() => self.hideAlert()}
                      onCancel={() => self.hideAlert()}
                      confirmBtnText={
                        switchIntl(self.props.toggleLng).basic_word.ok
                      }
                    >
                      {" "}
                      {switchIntl(self.props.toggleLng).basic_word.save_success}
                    </SweetAlert>
                  )
                });
                break;
              default:
                self.setState({
                  alert: (
                    <SweetAlert
                      warning
                      style={{ display: "block", marginTop: "-100px" }}
                      title={""}
                      onConfirm={() => self.hideAlert()}
                      onCancel={() => self.hideAlert()}
                      confirmBtnText={
                        switchIntl(self.props.toggleLng).basic_word.ok
                      }
                    >
                      {" "}
                      {switchIntl(self.props.toggleLng).basic_word.save_failed}
                    </SweetAlert>
                  )
                });
                break;
            }
          });
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  };

  setDate = date => {
    console.log(date);
    try {
      // const valueOfInput = date.format("DD/MM/YYYY");
      // this.setState({
      //   salesDate: valueOfInput
      // });

      this.setState({
        salesDate: date
      });
    } catch (err) {}

  };

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.getToken();
  }

  componentWillUnmount() {
    console.log("unmount section 1");
    this.resetState();
  }

  showDescription = () => {
    this.setState( {showDescription: true} );
  }
  hideDescription = () => {
    this.setState( {showDescription: false} );
  }

  render() {
    const { classes } = this.props;
    const { imgPlace, imgPlacePreview, fileBorchure, fileBorchurePreview,fileBorchureName }= this.state
    return (
      <GridContainer justify="center">
        {this.state.alert}
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        <GridItem xs={12} sm={5}>
          <h3>
            <b>ข้อมูลโครงการ</b>
          </h3>
          <CustomInput
            labelText={<span>รหัสอ้างอิง</span>}
            id="code"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.props.projectIDHome,
              disabled: true
            }}
          />
          <CustomInput
            success={this.state.project_name_state === "success"}
            error={this.state.project_name_state === "error"}
            labelText={<span>ชื่อโครงการ</span>}
            id="project_name"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            inputProps={{
              onChange: event => this.change(event, "project_name", "text"),
              value: this.state.project_name
            }}
          />
          <CustomInput
            success={this.state.project_phrase_state === "success"}
            error={this.state.project_phrase_state === "error"}
            labelText={<span>เฟสที่ <Info onMouseEnter={this.showDescription} onMouseLeave={this.hideDescription}></Info></span>}
            id="project_phrase"
            formControlProps={{
              fullWidth: true,
              required: false
            }}
            inputProps={{
              onChange: event => this.change(event, "project_phrase", "text"),
              value: this.state.project_phrase
            }}
          />
          {this.state.showDescription && 
          <div style={{borderStyle: 'solid', borderColor: 'grey' ,padding: '10px'}}>
              <h4 style={{color: 'black'}}>คำอธิบาย เฟส</h4>
              <p style={{color: 'black'}}>กรณีที่เป็นบ้านจัดสรรโครงการเดียวกัน แต่เปิดขายในแต่ละเฟสต่างวันที่กัน ต้องแยกเป็นเฟสใหม่ เรียงตามลำดับวันที่เปิดขาย </p> 
              <p style={{color: 'black'}}>กรณีที่เป็นอาคารชุดโครงการเดียวกัน แต่เปิดขายแต่ละเฟส/อาคารต่างวันที่กัน ต้องแยกเป็นเฟสใหม่ เรียงตามลำดับวันที่เปิดขาย</p> 
            </div>
          }
          <h4>วันที่เปิดขาย</h4>
          <FormControl fullWidth>
            {this.state.salesDate_state === "error" || this.state.salesDate === "" ? (
              <p style={{ color: "red" }}> กรุณาระบุวันที่เปิดขาย</p>
            ) : null}

            {/* <Datetime
              locale={'th'}
              timeFormat={false}
              dateFormat="DD/MM/YYYY"
              onChange={this.setDate}
              selected={this.state.salesDate}
              inputProps={{
                placeholder: "วันที่เปิดขาย (วัน/เดือน/ปี)",
                required: true,
                readOnly: true,
                value:this.state.salesDate
              }}
            /> */}
            <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
            <DatePicker
              disableToolbar
              label=""
              format="DD/MM/YYYY"
              pickerHeaderFormat="ddd D MMM"
              yearOffset={543}
              variant="inline"
              onChange={this.setDate}
              value={this.state.salesDate}
            />
            </MuiPickersUtilsProvider>
          </FormControl>
          <br />
          <br />
          {this.state.hometype_state === "error" ? (
            <p style={{ color: "red" }}>กรุณาระบุประเภทที่อยู่อาศัยในโครงการ </p>
          ) : null}
          { !this.props.stepProps.assetType.includes("c") ?
          <HomeType assetType={this.props.stepProps.assetType} assetSubtype={this.state.subtype} price={this.state.price}></HomeType>
          : <CondoType assetType={this.props.stepProps.assetType} assetSubtype={this.state.subtype} price={this.state.price}></CondoType>
          }
          <ProjectStatus selectedStatus={this.state.project_status}></ProjectStatus>
        </GridItem>
        <GridItem xs={12} sm={7}>
          <GridItem xs={6} sm={6}>
            <h4>
              <b>ที่ตั้งโครงการ</b>
            </h4>
            <h5>พิกัดทางภูมิศาสตร์</h5>
          </GridItem>
          <GridContainer>
            <GridItem xs={6} sm={6}>
              <CustomInput
                success={this.state.project_lat_state === "success"}
                error={this.state.project_lat_state === "error"}
                labelText={<span>ละติจูด</span>}
                id="latitude"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "project_lat", "latlon"),
                  onBlur: event => this.updateLatlng(event, "project_lat"),
                  // eslint-disable-next-line react/prop-types
                  //value: this.props.latlon.lat
                  value: this.state.project_lat
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6}>
              <CustomInput
                success={this.state.project_lon_state === "success"}
                error={this.state.project_lon_state === "error"}
                labelText={<span>ลองจิจูด</span>}
                id="longitude"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "project_lon", "latlon"),
                  onBlur: event => this.updateLatlng(event, "project_lon"),
                  // eslint-disable-next-line react/prop-types
                  //value: this.props.latlon.lng
                  value: this.state.project_lon
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <ProvAmphoeTambon></ProvAmphoeTambon>
            </GridItem>
            <GridItem xs={6} sm={6}>
              <CustomInput
                success={this.state.project_road_state === "success"}
                error={this.state.project_road_state === "error"}
                labelText={<span>ถนน</span>}
                id="street"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                  this.change(event, "project_road", "text2"),
                  value: this.state.project_road
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6}>
              <CustomInput
                success={this.state.project_avenue_state === "success"}
                error={this.state.project_avenue_state === "error"}
                labelText={<span>ซอย</span>}
                id="avenue"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                    this.change(event, "project_avenue", "text"),
                  value: this.state.project_avenue
                }}
              />
            </GridItem>
            <br /> <br /> <br />
            <GridItem xs={12} sm={12}>
              <Map></Map>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridItem xs={3} sm={3} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px"
                  }}
                >
                  สำนักงานขาย (1 รูปภาพ ขนาดไม่เกิน 10mb *.jpg .png)
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={event =>
                      this.handleImageSection(
                        event,
                        "imgPlace",
                        "household_saleofficeimg"
                      )
                    }
                    ref={this.fileimgPlace}
                  />
                  <div className={"resize_imageupload thumbnail"}>
                    <img key={imgPlace} src={imgPlacePreview} alt="..." />
                    {this.state.household_saleofficeimgLoading
                      ? loadingCard
                      : null}
                  </div>
              
                  <div>
                    {imgPlace.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("imgPlace")}
                      >
                        เลือกรูปใหม่
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClickSection("imgPlace")}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemoveSection("imgPlace")}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
          <GridItem xs={3} sm={3} md={3} lg={3}>
            <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px"
                  }}
                >
                  ไฟล์โครงการ (โบรชัวร์ / อื่นๆ .rar หรือ .zip) : 
                  <a href={ URL_SERVER + this.state.srp_file } target="_blank"  download style={{color: "blue"}}> ดาวน์โหลดไฟล์ </a>
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                    onChange={event =>
                      this.handleFileSection(
                        event,
                        "fileBorchure",
                        "file_borchure"
                      )
                    }
                    ref={this.fileInputBorchure}
                  />
                  <div className={"resize_imageupload thumbnail"}>
                    <img
                      key={fileBorchure}
                      src={fileBorchurePreview}
                      alt="..."
                    />
                    <p className="file_name">{fileBorchureName}</p>
                    {this.state.file_borchureLoading ? loadingCard : null}
                  </div>
                  <div>
                    {fileBorchure.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("fileBorchure")}
                      >
                        เลือกไฟล์ใหม่
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() =>
                            this.handleClickSection("fileBorchure")
                          }
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() =>
                            this.handleRemoveSection("fileBorchure")
                          }
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
            </GridItem> 
         {/* <GridItem xs={12} sm={12}>
          <div style={{ float: "right" }}>
            <Button color="info" onClick={() => {
              this.saveData();
            }}>
              {" "}
              บันทึก
            </Button>
          </div>
        </GridItem> */}
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setHomeType: payload => dispatch(setHomeType(payload)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setLatlon: payload => dispatch(setLatlon(payload)),
  setProv: payload => dispatch(setProv(payload)),
  setProjectStatus: payload => dispatch(setProjectStatus(payload)),
  setHouseSection1: payload => dispatch(setHouseSection1(payload)),
  setHouseSection2: payload => dispatch(setHouseSection2(payload)),
  setProjectIDHome: payload => dispatch(setProjectIDHome(payload)),
  setDataForSalesTable: payload => dispatch(setDataForSalesTable(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Step1));
