import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { HorizontalBar } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setGraphData from "DataControl/actions/setGraphData.js";
import setGraphData_absorption from "DataControl/actions/setGraphData_absorption.js";
import Minimize from "@material-ui/icons/ExpandLess";
import Maximize from "@material-ui/icons/ExpandMore";
import Snackbar from "components/Snackbar/Snackbar.jsx";
let totalProject = {
  labels: ["บ้านจัดสรร", "อาคารชุด", "บ้านพักตากอากาศ"],
  datasets: [
    {
      data: [0, 0, 0],
      backgroundColor: ["#FFCE56", "#36A2EB", "#FF6384"],
      hoverBackgroundColor: ["#FFCE56", "#36A2EB", "#FF6384"]
    }
  ]
};

let label_price = [
  "น้อยกว่า 1 ล้าน",
  "1 ล้าน - 2 ล้าน",
  "2 ล้าน - 3 ล้าน",
  "3 ล้าน - 5 ล้าน",
  "5 ล้าน - 7.5 ล้าน",
  "7.5 ล้าน - 10 ล้าน",
  "10 ล้านขึ้นไป"
];

let label_price_villa = [
  "น้อยกว่า 15 ล้าน",
  "15 ล้าน - 20 ล้าน",
  "20 ล้าน - 25 ล้าน",
  "25 ล้าน - 30 ล้าน",
  "30 ล้าน - 50 ล้าน",
  "50 ล้าน - 70 ล้าน",
  "70 ล้านขึ้นไป"
];

let data_count_house = {
  labels: [
    "บ้านเดี่ยว",
    "บ้านแฝด",
    "อาคารพาณิชย์",
    "ทาวน์เฮ้าส์ หรือทาวน์โฮม",
    "ที่ดินเปล่า"
  ],
  datasets: [
    {
      label: "บ้านจัดสรร",
      backgroundColor: "#FFCE56",
      borderColor: "#FFCE56",
      borderWidth: 1,
      hoverBackgroundColor: "#FFCE56",
      hoverBorderColor: "#FFCE56",
      data: [0, 0, 0, 0, 0]
    }
  ]
};

let data_count_villa = {
  labels: ["บ้านเดี่ยว", "บ้านแฝด", "ทาวน์เฮ้าส์ หรือทาวน์โฮม", "ที่ดินเปล่า"],
  datasets: [
    {
      label: "บ้านพักตากอากาศ",
      data: [],
      backgroundColor: "#FF6384",
      borderColor: "#FF6384",
      borderWidth: 1,
      hoverBackgroundColor: "#FF6384",
      hoverBorderColor: "#FF6384"
    }
  ]
};

let data_count_condo = {
  labels: ["สตูดิโอ", "1 ห้องนอน", "2 ห้องนอน", "3 ห้องนอนขึ้นไป"],
  datasets: [
    {
      label: "อาคารชุด",
      data: [],
      backgroundColor: "#36A2EB",
      borderColor: "#36A2EB",
      borderWidth: 1,
      hoverBackgroundColor: "#36A2EB",
      hoverBorderColor: "#36A2EB"
    }
  ]
};

let data_unit = {
  labels: [
    "หน่วยในผังโครงการทั้งหมด",
    "หน่วยขายได้สะสม",
    "หน่วยขายได้ใหม่",
    "หน่วยโอนสะสม",
    "หน่วยเหลือขายสร้างเสร็จพร้อมโอน",
    "หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง"
  ],
  datasets: [
    {
      label: "บ้านจัดสรร",
      backgroundColor: "#FFCE56",
      borderColor: "#FFCE56",
      borderWidth: 1,
      hoverBackgroundColor: "#FFCE56",
      hoverBorderColor: "#FFCE56",
      data: [0, 0, 0, 0, 0, 0]
    },
    {
      label: "อาคารชุด",
      backgroundColor: "#36A2EB",
      borderColor: "#36A2EB",
      borderWidth: 1,
      hoverBackgroundColor: "#36A2EB",
      hoverBorderColor: "#36A2EB",
      data: [0, 0, 0, 0, 0, 0]
    },
    {
      label: "วิลล่า",
      backgroundColor: "#FF6384",
      borderColor: "#FF6384",
      borderWidth: 1,
      hoverBackgroundColor: "#FF6384",
      hoverBorderColor: "#FF6384",
      data: [0, 0, 0, 0, 0, 0]
    }
  ]
};

let default_data_absorp_house = {
  labels: [],
  datasets: [
    {
      label: "บ้านจัดสรร",
      backgroundColor: "#FFCE56",
      borderColor: "#FFCE56",
      borderWidth: 1,
      hoverBackgroundColor: "#FFCE56",
      hoverBorderColor: "#FFCE56",
      data: []
    }
  ]
};

let default_data_absorp_condo = {
  labels: [],
  datasets: [
    {
      label: "อาคารชุด",
      backgroundColor: "#36A2EB",
      borderColor: "#36A2EB",
      borderWidth: 1,
      hoverBackgroundColor: "#36A2EB",
      hoverBorderColor: "#36A2EB",
      data: []
    }
  ]
};

let default_data_absorp_villa = {
  labels: [],
  datasets: [
    {
      label: "บ้านพักตากอากาศ",
      backgroundColor: "#FF6384",
      borderColor: "#FF6384",
      borderWidth: 1,
      hoverBackgroundColor: "#FF6384",
      hoverBorderColor: "#FF6384",
      data: []
    }
  ]
};

let options = {
  tooltips: {
    enabled: true
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map(data => {
          sum += data;
        });
        let percentage = ((value * 100) / sum).toFixed(2) + "%";
        //console.log(percentage);
        return percentage;
      },
      color: "#fff"
    }
  }
};
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    totalProject: {
      labels: ["บ้านจัดสรร", "อาคารชุด", "บ้านพักตากอากาศ"],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: ["#FFCE56", "#36A2EB", "#FF6384"],
          hoverBackgroundColor: ["#FFCE56", "#36A2EB", "#FF6384"]
        }
      ]
    },
    data_1: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_2: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_3: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_4: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_5: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_6: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_7: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_8: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_9: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_10: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    data_11: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: []
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: []
          }
        ]
      }
    },
    place: "tc",
    color: "warning",
    message: "ไม่มีข้อมูลรายงานในช่วงเวลาที่เลือก",
    open: false,
    all_project: [],
    showGraph0: true,
    showGraph1: true,
    showGraph2: true,
    showGraph3: true,
    showGraph4: true,
    showGraph5: true,
    showGraph6: true,
    showGraph7: true,
    showGraph8: true,
    showGraph9: true,
    showGraph10: true,
    showGraph11: true,
    showGraph12: true,
    data_absorp_villa: {
      labels: [],
      datasets: [
        {
          label: "บ้านพักตากอากาศ",
          backgroundColor: "#FF6384",
          borderColor: "#FF6384",
          borderWidth: 1,
          hoverBackgroundColor: "#FF6384",
          hoverBorderColor: "#FF6384",
          data: []
        }
      ]
    },
    data_absorp_condo: {
      labels: [],
      datasets: [
        {
          label: "อาคารชุด",
          backgroundColor: "#36A2EB",
          borderColor: "#36A2EB",
          borderWidth: 1,
          hoverBackgroundColor: "#36A2EB",
          hoverBorderColor: "#36A2EB",
          data: []
        }
      ]
    },
    data_absorp_house: {
      labels: [],
      datasets: [
        {
          label: "บ้านจัดสรร",
          backgroundColor: "#FFCE56",
          borderColor: "#FFCE56",
          borderWidth: 1,
          hoverBackgroundColor: "#FFCE56",
          hoverBorderColor: "#FFCE56",
          data: []
        }
      ]
    },
    ishide: true,
    data_count_house: null,
    data_count_villa: null,
    data_count_condo: null,
    data_absorp: null,
    data_unit: null
  };

  groupByPrice = raw => {
    let less_than_1M = raw.filter(function(obj) {
      return obj.pricelength === "0 - 1,000,000";
    });
    let M1_M2 = raw.filter(function(obj) {
      return obj.pricelength === "1,000,001 - 2,000,000";
    });
    let M2_M3 = raw.filter(function(obj) {
      return obj.pricelength === "2,000,001 - 3,000,000";
    });
    let M3_M5 = raw.filter(function(obj) {
      return obj.pricelength === "3,000,001 - 5,000,000";
    });
    let M5_M7_5 = raw.filter(function(obj) {
      return obj.pricelength === "5,000,001 - 7,500,000";
    });
    let M7_5_M10 = raw.filter(function(obj) {
      return obj.pricelength === "7,500,001 - 10,000,000";
    });

    let morethan_M10 = raw.filter(function(obj) {
      return obj.pricelength === "10,000,001 - 1,000,000,000";
    });
    return [less_than_1M, M1_M2, M2_M3, M3_M5, M5_M7_5, M7_5_M10, morethan_M10];
  };

  groupByPrice_villa = raw => {
    let less_than_1M = raw.filter(function(obj) {
      return obj.pricelength === "0 - 15,000,000";
    });
    let M1_M2 = raw.filter(function(obj) {
      return obj.pricelength === "15,000,001 - 20,000,000";
    });
    let M2_M3 = raw.filter(function(obj) {
      return obj.pricelength === "20,000,001 - 25,000,000";
    });
    let M3_M5 = raw.filter(function(obj) {
      return obj.pricelength === "25,000,001 - 30,000,000";
    });
    let M5_M7_5 = raw.filter(function(obj) {
      return obj.pricelength === "30,000,001 - 50,000,000";
    });
    let M7_5_M10 = raw.filter(function(obj) {
      return obj.pricelength === "50,000,001 - 70,000,000";
    });

    let morethan_M10 = raw.filter(function(obj) {
      return obj.pricelength === "70,000,001 - 1,000,000,000";
    });
    return [less_than_1M, M1_M2, M2_M3, M3_M5, M5_M7_5, M7_5_M10, morethan_M10];
  };

  separateType = (priceRaw, type) => {
    let less_than_1M = priceRaw[0].filter(function(obj) {
      return obj.cat === type;
    });
    let M1_M2 = priceRaw[1].filter(function(obj) {
      return obj.cat === type;
    });
    let M2_M3 = priceRaw[2].filter(function(obj) {
      return obj.cat === type;
    });
    let M3_M5 = priceRaw[3].filter(function(obj) {
      return obj.cat === type;
    });
    let M5_M7_5 = priceRaw[4].filter(function(obj) {
      return obj.cat === type;
    });
    let M7_5_M10 = priceRaw[5].filter(function(obj) {
      return obj.cat === type;
    });
    let morethan_M10 = priceRaw[6].filter(function(obj) {
      return obj.cat === type;
    });
    return [less_than_1M, M1_M2, M2_M3, M3_M5, M5_M7_5, M7_5_M10, morethan_M10];
  };

  sumData = (priceRaw, propsName) => {
    let less_than_1M = priceRaw[0].reduce((sum, props) => {
      let val = parseInt(props[propsName]);
      if (Number.isNaN(val)) {
        val = 0;
      }
      return sum + val;
    }, 0);
    let M1_M2 = priceRaw[1].reduce((sum, props) => {
      let val = parseInt(props[propsName]);
      if (Number.isNaN(val)) {
        val = 0;
      }
      return sum + val;
    }, 0);
    let M2_M3 = priceRaw[2].reduce((sum, props) => {
      let val = parseInt(props[propsName]);
      if (Number.isNaN(val)) {
        val = 0;
      }
      return sum + val;
    }, 0);
    let M3_M5 = priceRaw[3].reduce((sum, props) => {
      let val = parseInt(props[propsName]);
      if (Number.isNaN(val)) {
        val = 0;
      }
      return sum + val;
    }, 0);
    let M5_M7_5 = priceRaw[4].reduce((sum, props) => {
      let val = parseInt(props[propsName]);
      if (Number.isNaN(val)) {
        val = 0;
      }
      return sum + val;
    }, 0);
    let M7_5_M10 = priceRaw[5].reduce((sum, props) => {
      let val = parseInt(props[propsName]);
      if (Number.isNaN(val)) {
        val = 0;
      }
      return sum + val;
    }, 0);
    let morethan_M10 = priceRaw[6].reduce((sum, props) => {
      let val = parseInt(props[propsName]);
      if (Number.isNaN(val)) {
        val = 0;
      }
      return sum + val;
    }, 0);

    return [less_than_1M, M1_M2, M2_M3, M3_M5, M5_M7_5, M7_5_M10, morethan_M10];
  };

  componentDidUpdate(prevProps) {
    //1. จำนวนโครงการทั้งหมด
    if (prevProps.graphData_projectall !== this.props.graphData_projectall) {
      let data = this.props.graphData_projectall;

      const condo_sum = data.filter(function(obj) {
        return obj.cat === 1;
      });

      const house_sum = data.filter(function(obj) {
        return obj.cat === 2;
      });

      const villa_sum = data.filter(function(obj) {
        return obj.cat === 3;
      });

      this.updateTotalProject(
        house_sum.length,
        condo_sum.length,
        villa_sum.length
      );
    }

    //2.หน่วยเหลือขายทั้งหมด
    if (prevProps.graphData_leftsellall !== this.props.graphData_leftsellall) {
      let raw = this.props.graphData_leftsellall;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "leftsell");
      let sum_house_data = this.sumData(house_data, "leftsell");
      let sum_villa_data = this.sumData(villa_data, "leftsell");

      let sum_condo_data_all = this.sumData(condo_data, "leftsellall");
      let sum_house_data_all = this.sumData(house_data, "leftsellall");
      let sum_villa_data_all = this.sumData(villa_data, "leftsellall");

      let data_1_tmp = { ...this.state.data_1 };

      data_1_tmp.house.labels = label_price;
      data_1_tmp.condo.labels = label_price;
      data_1_tmp.villa.labels = label_price_villa;

      data_1_tmp.house.datasets[0].data = sum_house_data;
      data_1_tmp.condo.datasets[0].data = sum_condo_data;
      data_1_tmp.villa.datasets[0].data = sum_villa_data;

      data_1_tmp.house.datasets[1].data = sum_house_data_all;
      data_1_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_1_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_1: data_1_tmp });
    }

    //3.หน่วยเหลือขายสร้างเสร็จพร้อมโอน
    if (prevProps.graphData !== this.props.graphData) {
      let raw = this.props.graphData;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "sellaccumtransfer");
      let sum_house_data = this.sumData(house_data, "sellaccumtransfer");
      let sum_villa_data = this.sumData(villa_data, "sellaccumtransfer");

      let sum_condo_data_all = this.sumData(condo_data, "sellaccumtransferall");
      let sum_house_data_all = this.sumData(house_data, "sellaccumtransferall");
      let sum_villa_data_all = this.sumData(villa_data, "sellaccumtransferall");

      let data_2_tmp = { ...this.state.data_2 };

      data_2_tmp.house.labels = label_price;
      data_2_tmp.condo.labels = label_price;
      data_2_tmp.villa.labels = label_price_villa;

      data_2_tmp.house.datasets[0].data = sum_house_data;
      data_2_tmp.condo.datasets[0].data = sum_condo_data;
      data_2_tmp.villa.datasets[0].data = sum_villa_data;

      data_2_tmp.house.datasets[1].data = sum_house_data_all;
      data_2_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_2_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_2: data_2_tmp });
    }

    //4. หน่วยในผังโครงการทั้งหมด
    if (prevProps.setGraphData_allunit !== this.props.setGraphData_allunit) {
      let raw = this.props.setGraphData_allunit;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "unit");
      let sum_house_data = this.sumData(house_data, "unit");
      let sum_villa_data = this.sumData(villa_data, "unit");

      let sum_condo_data_all = this.sumData(condo_data, "unit_all");
      let sum_house_data_all = this.sumData(house_data, "unit_all");
      let sum_villa_data_all = this.sumData(villa_data, "unit_all");

      let data_3_tmp = { ...this.state.data_3 };

      data_3_tmp.house.labels = label_price;
      data_3_tmp.condo.labels = label_price;
      data_3_tmp.villa.labels = label_price_villa;

      data_3_tmp.house.datasets[0].data = sum_house_data;
      data_3_tmp.condo.datasets[0].data = sum_condo_data;
      data_3_tmp.villa.datasets[0].data = sum_villa_data;

      data_3_tmp.house.datasets[1].data = sum_house_data_all;
      data_3_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_3_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_3: data_3_tmp });
    }

    //5.หน่วยขายได้ใหม่
    if (prevProps.graphData_newsell !== this.props.graphData_newsell) {
      let raw = this.props.graphData_newsell;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "sellnew");
      let sum_house_data = this.sumData(house_data, "sellnew");
      let sum_villa_data = this.sumData(villa_data, "sellnew");

      let sum_condo_data_all = this.sumData(condo_data, "sellnew_all");
      let sum_house_data_all = this.sumData(house_data, "sellnew_all");
      let sum_villa_data_all = this.sumData(villa_data, "sellnew_all");

      let data_4_tmp = { ...this.state.data_4 };

      data_4_tmp.house.labels = label_price;
      data_4_tmp.condo.labels = label_price;
      data_4_tmp.villa.labels = label_price_villa;

      data_4_tmp.house.datasets[0].data = sum_house_data;
      data_4_tmp.condo.datasets[0].data = sum_condo_data;
      data_4_tmp.villa.datasets[0].data = sum_villa_data;

      data_4_tmp.house.datasets[1].data = sum_house_data_all;
      data_4_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_4_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_4: data_4_tmp });
    }

    //6.หน่วยโอนสะสม
    if (
      prevProps.graphData_transfer_acc !== this.props.graphData_transfer_acc
    ) {
      let raw = this.props.graphData_transfer_acc;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "transfer");
      let sum_house_data = this.sumData(house_data, "transfer");
      let sum_villa_data = this.sumData(villa_data, "transfer");

      let sum_condo_data_all = this.sumData(condo_data, "transferall");
      let sum_house_data_all = this.sumData(house_data, "transferall");
      let sum_villa_data_all = this.sumData(villa_data, "transferall");

      let data_5_tmp = { ...this.state.data_5 };

      data_5_tmp.house.labels = label_price;
      data_5_tmp.condo.labels = label_price;
      data_5_tmp.villa.labels = label_price_villa;

      data_5_tmp.house.datasets[0].data = sum_house_data;
      data_5_tmp.condo.datasets[0].data = sum_condo_data;
      data_5_tmp.villa.datasets[0].data = sum_villa_data;

      data_5_tmp.house.datasets[1].data = sum_house_data_all;
      data_5_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_5_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_5: data_5_tmp });
    }

    //7.โครงการเปิดขายใหม่
    if (prevProps.graphData_newproject !== this.props.graphData_newproject) {
      let raw = this.props.graphData_newproject;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "numofproj");
      let sum_house_data = this.sumData(house_data, "numofproj");
      let sum_villa_data = this.sumData(villa_data, "numofproj");

      let sum_condo_data_all = this.sumData(condo_data, "numofprojall");
      let sum_house_data_all = this.sumData(house_data, "numofprojall");
      let sum_villa_data_all = this.sumData(villa_data, "numofprojall");

      let data_6_tmp = { ...this.state.data_6 };

      data_6_tmp.house.labels = label_price;
      data_6_tmp.condo.labels = label_price;
      data_6_tmp.villa.labels = label_price_villa;

      data_6_tmp.house.datasets[0].data = sum_house_data;
      data_6_tmp.condo.datasets[0].data = sum_condo_data;
      data_6_tmp.villa.datasets[0].data = sum_villa_data;

      data_6_tmp.house.datasets[1].data = sum_house_data_all;
      data_6_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_6_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_6: data_6_tmp });
    }

    //8.หน่วยขายได้สะสม
    if (prevProps.graphData_sell_acc !== this.props.graphData_sell_acc) {
      let raw = this.props.graphData_sell_acc;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "sellaccum");
      let sum_house_data = this.sumData(house_data, "sellaccum");
      let sum_villa_data = this.sumData(villa_data, "sellaccum");

      let sum_condo_data_all = this.sumData(condo_data, "sellaccum_all");
      let sum_house_data_all = this.sumData(house_data, "sellaccum_all");
      let sum_villa_data_all = this.sumData(villa_data, "sellaccum_all");

      let data_7_tmp = { ...this.state.data_7 };

      data_7_tmp.house.labels = label_price;
      data_7_tmp.condo.labels = label_price;
      data_7_tmp.villa.labels = label_price_villa;

      data_7_tmp.house.datasets[0].data = sum_house_data;
      data_7_tmp.condo.datasets[0].data = sum_condo_data;
      data_7_tmp.villa.datasets[0].data = sum_villa_data;

      data_7_tmp.house.datasets[1].data = sum_house_data_all;
      data_7_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_7_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_7: data_7_tmp });
    }

    //9. หน่วยขายได้รอโอนสะสม (backlog)
    if (
      prevProps.graphData_sell_acc_transfer !==
      this.props.graphData_sell_acc_transfer
    ) {
      let raw = this.props.graphData_sell_acc_transfer;

      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "transferbacklog");
      let sum_house_data = this.sumData(house_data, "transferbacklog");
      let sum_villa_data = this.sumData(villa_data, "transferbacklog");

      let sum_condo_data_all = this.sumData(condo_data, "transferbacklog_all");
      let sum_house_data_all = this.sumData(house_data, "transferbacklog_all");
      let sum_villa_data_all = this.sumData(villa_data, "transferbacklog_all");

      let data_8_tmp = { ...this.state.data_8 };

      data_8_tmp.house.labels = label_price;
      data_8_tmp.condo.labels = label_price;
      data_8_tmp.villa.labels = label_price_villa;

      data_8_tmp.house.datasets[0].data = sum_house_data;
      data_8_tmp.condo.datasets[0].data = sum_condo_data;
      data_8_tmp.villa.datasets[0].data = sum_villa_data;

      data_8_tmp.house.datasets[1].data = sum_house_data_all;
      data_8_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_8_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_8: data_8_tmp });
    }

    //10. หน่วยเปิดขายใหม่
    if (
      prevProps.graphData_open_newsell !== this.props.graphData_open_newsell
    ) {
      let raw = this.props.graphData_open_newsell;
      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "sum_open_new");
      let sum_house_data = this.sumData(house_data, "sum_open_new");
      let sum_villa_data = this.sumData(villa_data, "sum_open_new");

      let sum_condo_data_all = this.sumData(condo_data, "sum_open_newall");
      let sum_house_data_all = this.sumData(house_data, "sum_open_newall");
      let sum_villa_data_all = this.sumData(villa_data, "sum_open_newall");

      let data_9_tmp = { ...this.state.data_9 };

      data_9_tmp.house.labels = label_price;
      data_9_tmp.condo.labels = label_price;
      data_9_tmp.villa.labels = label_price_villa;

      data_9_tmp.house.datasets[0].data = sum_house_data;
      data_9_tmp.condo.datasets[0].data = sum_condo_data;
      data_9_tmp.villa.datasets[0].data = sum_villa_data;

      data_9_tmp.house.datasets[1].data = sum_house_data_all;
      data_9_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_9_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_9: data_9_tmp });
    }

    //11.หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง
    if (prevProps.graphData_leftsell !== this.props.graphData_leftsell) {
      let raw = this.props.graphData_leftsell;
      let groupPrice = this.groupByPrice(raw);
      let groupPrice_villa = this.groupByPrice_villa(raw);

      let condo_data = this.separateType(groupPrice, 1);
      let house_data = this.separateType(groupPrice, 2);
      let villa_data = this.separateType(groupPrice_villa, 3);

      let sum_condo_data = this.sumData(condo_data, "leftsell");
      let sum_house_data = this.sumData(house_data, "leftsell");
      let sum_villa_data = this.sumData(villa_data, "leftsell");

      let sum_condo_data_all = this.sumData(condo_data, "leftsellall");
      let sum_house_data_all = this.sumData(house_data, "leftsellall");
      let sum_villa_data_all = this.sumData(villa_data, "leftsellall");

      let data_10_tmp = { ...this.state.data_10 };

      data_10_tmp.house.labels = label_price;
      data_10_tmp.condo.labels = label_price;
      data_10_tmp.villa.labels = label_price_villa;

      data_10_tmp.house.datasets[0].data = sum_house_data;
      data_10_tmp.condo.datasets[0].data = sum_condo_data;
      data_10_tmp.villa.datasets[0].data = sum_villa_data;

      data_10_tmp.house.datasets[1].data = sum_house_data_all;
      data_10_tmp.condo.datasets[1].data = sum_condo_data_all;
      data_10_tmp.villa.datasets[1].data = sum_villa_data_all;

      this.setState({ data_10: data_10_tmp });
    }

    if (prevProps.absorption !== this.props.absorption) {
      this.updateAbsorption(this.props.absorption);
    }
  }

  updateAbsorption = absorption => {
    let house = absorption.filter(props => {
      return props.cat_id === 2;
    });

    let condo = absorption.filter(props => {
      return props.cat_id === 1;
    });

    let villa = absorption.filter(props => {
      return props.cat_id === 3;
    });

    let house_absorp = house.map(props => {
      return parseFloat(props.absorption);
    });

    let house_absorp_label = house.map(props => {
      return props.province_t;
    });

    let condo_absorp = condo.map(props => {
      return parseFloat(props.absorption);
    });

    let condo_absorp_label = condo.map(props => {
      return props.province_t;
    });

    let villa_absorp = villa.map(props => {
      return props.absorption;
    });

    let villa_absorp_label = villa.map(props => {
      return props.province_t;
    });

    //new data;
    default_data_absorp_house.datasets[0].data = house_absorp;
    default_data_absorp_house.labels = house_absorp_label;

    this.setState({ data_absorp_house: default_data_absorp_house });

    default_data_absorp_condo.datasets[0].data = condo_absorp;
    default_data_absorp_condo.labels = condo_absorp_label;
    this.setState({ data_absorp_condo: default_data_absorp_condo });

    default_data_absorp_villa.datasets[0].data = villa_absorp;
    default_data_absorp_villa.labels = villa_absorp_label;
    this.setState({ data_absorp_villa: default_data_absorp_villa });

    try {
      this.absorp_house.chartInstance.update();
      this.absorp_condo.chartInstance.update();
      this.absorp_villa.chartInstance.update();
    } catch (err) {}
  };

  updateTotalProject = (house, condo, villa) => {
    if (house === 0 && condo === 0 && villa === 0) {
      alert("ขออภัย ไม่พบข้อมูลโครงการทั้งหมดในไตรมาสนี้");
    }
    let data = [house, condo, villa];
    let tmp_totalProject = { ...this.state.totalProject };
    tmp_totalProject.datasets[0].data = data;
    this.setState({ totalProject: tmp_totalProject });
    this.total_project.chartInstance.update();
  };

  updateUnitData = (houseInfo, condoInfo, villaInfo) => {
    let unit_all_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.unit_all);
    }, 0);

    let sellaccum_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellaccum);
    }, 0);

    let sell_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.sell);
    }, 0);

    let transfer_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.transfer);
    }, 0);

    let leftselltransfer_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftselltransfer);
    }, 0);

    let leftsell_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftsell);
    }, 0);

    let unit_all_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.unit_all);
    }, 0);

    let sellaccum_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellaccum);
    }, 0);

    let sell_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.sell);
    }, 0);

    let transfer_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.transfer);
    }, 0);

    let leftselltransfer_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftselltransfer);
    }, 0);

    let leftsell_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftsell);
    }, 0);

    let unit_all_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.unit_all);
    }, 0);

    let sellaccum_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellaccum);
    }, 0);

    let sell_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.sell);
    }, 0);

    let transfer_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.transfer);
    }, 0);

    let leftselltransfer_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftselltransfer);
    }, 0);

    let leftsell_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftsell);
    }, 0);

    let house_unit = [
      unit_all_house,
      sellaccum_house,
      sell_house,
      transfer_house,
      leftselltransfer_house,
      leftsell_house
    ];

    let condo_unit = [
      unit_all_condo,
      sellaccum_condo,
      sell_condo,
      transfer_condo,
      leftselltransfer_condo,
      leftsell_condo
    ];

    let villa_unit = [
      unit_all_villa,
      sellaccum_villa,
      sell_villa,
      transfer_villa,
      leftselltransfer_villa,
      leftsell_villa
    ];

    data_unit.datasets[0].data = house_unit;
    data_unit.datasets[1].data = condo_unit;
    data_unit.datasets[2].data = villa_unit;

    //let unit = this.house_unit.chartInstance;
    //unit.update();
  };

  render() {
    const { classes } = this.props;
    const names = [
      { name: "หน่วยเหลือขายทั้งหมด", id: 1, ref: "unit_all" },
      { name: "หน่วยเหลือขายสร้างเสร็จพร้อมโอน", id: 2, ref: "unit_transfer" },
      { name: "หน่วยในผังโครงการทั้งหมด", id: 3, ref: "all_unit" },
      { name: "หน่วยขายได้ใหม่", id: 4, ref: "unit_new_sell" },
      { name: "หน่วยโอนสะสม", id: 5, ref: "unit_transferacc" },
      { name: "โครงการเปิดขายใหม่", id: 6, ref: "proj_sell" },
      { name: "หน่วยขายได้สะสม", id: 7, ref: "unit_sellacc" },
      { name: "หน่วยขายได้รอโอนสะสม (backlog)", id: 8, ref: "unit_backlog" },
      { name: "หน่วยเปิดขายใหม่", id: 9, ref: "unit_new_open" },
      {
        name: "หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง",
        id: 10,
        ref: "unit_notbuild"
      }
    ];

    const graph = names.map(props => {
      if (this.props.showgraph.indexOf(props.id) !== -1) {
        let statename = "showGraph" + props.id;
        return (
          <Card key={props.id}>
            <CardBody>
              <a href="#">
                {" "}
                <h4
                  onClick={() =>
                    this.state[statename]
                      ? this.setState({ [statename]: false })
                      : this.setState({ [statename]: true })
                  }
                >
                  {props.name}
                  {this.state[statename] ? (
                    <Minimize style={{ float: "right" }} />
                  ) : (
                    <Maximize style={{ float: "right" }} />
                  )}
                </h4>
              </a>
              {this.state[statename] && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="danger" icon>
                        <h4 className={classes.cardIconTitle}>บ้านจัดสรร</h4>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <HorizontalBar
                            key={props.id}
                            ref={this.props.ref}
                            data={this.state["data_" + props.id].house}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="danger" icon>
                        <h4 className={classes.cardIconTitle}>อาคารชุด</h4>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <HorizontalBar
                            key={props.id}
                            ref={price_condo =>
                              (this.price_condo = price_condo)
                            }
                            data={this.state["data_" + props.id].condo}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="danger" icon>
                        <h4 className={classes.cardIconTitle}>
                          บ้านพักตากอากาศ
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <HorizontalBar
                            key={props.id}
                            ref={price_villa =>
                              (this.price_villa = price_villa)
                            }
                            data={this.state["data_" + props.id].villa}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
          </Card>
        );
      }
    });
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Snackbar
            place={this.state.place}
            color={this.state.color}
            message={this.state.message}
            open={this.state.open}
            closeNotification={() => this.setState({ open: false })}
            close
          />
          {this.props.showgraph.indexOf(0) !== -1 ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody
                    style={{ display: this.state.ishide ? "block" : "none" }}
                  >
                    <a
                      href="#"
                      onClick={() =>
                        this.state.showGraph0
                          ? this.setState({ showGraph0: false })
                          : this.setState({ showGraph0: true })
                      }
                    >
                      {" "}
                      <h4>
                        จำนวนโครงการทั้งหมด
                        {/* {this.state.showGraph0 ? (
                          <Minimize style={{ float: "right" }} />
                        ) : (
                          <Maximize style={{ float: "right" }} />
                        )} */}
                      </h4>
                    </a>
                    <div>
                      <Pie
                        ref={reference => (this.total_project = reference)}
                        //ref={total_project => (this.total_project = total_project)}
                        data={this.state.totalProject}
                        options={options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={6}>
              <CardBody
                style={{ display: this.state.ishide ? "block" : "none" }}
              >
                <div>
                  <Bar
                    ref={total_subhouse =>
                      (this.total_subhouse = total_subhouse)
                    }
                    data={data_count_house}
                    options={options}
                  />
                </div>
              </CardBody>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CardBody
                style={{ display: this.state.ishide ? "block" : "none" }}
              >
                <div>
                  <Bar
                    ref={total_subcondo =>
                      (this.total_subcondo = total_subcondo)
                    }
                    data={data_count_condo}
                    options={options}
                  />
                </div>
              </CardBody>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CardBody
                style={{ display: this.state.ishide ? "block" : "none" }}
              >
                <div>
                  <Bar
                    ref={total_subvilla =>
                      (this.total_subvilla = total_subvilla)
                    }
                    data={data_count_villa}
                    options={options}
                  />
                </div>
              </CardBody>
            </GridItem> */}
            </GridContainer>
          ) : null}

          {/* {this.props.showgraph.indexOf(3) !== -1 ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" icon>
                <h4 className={classes.cardIconTitle}>หน่วยในผังโครงการทั้งหมด</h4>
              </CardHeader>
              <CardBody>
                <div>
                  <h5>หน่วยในผังโครงการแยกตามประเภทที่อยู่อาศัย</h5>
                  <HorizontalBar
                    ref={house_unit => (this.house_unit = house_unit)}
                    data={data_unit}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        ) : null} */}
          {graph}
          {this.props.showgraph.indexOf(11) !== -1 ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="danger" icon>
                    <h4 className={classes.cardIconTitle}>
                      อัตราดูดซับต่อเดือน บ้านจัดสรร
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Bar
                        ref={absorp_house => (this.absorp_house = absorp_house)}
                        data={this.state.data_absorp_house}
                      />
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="danger" icon>
                    <h4 className={classes.cardIconTitle}>
                      อัตราดูดซับต่อเดือน อาคารชุด
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Bar
                        ref={absorp_condo => (this.absorp_condo = absorp_condo)}
                        data={this.state.data_absorp_condo}
                      />
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="danger" icon>
                    <h4 className={classes.cardIconTitle}>
                      อัตราดูดซับต่อเดือน บ้านพักตากอากาศ
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Bar
                        ref={absorp_villa => (this.absorp_villa = absorp_villa)}
                        data={this.state.data_absorp_villa}
                      />
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          ) : null}
          <GridItem xs={12} sm={12} md={7}>
            <div></div>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object,
  graphData: PropTypes.any,
  showgraph: PropTypes.any,
  graphData_open_newsell: PropTypes.any,
  graphData_sell_acc: PropTypes.any,
  graphData_transfer_acc: PropTypes.any,
  setGraphData_allunit: PropTypes.any,
  graphData_newproject: PropTypes.any,
  graphData_projectall: PropTypes.any,
  graphData_leftsellall: PropTypes.any,
  graphData_leftsell: PropTypes.any
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setGraphData: payload => dispatch(setGraphData(payload)),
  setGraphData_absorption: payload => dispatch(setGraphData_absorption(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
