export default (state, action) => {
  switch (action.type) {
    case "languageChange":
      return {
        ...state,
        toggleLng: action.payload
      };
    case "colorChange":
      return {
        ...state,
        activeColor: action.payload
      };
    case "addHouseLatLonChange":
      return {
        ...state,
        latlon: action.payload
      };
    case "boundaryChange":
      return {
        ...state,
        bbox: action.payload
      };
    case "setProv":
      return {
        ...state,
        prov_amphoe_tambon: action.payload
      };
    case "homeSubtypeChange":
      return {
        ...state,
        hometype: action.payload
      };
    case "minmaxhouseChange":
      return {
        ...state,
        minmax_house: action.payload
      };
    case "setHouseSection1":
      return {
        ...state,
        houseInfoSection1: action.payload
      };
    case "setHouseSection2":
      return {
        ...state,
        houseInfoSection2: action.payload
      };
    case "setProjectStatus":
      return {
        ...state,
        project_status: action.payload
      };
    case "setDropDownHouseForSection2":
      return {
        ...state,
        dropdown_house_section2: action.payload
      };
    case "setHousePriceForSection2":
      return {
        ...state,
        dropdown_house_price_section2: action.payload
      };
    case "setNoOfProjects":
      return {
        ...state,
        noOfProjects: action.payload
      };
    case "setProjectIDHome":
      return {
        ...state,
        projectIDHome: action.payload
      };
    case "setShowModal":
      return {
        ...state,
        showModal_detail: action.payload
      };
    case "setModalType":
      return {
        ...state,
        modaltype: action.payload
      };
    case "setMapImagePath":
      return {
        ...state,
        mapImagePath: action.payload
      };
    case "setPointsOnMap":
      return {
        ...state,
        pointsOnMap: action.payload
      };
    case "createModal": {
      return {
        ...state,
        isCreateModal: action.payload
      };
    }
    case "timeoutmodal":
      return {
        ...state,
        sessionModal: action.payload
      };
    case "logoutmodal":
      return {
        ...state,
        logoutModal: action.islogout
      };
    case "setInfoForSection2":
      return {
        ...state,
        logoutModal: action.islogout
      };
    case "setApproved":
      return {
        ...state,
        approved: action.payload
      };
    case "setDataForSalesTable":
      return {
        ...state,
        salesTable: action.payload
      };
    case "setLoading":
      return {
        ...state,
        loading: action.payload
      };
    case "noticount":
      return {
        ...state,
        count_noti: action.payload
      };
    case "getnotification":
      return {
        ...state,
        noti_toggle: action.payload
      };
    case "setGraphData":
      return {
        ...state,
        graphData: action.payload
      };
    case "setGraphData_open_newsell":
      return {
        ...state,
        graphData_open_newsell: action.payload
      };
    case "setGraphData_transfer_acc":
      return {
        ...state,
        graphData_transfer_acc: action.payload
      };
    case "setGraphData_sell_acc_transfer":
      return {
        ...state,
        graphData_sell_acc_transfer: action.payload
      };
    case "setGraphData_sell_acc":
      return {
        ...state,
        graphData_sell_acc: action.payload
      };
    case "setGraphData_graphView":
      return {
        ...state,
        graphData_graphView: action.payload
      };
    case "setGraphData_absorption":
      return {
        ...state,
        absorption: action.payload
      };
    case "setGraphData_leftsell":
      return {
        ...state,
        graphData_leftsell: action.payload
      };
    case "setGraphData_newsell":
      return {
        ...state,
        graphData_newsell: action.payload
      };
    case "setGraphProjectAll":
      return {
        ...state,
        graphData_projectall: action.payload
      };
    case "setShowGraph":
      return {
        ...state,
        showgraph: action.payload
      };
    case "setGraphData_allunit":
      return {
        ...state,
        setGraphData_allunit: action.payload
      };
    case "setGraphData_newproject":
      return {
        ...state,
        graphData_newproject: action.payload
      };
    case "setGraphData_leftsellall":
      return {
        ...state,
        graphData_leftsellall: action.payload
      };
    case "setMustSelectedPrice":
      return {
        ...state,
        mustSelectedPrice: action.payload
      };
    case "storepriceid":
      return {
        ...state,
        store_priceid: action.payload
      };
    default:
      return state;
  }
};
