import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Button from "components/CustomButtons/Button.jsx";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Search from "@material-ui/icons/Search";
import Datetime from "react-datetime";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/locale/th";
import {
  API_getProv_report,
  API_get_prov,
  API_gen_secretkey,
  API_report_numofproj_cp,
  API_absortrate_cp,
  API_report_sell_cp,
  API_report_sell_graph_cp,
  API_report_open_new_sell_cp,
  API_report_transfer_acc_cp,
  API_report_sell_acc_cp,
  API_report_backlog_cp,
  API_report_leftsell_cp,
  API_report_sellnew_cp,
  API_report_unitproj_cp,
  API_report_leftsellall_cp,
  API_report_sellaccumtransfer_cp,
  API_report_newproject_cp
} from "config/API.jsx";
import CryptoJS from "crypto-js";
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setGraphProjectAll from "DataControl/actions/setGraphProjectAll.js";
import setGraphData from "DataControl/actions/setGraphData.js";
import setGraphData_open_newsell from "DataControl/actions/setGraphData_open_newsell.js";
import setGraphData_newproject from "DataControl/actions/setGraphData_newproject.js";
import setGraphData_transfer_acc from "DataControl/actions/setGraphData_transfer_acc.js";
import setGraphData_sell_acc from "DataControl/actions/setGraphData_sell_acc.js";
import setGraphData_absorption from "DataControl/actions/setGraphData_absorption.js";
import setShowGraph from "DataControl/actions/setShowGraph.js";
import setGraphData_graphView from "DataControl/actions/setGraphData_graphView.js";
import setGraphData_sell_acc_transfer from "DataControl/actions/setGraphData_sell_acc_transfer.js";
import setGraphData_leftsell from "DataControl/actions/setGraphData_leftsell.js";
import setGraphData_newsell from "DataControl/actions/setGraphData_newsell.js";
import setGraphData_allunit from "DataControl/actions/setGraphData_allunit.js";
import setGraphData_leftsellall from "DataControl/actions/setGraphData_leftsellall.js";

import Checkbox from "@material-ui/core/Checkbox";

const names = [
  "จำนวนโครงการทั้งหมด",
  "หน่วยเหลือขายทั้งหมด",
  "หน่วยเหลือขายสร้างเสร็จพร้อมโอน",
  "หน่วยในผังโครงการทั้งหมด",
  "หน่วยขายได้ใหม่",
  "หน่วยโอนสะสม",
  "โครงการเปิดขายใหม่",
  "หน่วยขายได้สะสม",
  "หน่วยขายได้รอโอนสะสม (backlog)",
  "หน่วยเปิดขายใหม่",
  "หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง",
  "อัตราดูดซับต่อเดือน"
];

const right = {
  float: "right"
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    showAll: false,
    showGraph0: true,
    showGraph1: true,
    showGraph2: true,
    showGraph3: true,
    showGraph4: true,
    showGraph5: true,
    showGraph6: true,
    showGraph7: true,
    showGraph8: true,
    showGraph9: true,
    showGraph10: true,
    showGraph11: true,
    showGraph12: true,
    checked: [],
    ishide: true,
    value: 0,
    multipleSelect: [],
    projectStatusSelect: [],
    selectedValue: "a",
    asset_type: "",
    quarter: [1, 2, 3, 4],
    selected_quarter: 1,
    selected_quarter2: 1,
    projectStatus: [
      { name: "โครงการปิดการขาย", value: 1 },
      { name: "โครงการอยู่ระหว่างการขาย", value: 2 },
      { name: "โครงการเปิดขายใหม่ในช่วงสำรวจ", value: 3 },
      { name: "โครงการในอนาคต", value: 4 },
      { name: "โครงการชะลอการขาย", value: 5 },
      { name: "โครงการเปิดและปิดการขายในช่วงสำรวจ", value: 6 }
    ],
    province: [],
    selected_province: 1000,
    selected_province2: 1000,
    amphoe: [
      {
        label: "ทั้งหมด",
        value: "0"
      }
    ],
    selected_amphoe: 0,
    selected_amphoe: 0,
    hometype: [
      { label: "บ้านจัดสรร", value: "2" },
      { label: "อาคารชุด", value: "1" },
      { label: "บ้านพักตากอากาศ", value: "3" }
    ],
    homeSubtype: [
      {
        type: "1",
        subtype: [
          { label: "บ้านเดี่ยว", value: 1 },
          { label: "บ้านแฝด", value: 2 },
          { label: "ทาวน์เฮาส์ หรือ ทาวน์โฮม", value: 3 },
          { label: "อาคารพาณิชย์", value: 4 },
          { label: "ที่ดินเปล่า", value: 5 }
        ]
      }
    ],
    selected_year: 2019,
    selected_year2: 2019
  };

  componentDidMount() {
    this.getSecretKey();
  }

  getProvince = () => {
    let self = this;
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    // const tokenData = jsonDec.token;
    const org_id = jsonDec.member_org;

    let data = {
      org_id: org_id
    };

    var request = new Request(API_getProv_report, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data)
    });
    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code === 200) {
          let prov = data.data;
          prov.unshift({
            label: "ทั้งหมด",
            value: "1000"
          });

          this.setState({
            province: prov,
            selected_province: "1000"
          });
          //self.getAmphoe(1000);
        }else{
          let prov = [];
          prov.unshift({
            label: "ทั้งหมด",
            value: "1000"
          });

          this.setState({
            province: prov,
            selected_province: "1000"
          });
        }
      });
  };

  // getAmphoe = default_val_amp => {
  //   let self = this;
  //   let province_id = parseInt(self.state.selected_province);
  //   let province = {
  //     pro_id: province_id,
  //     amp_id: 0,
  //     tam_id: 0
  //   };
  //   var request = new Request(API_get_prov_cp, {
  //     method: "POST",
  //     headers: new Headers({ "Content-Type": "application/json" }),
  //     body: JSON.stringify(province)
  //   });
  //   fetch(request)
  //     .then(response => {
  //       return response.json();
  //     })
  //     .then(data => {
  //       if (data.code === 200) {
  //         let amphoe = data.data;
  //         amphoe.unshift({
  //           label: "ทั้งหมด",
  //           value: "1000"
  //         });
  //         this.setState({
  //           amphoe: amphoe
  //         });
  //         if (default_val_amp === 1000) {
  //           this.setState({
  //             selected_amphoe: amphoe[0].value
  //           });
  //         } else {
  //           this.setState({
  //             selected_amphoe: default_val_amp
  //           });
  //         }
  //       }
  //     });
  // };

  handleFilter = (event, value) => {
    this.setState({ selectedValue: event.target.value });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  handleMultiple = event => {
    this.setState({ selected_quarter: event.target.value });
  };
  handleMultiple2 = event => {
    this.setState({ selected_quarter2: event.target.value });
  };
  handleProjectStatus = event => {
    this.setState({ projectStatusSelect: event.target.value });
  };
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAssetType = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSelectedYear2 = event => {
    this.setState({
      selected_year: event.target.value
    });
  };

  handleSelectProvince2 = event => {
    this.setState(
      {
        selected_province: event.target.value
      },
      () => {
        //this.getAmphoe(1000);
      }
    );
  };
  handleSelectAmphoe2 = event => {
    this.setState({
      selected_amphoe2: event.target.value
    });
  };
  handleSelectedYear = event => {
    this.setState({
      selected_year: event.target.value
    });
  };

  handleSelectProvince = event => {
    this.setState(
      {
        selected_province: event.target.value
      },
      () => {
        //this.getAmphoe(1000);
      }
    );
  };
  handleSelectAmphoe = event => {
    this.setState({
      selected_amphoe: event.target.value
    });
  };

  getAbsorbtionRate = org_id => {
    let self = this;
    // let param = {
    //   quarter: this.state.selected_quarter,
    //   org_id: org_id,
    //   year: this.state.selected_year,
    //   prov_id:
    //     this.state.selected_province === "1000"
    //       ? 0
    //       : this.state.selected_province
    // };
    let param = {
      quarter: this.state.selected_quarter,
      org_id: org_id,
      year: this.state.selected_year,
      pro_id:
        this.state.selected_province === "1000"
          ? 0
          : this.state.selected_province,
      amp_id:
        this.state.selected_amphoe === "1000" ? 0 : this.state.selected_amphoe
    };
    var request = new Request(API_absortrate_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            self.props.setGraphData_absorption(info);
          } else {
            //alert("sorry, no data.");
            self.props.setGraphData_absorption([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getSecretKey = () => {
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });

    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState(
            {
              key: responseJson.data
            },
            () => {
              this.getProvince();
            }
          );
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getData = () => {
    let self = this;
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    // const tokenData = jsonDec.token;
    const org_id = jsonDec.member_org;

    if (this.state.checked.indexOf(0) !== -1) {
      //1 จำนวนโครงการทั้งหมด
      this.getGraphTotal(org_id);
    }

    if (this.state.checked.indexOf(1) !== -1) {
      //2. หน่วยเหลือขายทั้งหมด
      this.getGraph_leftsellall(org_id);
    }

    if (this.state.checked.indexOf(2) !== -1) {
      //3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
      this.getGraphData(org_id);
    }

    if (this.state.checked.indexOf(3) !== -1) {
      //4. หน่วยในผังโครงการทั้งหมด
      this.getGraphData_allunit(org_id);
    }

    if (this.state.checked.indexOf(4) !== -1) {
      //5. หน่วยขายได้ใหม่
      this.getGraphData_newSell(org_id);
    }

    if (this.state.checked.indexOf(5) !== -1) {
      //6. หน่วยโอนสะสม
      this.getGraphData_transfer_acc(org_id);
    }

    if (this.state.checked.indexOf(6) !== -1) {
      //7. โครงการเปิดขายใหม่
      this.getNewProjectInQuarter(org_id);
    }

    if (this.state.checked.indexOf(7) !== -1) {
      //8. หน่วยขายได้สะสม
      this.getGraphData_sell_acc(org_id);
    }

    if (this.state.checked.indexOf(8) !== -1) {
      //9. หน่วยขายได้รอโอนสะสม
      this.getGraphData_sell_acc_transfer(org_id);
    }

    if (this.state.checked.indexOf(9) !== -1) {
      //10. หน่วยเปิดขายใหม่
      this.getGraphData_open_newsell(org_id);
    }

    //11.หน่วยเหลือขายยังไม่ก่อสร้าง
    if (this.state.checked.indexOf(10) !== -1) {
      this.getReport_leftSell(org_id);
    }

    if (this.state.checked.indexOf(11) !== -1) {
      //12. อัตราดูดซับต่อเดือน
      this.getAbsorbtionRate(org_id);
    }
  };

  setYear = date => {
    try {
      const valueOfInput = date.format("YYYY");
      this.setState({
        selected_year: valueOfInput
      });
    } catch (err) {}
  };

  getNewProjectInQuarter = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };
    var request = new Request(API_report_newproject_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data);
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                return props.numofproj !== 0 && props.numofprojall !== 0;
              });
              self.props.setGraphData_newproject(info_nonull);
            } else {
              self.props.setGraphData_newproject([]);
            }
          } else {
            self.props.setGraphData_newproject([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  changeLabelPrice = pricelength => {
    if (pricelength === "10,000,001 - 1,000,000,000") {
      pricelength = "มากกว่า 10 ล้าน";
    }

    if (pricelength === "70,000,001 - 1,000,000,000") {
      pricelength = "มากกว่า 70 ล้าน";
    }

    return pricelength;
  };

  getGraphData_newSell = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_sellnew_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log("API_report_sellnew")
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (props.sellnew === 0 && props.sellnew_all !== 0) {
                  props.sellnew = "";
                }
                return props.sellnew_all !== 0;
              });
              self.props.setGraphData_newsell(info_nonull);
            } else {
              self.props.setGraphData_newsell([]);
            }
          } else {
            self.props.setGraphData_newsell([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getGraphTotal = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_numofproj_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          // if (data.code === 200) {
          //   let info = data.data;
          //   self.props.setGraphProjectAll(info);
          // } else {
          //   self.props.setGraphProjectAll([]);
          // }
          console.log(data)
          switch (data.code) {
            case 200:
              if (data.data.length !== 0) {
                let info = data.data;
                self.props.setGraphProjectAll(info);
              } else {
                self.props.setGraphProjectAll([]);
              }
              break;
            default:
              self.props.setGraphProjectAll([]);
              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getReport_leftSell = orgid => {
    let self = this;

    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };
    var request = new Request(API_report_leftsell_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log("left sell")
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);

                if (props.leftsell === 0 && props.leftsellall !== 0) {
                  props.leftsell = "";
                }

                return props.leftsell_all !== 0;
              });
              self.props.setGraphData_leftsell(info_nonull);
            } else {
              self.props.setGraphData_leftsell([]);
            }
          } else {
            self.props.setGraphData_leftsell([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getGraphData_sell_acc_transfer = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_backlog_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (
                  props.transferbacklog === 0 &&
                  props.transferbacklog_all !== 0
                ) {
                  props.transferbacklog = "";
                }
                return props.transferbacklog_all !== 0;
              });
              self.props.setGraphData_sell_acc_transfer(info_nonull);
            } else {
              self.props.setGraphData_sell_acc_transfer([]);
            }
          } else {
            self.props.setGraphData_sell_acc_transfer([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getGraphData_allunit = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_unitproj_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data);
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (props.unit === 0 && props.unit_all !== 0) {
                  props.unit = "";
                }
                return props.unit_all !== 0;
              });
              self.props.setGraphData_allunit(info_nonull);
            } else {
              self.props.setGraphData_allunit([]);
            }
          } else {
            self.props.setGraphData_allunit([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getGraphData_open_newsell = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_open_new_sell_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (props.sum_open_new === 0 && props.sum_open_newall !== 0) {
                  props.sum_open_new = "";
                }
                return props.sum_open_newall !== 0;
              });
              self.props.setGraphData_open_newsell(info_nonull);
            } else {
              self.props.setGraphData_open_newsell([]);
            }
          } else {
            self.props.setGraphData_open_newsell([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getGraphData_sell_acc = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_sell_acc_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (props.sellaccum === 0 && props.sellaccum_all !== 0) {
                  props.sellaccum = "";
                }
                return props.sellaccum_all !== 0;
              });
              self.props.setGraphData_sell_acc(info_nonull);
            } else {
              self.props.setGraphData_sell_acc([]);
            }
          } else {
            self.props.setGraphData_sell_acc([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getGraphData_transfer_acc = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_transfer_acc_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (props.transfer === 0 && props.transferall !== 0) {
                  props.transfer = "";
                }
                return props.transferall !== 0;
              });
              self.props.setGraphData_transfer_acc(info_nonull);
            } else {
              self.props.setGraphData_transfer_acc([]);
            }
          } else {
            self.props.setGraphData_transfer_acc([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  //show หน่วยเหลือขายทั้งหมด
  getGraph_leftsellall = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };
    var request = new Request(API_report_leftsellall_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log('check')
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (props.leftsell === 0 && props.leftsellall !== 0) {
                  props.leftsell = "";
                }
                return props.leftsellall !== 0;
              });
              self.props.setGraphData_leftsellall(info_nonull);
            } else {
              self.props.setGraphData_leftsellall([]);
            }
          } else {
            alert("ขออภัย ไม่มีข้อมูล");
            self.props.setGraphData_leftsellall([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  //3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
  getGraphData = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      quarter: this.state.selected_quarter,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_sellaccumtransfer_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          if (data.code === 200) {
            let info = data.data;
            if (info.length !== 0) {
              let info_nonull = info.filter(props => {
                props.pricelength = self.changeLabelPrice(props.pricelength);
                if (props.sellaccumtransfer === 0 && props.sellaccumtransfer !== 0) {
                  props.sellaccumtransfer = "";
                }
                return (
                  props.sellaccumtransfer !== 0
                );
              });
              console.log(info_nonull)
              self.props.setGraphData(info_nonull);
            } else {
              self.props.setGraphData([]);
            }
          } else {
            alert("ขออภัย ไม่มีข้อมูล");
            self.props.setGraphData([]);
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  getGraphData_GraphView = orgid => {
    let self = this;
    let param = {
      org_id: orgid,
      year: self.state.selected_year
        ? parseInt(self.state.selected_year)
        : null,
      pro_id:
        self.state.selected_province === "1000"
          ? 0
          : self.state.selected_province,
      amp_id:
        self.state.selected_amphoe === "1000" ? 0 : self.state.selected_amphoe
    };

    var request = new Request(API_report_sell_graph_cp, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          // if (data.code === 200) {
          //   let info = data.data;
          //   self.props.setGraphData_graphView(info);
          // } else {
          //   alert("ขออภัย ไม่มีข้อมูล");
          //   self.props.setGraphData_graphView([]);
          // }
          switch (data.code) {
            case 200:
              if (data.data.length !== 0) {
                let info = data.data;
                self.props.setGraphData_graphView(info);
              } else {
                self.props.setGraphData_graphView([]);
              }
              break;
            default:
              self.props.setGraphData_graphView([]);
              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  showGraphAll = () => {
    if (this.state.showAll) {
      this.setState({
        checked: [],
        showAll: false
      });
      this.props.setShowGraph([]);
    } else {
      let checked = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      this.setState({
        checked: checked,
        showAll: true
      });
      this.props.setShowGraph(checked);
    }
  };

  showGraph = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
    this.props.setShowGraph(newChecked);
  };

  render() {
    const { classes } = this.props;

    let quarter = this.state.quarter.map(q => {
      return (
        <MenuItem
          key={q}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={q}
        >
          {q}
        </MenuItem>
      );
    });

    let projectStatus = this.state.projectStatus.map(props => {
      return (
        <MenuItem
          key={props.value}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={props.value}
        >
          {props.name}
        </MenuItem>
      );
    });

    let province = this.state.province;
    let amphoe = this.state.amphoe;

    let optionItems = province.map(prov => {
      return (
        <MenuItem
          key={prov.value}
          id={prov.label}
          classes={{ root: classes.selectMenuItem }}
          value={prov.value}
        >
          {prov.label}
        </MenuItem>
      );
    });

    let optionItems_amphoe = amphoe.map(amp => (
      <MenuItem
        key={amp.value}
        id={amp.label}
        classes={{ root: classes.selectMenuItem }}
        value={amp.value}
      >
        {amp.label}
      </MenuItem>
    ));

    let year = [];

    for (var i = 2015; i <= new Date().getFullYear(); i++) {
      let tmp = { value: i, label: i + 543 };
      year.push(tmp);
    }
    let optionItems_year = year.map(type => (
      <MenuItem
        key={type.value}
        id={type.value}
        classes={{ root: classes.selectMenuItem }}
        value={type.value}
      >
        {type.label}
      </MenuItem>
    ));

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>เลือกไตรมาส</InputLabel>
            <Select
              value={this.state.selected_quarter}
              onChange={this.handleMultiple}
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              inputProps={{
                name: "quarter",
                id: "quarter"
              }}
            >
              {quarter}
            </Select>
          </FormControl>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="multiple-select"
              className={classes.selectLabel}
            >
              สถานะโครงการ
            </InputLabel>
            <Select
              value={this.state.projectStatusSelect}
              onChange={this.handleProjectStatus}
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              inputProps={{
                name: "projectStatus",
                id: "projectStatus"
              }}
            >
              {projectStatus}
            </Select>
          </FormControl>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              ปี
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.selected_year}
              onChange={this.handleSelectedYear}
              inputProps={{
                name: "year",
                id: "year"
              }}
            >
              {optionItems_year}
            </Select>
            {/* <Datetime
            locale={"th"}
            timeFormat={false}
            dateFormat="YYYY"
            inputProps={{ placeholder: "เลือกปี" }}
            onChange={this.setYear.bind(this)}
            selected={this.state.selected_year}
          /> */}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              จังหวัด
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.selected_province}
              onChange={this.handleSelectProvince}
              inputProps={{
                name: "province",
                id: "province"
              }}
            >
              {optionItems}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              อำเภอ
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.selected_amphoe}
              onChange={this.handleSelectAmphoe}
              inputProps={{
                name: "amphoe",
                id: "amphoe"
              }}
            >
              {optionItems_amphoe}
            </Select>
          </FormControl>
        </GridItem>
        
        {/* <GridItem xs={12} sm={12} md={12}>เทียบกับ</GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>เลือกไตรมาส</InputLabel>
            <Select
              value={this.state.selected_quarter2}
              onChange={this.handleMultiple2}
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              inputProps={{
                name: "quarter",
                id: "quarter"
              }}
            >
              {quarter}
            </Select>
          </FormControl>
        </GridItem>
        
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              ปี
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.selected_year2}
              onChange={this.handleSelectedYear2}
              inputProps={{
                name: "year",
                id: "year"
              }}
            >
              {optionItems_year}
            </Select>
          
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              จังหวัด
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.selected_province2}
              onChange={this.handleSelectProvince2}
              inputProps={{
                name: "province",
                id: "province"
              }}
            >
              {optionItems}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              อำเภอ
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.selected_amphoe2}
              onChange={this.handleSelectAmphoe2}
              inputProps={{
                name: "amphoe",
                id: "amphoe"
              }}
            >
              {optionItems_amphoe}
            </Select>
          </FormControl>
        </GridItem>
         */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <br />
            <h4>
              <b>เลือกกราฟที่ต้องการแสดงผล</b>
              <div style={{ float: "right" }}>
                <Checkbox onClick={() => this.showGraphAll()} /> แสดงทั้งหมด{" "}
              </div>
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {names.map((value, index) => {
            return (
              <GridItem key={index} xs={12} sm={12} md={3}>
                <Checkbox
                  checked={this.state.checked.indexOf(index) !== -1}
                  key={index}
                  onClick={() => this.showGraph(index)}
                />
                {value}{" "}
              </GridItem>
            );
          })}
        </GridContainer>

        <Button color="info" fullWidth={false} onClick={() => this.getData()}>
          {" "}
          <Search />
          ค้นหา
        </Button>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setGraphProjectAll: payload => dispatch(setGraphProjectAll(payload)),
  setGraphData: payload => dispatch(setGraphData(payload)),
  setGraphData_open_newsell: payload =>
    dispatch(setGraphData_open_newsell(payload)),
  setGraphData_transfer_acc: payload =>
    dispatch(setGraphData_transfer_acc(payload)),
  setGraphData_graphView: payload => dispatch(setGraphData_graphView(payload)),
  setGraphData_absorption: payload =>
    dispatch(setGraphData_absorption(payload)),
  setShowGraph: payload => dispatch(setShowGraph(payload)),
  setGraphData_sell_acc: payload => dispatch(setGraphData_sell_acc(payload)),
  setGraphData_sell_acc_transfer: payload =>
    dispatch(setGraphData_sell_acc_transfer(payload)),
  setGraphData_leftsell: payload => dispatch(setGraphData_leftsell(payload)),
  setGraphData_newsell: payload => dispatch(setGraphData_newsell(payload)),
  setGraphData_allunit: payload => dispatch(setGraphData_allunit(payload)),
  setGraphData_newproject: payload =>
    dispatch(setGraphData_newproject(payload)),
  setGraphData_leftsellall: payload =>
    dispatch(setGraphData_leftsellall(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
