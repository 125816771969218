/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
// switch language function 
import switchIntl from "translate/switchIntl.js";

function Footer({ ...props }) {
  const { classes, fluid, black, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.blackColor]: black
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.blackColor]: black
    });
  var block = cx({
    [classes.block]: true,
    [classes.blackColor]: black
  });
  return (
    <footer className={classes.footer}>
        <div className={classes.container}>
        <GridContainer justify="center"> 
          <GridItem xs={12} sm={12} md={6}>   
            {" "} &copy; {1900 + new Date().getYear()}
            <p style={{color: 'black', textAlign: 'center'}}>
            {switchIntl(props.toggleLng).login.copyright_phrase1}
            &copy; {1900 + new Date().getYear()}{" "}
            {switchIntl(props.toggleLng).login.copyright_phrase2}
          <br />
            {switchIntl(props.toggleLng).login.copyright_phrase3}
            <br />
            {switchIntl(props.toggleLng).login.copyright_phrase4}
            </p>
          </GridItem>
        </GridContainer> 
        </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(withStyles(footerStyle)(Footer));
