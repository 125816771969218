import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Redirect } from "react-router-dom";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";

import AddIcon from "@material-ui/icons/Add";
import AddFile from "@material-ui/icons/CreateNewFolder";
import Close from "@material-ui/icons/Close";

import CryptoJS from "crypto-js";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import ReactTable from "views/Project/Home/Tables/ReactTables.jsx";
import ReactTable2 from "views/Project/Home/Tables/ReactTable2.jsx";
import ReactTable3 from "views/Project/Home/Tables/ReactTable3.jsx";

import Badge from "components/Badge/Badge.jsx";

import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Slide from "@material-ui/core/Slide";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import NewProjectHome from "views/Project/Home/add/NewProject_home.jsx";
import EditProjectHome from "views/Project/Home/edit/EditProject_home.jsx";

import { BeatLoader } from "react-spinners";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setNoOfProjects from "DataControl/actions/setNoOfProjects.js";
import setProjectIDHome from "DataControl/actions/setProjectIDHome.js";
import setShowModal from "DataControl/actions/setShowModal.js";
import setProv from "DataControl/actions/setProvAmphoeTambon.js";
import setLatlon from "DataControl/actions/setLatlon.js";
import setProjectStatus from "DataControl/actions/setProjectStatus.js";
import setHomeType from "DataControl/actions/setHomeType.js";
import setHouseSection1 from "DataControl/actions/setHouseSection1.js";
import setHouseSection2 from "DataControl/actions/setHouseSection2.js";
import createModal from "DataControl/actions/createModal.js";
import setLoading from "DataControl/actions/setLoading.js";
// switch language function
import switchIntl from "translate/switchIntl.js";

import { API_get_project_id, API_gen_secretkey } from "config/API.jsx";
import GHBankLoading from "assets/img/reicLoading.gif";

import "assets/scss/project.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const loadingCard = (
  <div className="card_loading">
    <BeatLoader sizeUnit={"px"} size={15} color={"#ffffff"} loading={true} />
  </div>
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      img_house: require("assets/img/card_house.jpg"),
      img_villa: require("assets/img/card_villa.jpg"),
      img_condo: require("assets/img/card_condo.jpg"),
      openNotification: false,
      projecttype: null,
      tokenData: null,
      alert: null,
    };
  }

  componentDidUpdate(prevProp) {
    if (prevProp.modaltype !== this.props.modaltype) {
      this.props.createModal(true);
      this.setState({ loadingScreen: true });
      setTimeout(() => {
        this.setState({ loadingScreen: false });
      }, 2500);
      this.setState({ projecttype: this.props.modaltype });
    }
  }

  openUploadFile = () => {
    this.props.history.push("/uploadfile");
  };

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
          });
          //this.getProjectID(); //get new project ID
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkProjectType = (type) => {
    let self = this;
    if (type) {
      if (type === "h") {
        return <NewProjectHome assetType="h"></NewProjectHome>;
      } else if (type === "c") {
        return <NewProjectHome assetType="c"></NewProjectHome>;
      } else if (type === "v") {
        return <NewProjectHome assetType="v"></NewProjectHome>;
      } else if (type.includes("eh")) {
        return (
          <EditProjectHome
            assetType="eh"
            token={this.state.tokenData}
          ></EditProjectHome>
        );
      } else if (type.includes("ec")) {
        return (
          <EditProjectHome
            assetType="ec"
            token={this.state.tokenData}
          ></EditProjectHome>
        );
      } else if (type.includes("ev")) {
        return (
          <EditProjectHome
            assetType="ev"
            token={this.state.tokenData}
          ></EditProjectHome>
        );
      } else {
        return null;
      }
    }
  };

  clearState = () => {
    let hometype_default = {
      home: 1,
      land: 0,
      shophouse: 0,
      townhouse: 0,
      twinhouse: 0,
    };
    this.props.setHomeType(hometype_default);
    this.props.setLatlon({
      lat: 0,
      lng: 0,
    });
    this.props.setProv({
      prov_id: 0,
      amp_id: 0,
      tam_id: 0,
      prov_name: "",
      amp_name: "",
      tam_name: "",
    });
    this.props.setProjectStatus(1);
    this.props.createModal(false);
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };
  handleClickNotification = () => {
    this.setState({ openNotification: !this.state.openNotification });
  };
  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  };
  handleOpenNewProject = () => {
    this.setState({ openNotification: true });
  };
  genProjectID = () => {
    this.getToken();
  };

  getProjectID() {
    let self = this;
    var request = new Request(API_get_project_id, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData,
      }),
      body: JSON.stringify({ currentid: 0 }),
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          self.setState({ loadingScreen: false });
          switch (data.code) {
            case 200:
              self.props.setProjectIDHome(data.data);
              break;
            default:
              //failed
              alert("Sorry, something went wrong...");
              break;
          }
        });
      })
      .catch(function() {});
  }

  confirmClose = () => {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "-100px" }}
          title={"คุณต้องการออกจากหน้านี้"}
          onConfirm={() => {
            this.props.createModal(false);
            this.setState({ projecttype: null });
            this.clearState();
            this.hideAlert();
            this.props.setProjectIDHome("");
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnText={"ใช่ ออกจากหน้านี้"}
          cancelBtnText={"ยกเลิก"}
          showCancel
        >
          หากต้องการบันทึกข้อมูล กรุณาคลิก "บันทึก" ก่อนปิดหน้าจอ
        </SweetAlert>
      ),
    });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
      //
    }
  }

  render() {
    const { classes } = this.props;
    const { openNotification } = this.state;
    let no_of_allproject = this.props.noOfProjects.no_of_allproject;
    let no_of_send_project = this.props.noOfProjects.no_of_send_project;
    let no_of_saved_project = this.props.noOfProjects.no_of_saved_project;
    const loadingCard = (
      <div className="card_loading">
        <BeatLoader
          sizeUnit={"px"}
          size={15}
          color={"#ffffff"}
          loading={true}
        />
      </div>
    );

    return (
      <div>
        <div>
          {this.state.alert}
          {this.loadingScreen(this.state.loadingScreen)}
          <h3>
            โครงการ &nbsp;
            <Button
              color="info"
              size="sm"
              buttonRef={(node) => {
                this.anchorNotification = node;
              }}
              onClick={this.handleClickNotification}
            >
              <h4 style={{ fontWeight: 600 }}>
                <AddIcon />
                {switchIntl(this.props.toggleLng).project.home.add_new_project}
              </h4>
            </Button>
            <Button
              color="info"
              size="sm"
              onClick={this.openUploadFile.bind(this)}
            >
              <h4 style={{ fontWeight: 600 }}>
                <AddFile />
                {
                  switchIntl(this.props.toggleLng).project.home
                    .upload_old_project
                }
              </h4>
            </Button>
          </h3>
        </div>
        <Popper
          open={openNotification}
          anchorEl={this.anchorNotification}
          transition
          placement="bottom-start"
          className="popper"
          style={{ zIndex: 999 }}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "viewport",
              escapeWithReference: false,
            },
            hide: {
              enabled: false,
            },
            arrow: {
              enabled: false,
              element: "arrowRef",
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleClickNotification}>
                  <div style={{ padding: "10px" }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12}>
                        <Button
                          style={{ float: "right" }}
                          color="transparent"
                          onClick={this.handleCloseNotification}
                        >
                          <Close />
                        </Button>
                        <h2>
                          {
                            switchIntl(this.props.toggleLng).project.home
                              .add_new_project
                          }
                        </h2>
                        {
                          switchIntl(this.props.toggleLng).project.home
                            .project_type
                        }
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem>
                        <Card style={{ maxWidth: "200px" }}>
                          {this.state.cardloaded &&
                          this.state.projecttype === "h"
                            ? loadingCard
                            : null}
                          <a
                            href="#/"
                            onClick={() => {
                              this.props.createModal(true);
                              this.setState({
                                projecttype: "h",
                                cardloaded: true,
                                loadingScreen: true,
                              });
                              this.checkProjectType("h");
                              this.setState({
                                loadingScreen: false,
                              });
                              //this.genProjectID();
                            }}
                          >
                            <CardBody className="card_imageBody">
                              <img
                                src={this.state.img_house}
                                alt="..."
                                className="card_imagePic"
                              />
                            </CardBody>
                            <CardFooter stats className="card_imageFooter">
                              <div
                                className={classes.stats}
                                style={{ fontSize: "12px" }}
                              >
                                Code: H
                              </div>
                              <div
                                className={classes.stats}
                                style={{ fontSize: "14px" }}
                              >
                                {
                                  switchIntl(this.props.toggleLng).project.home
                                    .house
                                }
                              </div>
                            </CardFooter>
                          </a>
                        </Card>
                      </GridItem>
                      <GridItem>
                        <Card style={{ maxWidth: "200px" }}>
                          {this.state.cardloaded &&
                          this.state.projecttype === "c"
                            ? loadingCard
                            : null}
                          <a
                            href="#/"
                            onClick={() => {
                              this.props.createModal(true);
                              this.setState({
                                projecttype: "c",
                                cardloaded: true,
                                loadingScreen: true,
                              });
                              this.checkProjectType("c");
                              this.setState({
                                loadingScreen: false,
                              });
                              //this.genProjectID();
                            }}
                          >
                            <CardBody className="card_imageBody">
                              <img
                                src={this.state.img_condo}
                                alt="..."
                                className="card_imagePic"
                              />
                            </CardBody>
                            <CardFooter stats className="card_imageFooter">
                              <div
                                className={classes.stats}
                                style={{ fontSize: "12px" }}
                              >
                                Code: C
                              </div>
                              <div
                                className={classes.stats}
                                style={{ fontSize: "14px" }}
                              >
                                {
                                  switchIntl(this.props.toggleLng).project.home
                                    .condo
                                }
                              </div>
                            </CardFooter>
                          </a>
                        </Card>
                      </GridItem>
                      <GridItem>
                        <Card style={{ maxWidth: "200px" }}>
                          {this.state.cardloaded &&
                          this.state.projecttype === "v"
                            ? loadingCard
                            : null}
                          <a
                            href="#/"
                            onClick={() => {
                              this.props.createModal(true);
                              this.setState({
                                projecttype: "v",
                                cardloaded: true,
                                loadingScreen: true,
                              });
                              this.checkProjectType("v");
                              this.setState({
                                loadingScreen: false,
                              });
                              //this.genProjectID();
                            }}
                          >
                            <CardBody className="card_imageBody">
                              <img
                                src={this.state.img_villa}
                                alt="..."
                                className="card_imagePic"
                              />
                            </CardBody>
                            <CardFooter stats className="card_imageFooter">
                              <div
                                className={classes.stats}
                                style={{ fontSize: "12px" }}
                              >
                                Code: V
                              </div>
                              <div
                                className={classes.stats}
                                style={{ fontSize: "14px" }}
                              >
                                {
                                  switchIntl(this.props.toggleLng).project.home
                                    .villa
                                }
                              </div>
                            </CardFooter>
                          </a>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Dialog
          fullScreen
          open={this.props.isCreateModal}
          transition={Transition}
          style={{ width: "100%" }}
          keepMounted
          onClose={() => this.props.createModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <div style={{ float: "top", width: "100%", height: "5%" }}>
              <div style={{ float: "right" }}>
                <Button
                  color="transparent"
                  onClick={() => {
                    this.confirmClose();
                  }}
                >
                  <Close />
                </Button>
              </div>
            </div>
            {this.checkProjectType(this.state.projecttype)}
          </DialogContent>
        </Dialog>
        <CustomTabs
          headerColor="info"
          tabs={[
            {
              tabName: (
                <div>
                  <h4 style={{ fontWeight: 600 }}>
                    {switchIntl(this.props.toggleLng).project.home.all_project}
                    {"  "}
                    <Badge color="info">{no_of_allproject}</Badge>
                  </h4>
                </div>
              ),
              tabContent: (function() {
                return <ReactTable />;
              })(),
            },
            {
              tabName: (
                <div>
                  <h4 style={{ fontWeight: 600 }}>
                    {switchIntl(this.props.toggleLng).project.home.sent_project}{" "}
                    {"  "}
                    <Badge color="info">{no_of_send_project}</Badge>
                  </h4>
                </div>
              ),
              tabContent: (function() {
                return <ReactTable2 />;
              })(),
            },
            {
              tabName: (
                <div>
                  <h4 style={{ fontWeight: 600 }}>
                    {
                      switchIntl(this.props.toggleLng).project.home
                        .saved_project
                    }{" "}
                    {"  "}
                    <Badge color="info">{no_of_saved_project}</Badge>
                  </h4>
                </div>
              ),
              tabContent: (function() {
                return <ReactTable3 />;
              })(),
            },
          ]}
        />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  modaltype: PropTypes.string,
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setNoOfProjects: (payload) => dispatch(setNoOfProjects(payload)),
  setProjectIDHome: (payload) => dispatch(setProjectIDHome(payload)),
  setShowModal: (payload) => dispatch(setShowModal(payload)),
  setHomeType: (payload) => dispatch(setHomeType(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  setProjectStatus: (payload) => dispatch(setProjectStatus(payload)),
  setHouseSection1: (payload) => dispatch(setHouseSection1(payload)),
  setHouseSection2: (payload) => dispatch(setHouseSection2(payload)),
  createModal: (payload) => dispatch(createModal(payload)),
  setLoading: (payload) => dispatch(setLoading(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
