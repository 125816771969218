// change main API request
// export const URL_SERVER = "http://172.17.1.75:4210";
// export const API_request = "http://172.17.1.75:4210/api/v1/";
// export const URL_SERVER = "http://dsrp.siitgis.com";
// export const API_request = "http://dsrp.siitgis.com/api/v1/";

export const URL_SERVER = "https://srp.powermap.live";
export const API_request = "https://srp.powermap.live/api/v1/";

// export const URL_SERVER = "https://srp.reicth.com";
// export const API_request = "https://srp.reicth.com/api/v1/";

// export const URL_SERVER = "";
// export const API_request = "/api/v1/";

// export const URL_SERVER = "http://localhost:4300";
// export const API_request = "http://localhost:4300/api/v1/";

export const recaptcha_key = "6LfiqSIaAAAAALsQMD4TjeB2avy9-BmSwZGf6DhX";
export const heremap_API_key = "4jd6f0fj_g_ZcmzhZAH3sVpHTO5AJBYQAInKFQjRgss";
export const heremap_appID = "W336uytfRUG9kKud1fnj";
export const heremap_appCode = "eYy0bLdL7xizsFDZkBfJGQ";
// const API_request = "/api/v1/";

export const template = URL_SERVER + "/Hbg/files/template.zip";
export const templateWithId = URL_SERVER + "/Hbg/files/srp-templateWithId.csv";
//report
// export const API_report = "http://srp.siitgis.com/api/v1/reportsrp";
// export const API_absortrate = "http://srp.siitgis.com/api/v1/report_catabsorp";
// export const API_reportMarket = "http://srp.siitgis.com/api/v1/list_cwtcomp";
// export const API_reportAllComp = "http://srp.siitgis.com/api/v1/report_allcomp";
// export const API_getorg = "http://srp.siitgis.com/api/v1/getorg";

export const API_report = API_request + "reportsrp";
export const API_absortrate = API_request + "report_catabsorp";
export const API_reportMarket = API_request + "list_cwtcomp";
export const API_reportAllComp = API_request + "report_allcomp";
export const API_getorg = API_request + "getorg";

export const API_noti = API_request + "listsrpnoti";
export const API_gen_secretkey = API_request + "genskey";
export const API_login = API_request + "login";
export const API_contact_us = API_request + "srpcontact";
export const API_FAQ = API_request + "faqs";
export const API_logout = API_request + "logout";
export const API_register = API_request + "register";
export const API_newpassword = API_request + "reset/";
export const API_easypassword = API_request + "easytoguesspassword";
export const API_get_guild = API_request + "listguild";
export const API_oldpassword = API_request + "oldpassword";
export const API_getold_resetpasswd = API_request + "oldresetpassword";
export const API_change_passwd = API_request + "changepassword";

export const API_sendresetpassword = API_request + "sendresetpassword";
//assets categories
export const API_category_house = API_request + "cat_house";
export const API_category_condo = API_request + "cat_condo";
export const API_category_villa = API_request + "cat_villa";

//get prov
export const API_get_prov = API_request + "getprov";
export const API_get_amphoe = API_request + "getamp";
export const API_get_tambon = API_request + "gettam";

//get asset typ
export const API_get_cate_house = API_request + "cat_house";
export const API_get_cate_condo = API_request + "cat_condo";
export const API_address_code = API_request + "point_tambon";
export const API_bbox_address = API_request + "bbox_cwt";

//Project
export const API_search_oldproject = API_request + "searchsrpoldprojid";

export const API_get_project_id = API_request + "gensrpid";
export const API_view_all_project = API_request + "listsrp";
export const API_get_project_status = API_request + "listpstatus";
export const API_addSRP = API_request + "addsrp";
export const API_saveSRP = API_request + "savesrp";
export const API_editSRP = API_request + "editsrp";
export const API_get_project_detail = API_request + "srpdetail";
export const API_deleteProject = API_request + "delsrp";
export const API_add_image = API_request + "addimg";
export const API_add_file = API_request + "addfile";
export const API_get_price = API_request + "getprice";

//ORG
export const API_addOrg = API_request + "addorg";
export const API_searchOrg = API_request + "searchorg";
export const API_profile = API_request + "profile";
export const API_updateprofile = API_request + "updateprofile";
//CSV
export const API_uploadCSV = API_request + "importsrpcsv";
export const API_uploadCSVWithId = API_request + "importwithidsrpcsv";

//report
export const API_report_sell = API_request + "report_sell";
export const API_report_sell_graph = API_request + "report_sellg";
export const API_getProv_report = API_request + "list_cwtcomp";

// 1.จำนวนโครงการทั้งหมด
export const API_report_numofproj = API_request + "report_numofproj";

// 2.หน่วยเหลือขายทั้งหมด
export const API_report_leftsellall = API_request + "report_leftsellall";

// 3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
export const API_report_sellaccumtransfer =
  API_request + "report_sellaccumtranfer";

// 4. หน่วยในผังโครงการทั้งหมด
export const API_report_unitproj = API_request + "report_unitproj";

// 5. หน่วยขายได้ใหม่
export const API_report_sellnew = API_request + "report_sellnew";

// 6. โอนสะสม
export const API_report_transfer_acc = API_request + "report_transfer";

// 7. โครงการเปิดขายใหม่ในไตรมาสนี้
export const API_report_newproject = API_request + "report_numofprojnew";

// 8. หน่วยขายได้สะสม
export const API_report_sell_acc = API_request + "report_sellaccum";

//9. หน่วยขายได้ รอโอน สะสม (Backlog)
export const API_report_backlog = API_request + "report_transferbacklog";

//10.หน่วยเปิดขายใหม่
export const API_report_open_new_sell = API_request + "report_opennew";

//11. หน่วยเหลือขายยังไม่ก่อสร้าง
export const API_report_leftsell = API_request + "report_leftsell";

//
//
//
//
//
export const API_report_sell_all = API_request + "report_sell_all";
export const API_report_sell_graph_all = API_request + "report_sellg_all";
// 1.จำนวนโครงการทั้งหมด
export const API_report_numofproj_all = API_request + "report_numofproj_all";

// 2.หน่วยเหลือขายทั้งหมด
export const API_report_leftsellall_all =
  API_request + "report_leftsellall_all";

// 3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
export const API_report_sellaccumtransfer_all =
  API_request + "report_sellaccumtranfer_all";

// 4. หน่วยในผังโครงการทั้งหมด
export const API_report_unitproj_all = API_request + "report_unitproj_all";

// 5. หน่วยขายได้ใหม่
export const API_report_sellnew_all = API_request + "report_sellnew_all";

// 6. โอนสะสม
export const API_report_transfer_acc_all = API_request + "report_transfer_all";

// 7. โครงการเปิดขายใหม่ในไตรมาสนี้
export const API_report_newproject_all =
  API_request + "report_numofprojnew_all";

// 8. หน่วยขายได้สะสม
export const API_report_sell_acc_all = API_request + "report_sellaccum_all";

//9. หน่วยขายได้ รอโอน สะสม (Backlog)
export const API_report_backlog_all =
  API_request + "report_transferbacklog_all";

//10.หน่วยเปิดขายใหม่
export const API_report_open_new_sell_all = API_request + "report_opennew_all";

//11. หน่วยเหลือขายยังไม่ก่อสร้าง
export const API_report_leftsell_all = API_request + "report_leftsell_all";
export const API_absortrate_all = API_request + "report_catabsorp_all";

export const API_report_sell_cp = API_request + "report_sell_cp";
export const API_report_sell_graph_cp = API_request + "report_sellg_cp";
// 1.จำนวนโครงการทั้งหมด
export const API_report_numofproj_cp = API_request + "report_numofproj_cp";

// 2.หน่วยเหลือขายทั้งหมด
export const API_report_leftsellall_cp = API_request + "report_leftsellall_cp";

// 3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
export const API_report_sellaccumtransfer_cp =
  API_request + "report_sellaccumtranfer_cp";

// 4. หน่วยในผังโครงการทั้งหมด
export const API_report_unitproj_cp = API_request + "report_unitproj_cp";

// 5. หน่วยขายได้ใหม่
export const API_report_sellnew_cp = API_request + "report_sellnew_cp";

// 6. โอนสะสม
export const API_report_transfer_acc_cp = API_request + "report_transfer_cp";

// 7. โครงการเปิดขายใหม่ในไตรมาสนี้
export const API_report_newproject_cp = API_request + "report_numofprojnew_cp";

// 8. หน่วยขายได้สะสม
export const API_report_sell_acc_cp = API_request + "report_sellaccum_cp";

//9. หน่วยขายได้ รอโอน สะสม (Backlog)
export const API_report_backlog_cp = API_request + "report_transferbacklog_cp";

//10.หน่วยเปิดขายใหม่
export const API_report_open_new_sell_cp = API_request + "report_opennew_cp";

//11. หน่วยเหลือขายยังไม่ก่อสร้าง
export const API_report_leftsell_cp = API_request + "report_leftsell_cp";
export const API_absortrate_cp = API_request + "report_catabsorp_cp";

export const API_exp_CSV = API_request + "exp_csv";
