/* eslint-disable no-console */
/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import CryptoJS from "crypto-js";
import { connect } from "react-redux";
import {
  API_login,
  API_logout,
  API_gen_secretkey,
  recaptcha_key,
} from "config/API.jsx";

import setLoading from "DataControl/actions/setLoading.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import SweetAlert from "react-bootstrap-sweetalert";
import { ClipLoader } from "react-spinners";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loadingScreen from "../Components/Loading.js";

// import combineStyles from "assets/combinedStyles.js";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
// import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// GHBank assets
import GHBankLogoPSurvey from "assets/img/srplogo.svg";

// switch language function
import switchIntl from "translate/switchIntl.js";

// const combinedLoginStyles = combineStyles(loginPageStyle, sweetAlertStyle)

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      alert: null,
      show: false,
      // login form
      loginEmail: "",
      loginEmailState: "",
      loginPassword: "",
      loginPasswordState: "",
      loginCaptcha: "",
      loginCaptchaState: "",
      // check loading
      isLoading: false,
      // bt loading
      btLoading: false,
      disButton: false,
      key: undefined,
      token: undefined,
      show_pass: false,
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.forceLogin = this.forceLogin.bind(this);
    this.validateLogin = this.validateLogin.bind(this);
    this.handleAutoLogin = this.handleAutoLogin.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
    setLoading: PropTypes.object.isRequired,
  };

  componentDidMount() {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  // start of recaptcha callback function
  recaptchaCallback = () => {
    return null;
  };
  verifyCallback = (recaptchaToken) => {
    if (recaptchaToken != null) {
      this.setState({
        loginCaptcha: recaptchaToken,
        loginCaptchaState: "success",
      });
    }
  };
  expiredCallback = () => {
    this.setState({ loginCaptcha: "", loginCaptchaState: "error" });
  };
  // end of recaptcha callback function

  // start of dynsmic alert handled
  dynamicAlert(
    alertType,
    title,
    content,
    conTxt,
    canTxt,
    showCancel,
    confirmFunction,
    cancelFunction
  ) {
    this.setState({
      alert: (
        <SweetAlert
          type={alertType}
          style={{
            display: "block",
            color: "black",
            fontSize: "18px",
          }}
          customClass="modalContent"
          title={title}
          onConfirm={confirmFunction}
          onCancel={cancelFunction}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={conTxt}
          cancelBtnText={canTxt}
          showCancel={showCancel}
        >
          {content}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  // end of dynsmic alert handled

  // === start of auto login function ===
  forceLogin() {
    var tokenData = this.state.token;
    var request = new Request(API_logout, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            alert: (
              <SweetAlert
                type="success"
                style={{
                  display: "block",
                  color: "black",
                  fontSize: "18px",
                  paddingBottom: "50px",
                }}
                customClass="modalContent"
                showConfirm={false}
                onConfirm={this.hideAlert}
                title={
                  switchIntl(this.props.toggleLng).sweetalert.forceLogin.title
                }
              >
                {switchIntl(this.props.toggleLng).sweetalert.forceLogin.content}
              </SweetAlert>
            ),
          });
          this.handleAutoLogin();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleAutoLogin() {
    let self = this;
    setTimeout(() => {
      this.hideAlert();
    }, 1000);
    setTimeout(() => {
      this.props.setLoading(true);
    }, 1000);

    var body = {
      member_email: this.state.loginEmail,
      member_passwd: this.state.loginPassword,
    };

    var request = new Request(API_login, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.code === 200) {
          console.log(responseJson.data);
          const key = self.state.key;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);
          const aes = CryptoJS.AES.encrypt(
            JSON.stringify(responseJson.data),
            keyutf,
            { iv: iv }
          );
          const aesStr = aes.toString();
          localStorage.setItem("session", aesStr);
          setTimeout(() => {
            // eslint-disable-next-line react/prop-types
            self.props.history.push("");
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // === end of auto login function ===

  // === start of handle input forms ===
  change(event, stateName, type) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value,
          });
        } else {
          this.setState({ [stateName + "State"]: "error", [stateName]: null });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 8, 16)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value,
          });
        } else {
          this.setState({ [stateName + "State"]: "error", [stateName]: null });
        }
        break;
      default:
        break;
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    //var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    ///if (emailRex.test(value)) {
    return true;
    //}
    //return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, minLength, maxLength) {
    if (value.length >= minLength && value.length <= maxLength) {
      return true;
    }
    return false;
  }

  // === end of handle input forms ===

  // loading function
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }
  // end of loading function

  // handle trigger function
  validateLogin() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
    if (this.state.loginCaptcha === "") {
      this.setState({ loginCaptchaState: "error" });
    }
    if (
      this.state.loginEmailState === "success" &&
      this.state.loginPasswordState === "success" &&
      this.state.loginCaptchaState === "success"
    ) {
      this.handleLoginRequest();
    } else {
      this.dynamicAlert(
        "warning",
        switchIntl(this.props.toggleLng).sweetalert.captcha.title,
        switchIntl(this.props.toggleLng).sweetalert.captcha.content,
        switchIntl(this.props.toggleLng).sweetalert.captcha.conBtTxt,
        "",
        false,
        this.hideAlert
      );
    }
  }
  // call login API
  handleLoginRequest() {
    this.setState({ btLoading: true, disButton: true });

    var body = {
      member_email: this.state.loginEmail,
      member_passwd: this.state.loginPassword,
    };
    var request = new Request(API_login, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({ btLoading: false, disButton: false });
        if (responseJson.code === 200) {
          this.setState({
            alert: (
              <SweetAlert
                type="success"
                style={{
                  display: "block",
                  color: "black",
                  fontSize: "18px",
                  paddingBottom: "50px",
                }}
                customClass="modalContent"
                showConfirm={false}
                onConfirm={this.hideAlert}
                title={
                  switchIntl(this.props.toggleLng).sweetalert.forceLogin.title
                }
              >
                {switchIntl(this.props.toggleLng).sweetalert.forceLogin.content}
              </SweetAlert>
            ),
          });

          const key = this.state.key;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);
          const aes = CryptoJS.AES.encrypt(
            JSON.stringify(responseJson.data),
            keyutf,
            { iv: iv }
          );
          const aesStr = aes.toString();
          localStorage.setItem("session", aesStr);

          setTimeout(() => {
            this.props.setLoading(true);
          }, 1000);
          setTimeout(() => {
            // eslint-disable-next-line react/prop-types
            this.props.history.push("");
          }, 3000);
        } else if (responseJson.code === 203) {
          // console.log("waiting for email confirm");
          this.dynamicAlert(
            "error",
            switchIntl(this.props.toggleLng).sweetalert.confirmEmail.title,
            switchIntl(this.props.toggleLng).sweetalert.confirmEmail.content,
            switchIntl(this.props.toggleLng).sweetalert.confirmEmail.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        } else if (responseJson.code === 205) {
          // console.log("login with your already login");
          this.setState({
            token: responseJson.data.token,
          });
          this.dynamicAlert(
            "warning",
            switchIntl(this.props.toggleLng).sweetalert.alreadyLogin.title,
            switchIntl(this.props.toggleLng).sweetalert.alreadyLogin.content,
            switchIntl(this.props.toggleLng).sweetalert.alreadyLogin.conBtTxt,
            switchIntl(this.props.toggleLng).sweetalert.alreadyLogin.canBtTxt,
            true,
            this.forceLogin,
            this.hideAlert
          );
        } else {
          // console.log("no accout");
          this.dynamicAlert(
            "error",
            switchIntl(this.props.toggleLng).sweetalert.noAccount.title,
            switchIntl(this.props.toggleLng).sweetalert.noAccount.content,
            switchIntl(this.props.toggleLng).sweetalert.noAccount.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // main render
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.alert}
        {/* {loadingScreen(this.state.isLoading)} */}
        <GridContainer justify="center" style={{ marginBottom: "20px" }}>
          <GridItem xs={12} sm={6} md={4}>
            <img src={GHBankLogoPSurvey} width="100%" alt="ghlogo" />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  style={{ background: "#283F88" }}
                  color="info"
                >
                  <h4 className={classes.cardTitle}>
                    {switchIntl(this.props.toggleLng).login.header}
                  </h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={this.state.loginEmailState === "success"}
                    error={this.state.loginEmailState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).login.emailInput
                    }
                    id="loginemail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(event, "loginEmail", "email"),
                      type: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    success={this.state.loginPasswordState === "success"}
                    error={this.state.loginPasswordState === "error"}
                    labelText={switchIntl(this.props.toggleLng).login.passInput}
                    id="loginpassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(event, "loginPassword", "password"),
                      type: this.state.show_pass ? "text" : "password",
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: "pointer" }}
                        >
                          {this.state.show_pass ? (
                            <Icon
                              className={classes.inputAdornmentIcon}
                              onClick={() =>
                                this.setState({
                                  show_pass: false,
                                })
                              }
                            >
                              visibility_off
                            </Icon>
                          ) : (
                            <Icon
                              className={classes.inputAdornmentIcon}
                              onClick={() =>
                                this.setState({
                                  show_pass: true,
                                })
                              }
                            >
                              visibility
                            </Icon>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="forgetPassword">
                    <NavLink to={"/auth/reset-page"} style={{ color: "gray" }}>
                      {switchIntl(this.props.toggleLng).login.forget}
                    </NavLink>
                  </div>
                  <div className="recaptchaContainer">
                    <Recaptcha
                      // hl={this.props.toggleLng}
                      sitekey={recaptcha_key}
                      render="explicit"
                      onloadCallback={this.recaptchaCallback}
                      verifyCallback={this.verifyCallback}
                      expiredCallback={this.expiredCallback}
                    />
                  </div>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <NavLink
                    to={"#"}
                    style={{ width: "100%", paddingRight: "5px" }}
                    onClick={this.state.disButton ? null : this.validateLogin}
                  >
                    <Button
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorLogin"
                    >
                      {this.loadingButton(this.state.btLoading)}
                      {switchIntl(this.props.toggleLng).login.loginBt}
                    </Button>
                  </NavLink>
                  <NavLink
                    to={"/auth/register-page"}
                    style={{ width: "100%", paddingLeft: "5px" }}
                  >
                    <Button
                      simple
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorReg"
                    >
                      {switchIntl(this.props.toggleLng).login.regBt}
                    </Button>
                  </NavLink>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (bool) => dispatch(setLoading(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(LoginPage));
