import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import PDFViewer from "mgr-pdf-viewer-react";
import Manual from "assets/srp_manual.pdf";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import switchIntl from "translate/switchIntl.js";

class Dashboard extends React.Component {
  state = {
    value: 0,
    scale: 1.5
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  increaseScale = () => {
    if (this.state.scale < 1.5) {
      this.setState(({ scale }) => ({ scale: scale + 0.2 }));
    }
  };

  decreaseScale = () => {
    if (this.state.scale > 0.3) {
      this.setState(({ scale }) => ({ scale: scale - 0.2 }));
    }
  };
  render() {
    return (
      <div>
        <Card>
          <CardHeader color="info">
            {switchIntl(this.props.toggleLng).manual.header}
            <div style={{ float: "right" }}>
              <Button color="default" size="sm" onClick={this.decreaseScale}>
                -
              </Button>
              <Button color="default" size="sm" onClick={this.increaseScale}>
                +
              </Button>
            </div>
          </CardHeader>
          <PDFViewer
            document={{
              file: Manual
            }}
            scale={this.state.scale}
          />
        </Card>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  setLanguage: payload => dispatch(setLanguage(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
