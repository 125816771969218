import React, { useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// react plugin for creating charts

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import {
  API_uploadCSV,
  API_gen_secretkey,
  API_uploadCSVWithId,
} from "config/API.jsx";
import CryptoJS from "crypto-js";
import { template, templateWithId } from "config/API.jsx";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  //color: "#bdbdbd",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const MAX_SIZE = 10000000;

function Accept(props) {
  var token = props.token;
  let onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      //acceptedFiles.forEach(file => reader.readAsArrayBuffer(file))
      //acceptedFiles.forEach(file => reader.readAsText(file))
      acceptedFiles.forEach((file) => {
        handleSubmit(file, token);
      });
    },
    [props]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    accept: ".csv",
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [[isDragActive, isDragReject]]
  );

  const acceptedFilesItems =
    acceptedFiles.length !== 0
      ? acceptedFiles.map((file) => (
          <li key={file.path}>
            {file.path} - {file.size} bytes
          </li>
        ))
      : "ไม่ได้เลือกไฟล์ใดๆ";

  const rejectedFilesItems = rejectedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleSubmit = (csv, token) => {
    // console.log("handle submit..." + props.token);
    let formData = new FormData();
    formData.append("csv", csv);
    console.log(props.API);
    var request = new Request(props.API, {
      method: "POST",
      headers: new Headers({
        token: token,
      }),
      body: formData,
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.code === 200) {
          alert("อัพโหลดโครงการสำเร็จ");
        } else if (data.code === 405) {
          alert(
            "การเข้ารหัสไฟล์ (Encoding ไม่ถูกต้อง) กรุณาเช็ค encoding ของไฟล์ที่อัพโหลด (ต้องเป็น UTF-8 เท่านั้น)"
          );
        } else if (data.code === 406) {
          alert(
            "ข้อมูลจังหวัด อำเภอ ตำบลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้งก่อนอัพโหลด ในบรรทัดที่ " +
              data.data
          );
        } else {
          alert("ขออภัย มีข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
        }
      });
  };

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          ลากไฟล์มาวางที่นี่ หรือ{" "}
          <Button color="info">คลิกเพื่อเลือกไฟล์</Button>
        </p>
        <em>(สามารถอัพโหลดได้เฉพาะไฟล์ *.csv เท่านั้น)</em>
      </div>
      <aside>
        <h4>สถานะไฟล์</h4>
        <ul color="green">{acceptedFilesItems}</ul>
        {/* {sample}
        <h4>ไฟล์ที่ถูกปฏิเสธ</h4>
        <ul color="red">{rejectedFilesItems}</ul> */}
      </aside>
    </section>
  );
}

class Dashboard extends React.Component {
  constructor() {
    super();
    this.onDrop = (files) => {
      this.setState({ files });
    };

    this.state = {
      files: [],
    };
  }

  componentDidMount() {
    this.getToken();
  }

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  gotoproject = () => {
    this.props.history.push("/project");
  };

  download = () => {};

  render() {
    const { classes } = this.props;
    const files = this.state.files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        <label>
          <a href="#" onClick={this.gotoproject.bind(this)}>
            โครงการ
          </a>{" "}
          / <a href="#">อัพโหลดโครงการเก่า</a>
        </label>
        <Card>
          <CardHeader>
            <h4>อัพโหลดไฟล์โครงการเข้าสู่ระบบ</h4>
          </CardHeader>
          <CardBody>
            <label>
              ท่านสามารถอัพโหลดไฟล์โครงการเก่าเข้าสู่ระบบได้
              โดยสามารถอัพโหลดได้ในรูปแบบไฟล์ .csv
            </label>
            <h4>ข้อกำหนด</h4>
            <ui>
              <li>สามารถอัพโหลดได้เฉพาะไฟล์ .csv เท่านั้น</li>
              <li>
                {" "}
                ไฟล์ที่อัพโหลดต้องมี encoding ในรูปแบบ UTF8 หรือ window 874{" "}
              </li>
              <li style={{ color: "red" }}>
                เมื่อทำการอัพโหลดแล้วไม่สามารถแก้ไขข้อมูลได้
                กรุณาตรวจสอบข้อมูลก่อนทำการอัพโหลดทุกครั้ง
              </li>
            </ui>
            <div></div>
          </CardBody>
          <div style={{ textAlign: "center" }}>
            <h4>อัพโหลดโครงการใหม่</h4>
            *ระบบจะสร้างเลขอ้างอิงให้
            <br />
            <a href={template} target="_blank" download>
              <Button>ดาวน์โหลดไฟล์ตัวอย่าง template</Button>
            </a>
            <Accept token={this.state.tokenData} API={API_uploadCSV} />
          </div>
          <div style={{ textAlign: "center" }}>
            <h4>อัพโหลดโครงการที่มีเลขอ้างอิงแล้ว</h4>
            *ผู้ใช้แนบเลขอ้างอิงมากับไฟล์ csv
            <br />
            <a href={templateWithId} target="_blank" download>
              <Button>ดาวน์โหลดไฟล์ตัวอย่าง template</Button>
            </a>
            <Accept token={this.state.tokenData} API={API_uploadCSVWithId} />
          </div>
        </Card>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
