import { createStore } from "redux";
import rootReducer from "../reducers";

function configureStore(
  state = {
    mapImagePath: "",
    sessionModal: null,
    token: null,
    toggleLng: "TH",
    latlon: { lat: 0, lng: 0 },
    bbox: null,
    prov_amphoe_tambon: {
      prov_id: 0,
      amp_id: 0,
      tam_id: 0,
      prov_name: "",
      amp_name: "",
      tam_name: ""
    },
    setHomeSubType: null,
    minmax_house: {
      house: [0, 0],
      twinhouse: [0, 0],
      townhouse: [0, 0],
      shophouse: [0, 0],
      land: [0, 0]
    },
    saleinfo: {
      unitall: {
        "0": 0
      }
    },
    showModal_detail: false,
    projectIDHome: "",
    noOfProjects: {
      no_of_allproject: 0,
      no_of_send_project: 0,
      no_of_saved_project: 0
    },
    project_status: 1,
    houseInfoSection1: {
      srp_projectid: "-",
      srp_name: "-",
      srp_selldate: "-",
      srp_address: "-",
      lookup_asset: [
        { name: "บ้านเดี่ยว", value: 1, min: 0, max: 0 },
        { name: "บ้านแฝด", value: 2, min: 0, max: 0 },
        { name: "ทาวน์เฮาส์ หรือ ทาวน์โฮม", value: 3, min: 0, max: 0 },
        { name: "อาคารพาณิชย์", value: 4, min: 0, max: 0 },
        { name: "ที่ดินเปล่า", value: 5, min: 0, max: 0 }
      ]
    },
    pointsOnMap: "",
    isCreateModal: false,
    logoutModal: null,
    hometype: {
      home: 1,
      land: 0,
      shophouse: 0,
      townhouse: 0,
      twinhouse: 0
    },
    absorption: [],
    graphData: [],
    graphData_graphView: [],
    showgraph: []
  }
) {
  return createStore(rootReducer, state);
}

export default configureStore;
