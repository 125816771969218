/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import { connect } from "react-redux";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import switchIntl from "translate/switchIntl.js";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import loading from "assets/img/loading.gif";

import CryptoJS from "crypto-js";
import {
  URL_SERVER,
  API_gen_secretkey,
  API_profile,
  API_updateprofile
} from "config/API.jsx";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nochange: true,
      place: "tr",
      color: "success",
      message: "",
      file: null,
      imagePreviewUrl: loading,
      imgBase64: "",
      member_id: "",
      member_username: "",
      token: "",
      fnamestate: "",
      allinfo: {
        member_username: "",
        member_name: "",
        member_lname: "",
        member_email: "",
        member_status: "",
        member_tel: "",
        member_type: "",
        member_verify: "",
        member_profimg: loading,
        org_address: "",
        org_name: "",
        org_district: "",
        org_fax: "",
        org_floor: "",
        org_namebuilding: "",
        org_province: "",
        org_road: "",
        org_saletel: "",
        org_soi: "-",
        org_subdistrict: "",
        org_tel: "",
        org_website: "",
        p_value: "",
        tam_value: ""
      }
    };

    //this.requestToken = this.requestToken.bind(this);
  }
  fileInput = React.createRef();

  componentDidMount() {
    this.requestToken();
  }

  handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let self = this;
    try {
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
          self.handleSubmit();
        };
        reader.readAsDataURL(file);
      } else {
        alert("Please select the image file");
      }
    } catch (err) {
      console.log(err);
    }
  };

  showLoadingImage = () => {
    let allinfo = { ...this.state.allinfo };
    allinfo.member_profimg = loading;
    this.setState({ allinfo: allinfo });
  };

  handleSubmit = () => {
    let self = this;
    self.showLoadingImage();
    let imgbase64_tmp = this.state.imagePreviewUrl;
    let imgbase64 = imgbase64_tmp.split(",");

    let info = {
      member_id: self.state.member_id,
      member_name: self.state.allinfo.member_name,
      member_lname: self.state.allinfo.member_lname,
      member_tel: self.state.allinfo.member_tel,
      member_profimg: imgbase64[1]
    };

    var request = new Request(API_updateprofile, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(info)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          switch (data.code) {
            case 200:
              //success
              self.getUserProfile(self.state.member_id);
              setTimeout(() => {
                self.showNotification("success", "อัพโหลดรูปภาพสำเร็จ");
              }, 1500);
              break;
            default:
              //failed
              self.showNotification(
                "warning",
                "ไม่สามารถอัพโหลดรูปได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง"
              );
              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  setOrgInfo = companyinfo => {
    this.setState({ company: companyinfo });
  };

  getUserProfile = member_id => {
    let self = this;
    var request = new Request(API_profile, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify({
        member_id: member_id
      })
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          let info = data.data;
          switch (data.code) {
            case 200:
              //success
              //self.setState({ allinfo: info });
              self.setState(
                {
                  allinfo: info
                },
                () => {
                  let profile_path = info.member_profimg;
                  let imgpath = URL_SERVER + profile_path + "?" + Math.random();
                  let allinfo = { ...self.state.allinfo };
                  allinfo.member_profimg = imgpath;
                  self.setState({ allinfo: allinfo });
                }
              );

              break;
            default:
              //failed

              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  requestToken = () => {
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
            member_id: jsonDec.member_id,
            member_username: jsonDec.member_username
          });
          this.getUserProfile(jsonDec.member_id);
        }
      })
      .catch(err => {});
  };

  handleClick = () => {
    this.fileInput.current.click();
  };

  change(event, stateName, type) {
    let allinfo = this.state.allinfo;
    allinfo[stateName] = event.target.value;
    this.setState({ allinfo: allinfo });
    switch (type) {
      case "tel":
        if (this.verifyPhone(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "text":
        if (this.verifyName(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      default:
        break;
    }

    //enable update button
    this.setState({ nochange: false });
  }

  verifyPhone = value => {
    var phoneRex = /^0[0-9]+$/;
    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyName(value) {
    var nameRex = /^[A-Za-zก-๙]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyAll = () => {
    if (
      this.state.member_name_state === "error" ||
      this.state.member_lname_state === "error" ||
      this.state.member_tel_state === "error"
    ) {
      this.showNotification("warning", "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก");
    } else {
      this.updateProfile();
    }
  };

  updateProfile = () => {
    let self = this;
    let info = {
      member_id: self.state.member_id,
      member_name: self.state.allinfo.member_name,
      member_lname: self.state.allinfo.member_lname,
      member_tel: self.state.allinfo.member_tel,
      member_profimg: ""
    };

    var request = new Request(API_updateprofile, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token
      }),
      body: JSON.stringify(info)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          switch (data.code) {
            case 200:
              //success
              self.showNotification("success", "เปลี่ยนแปลงข้อมูลเรียบร้อย");
              self.getUserProfile(self.state.member_id);

              //clear checking state
              self.setState({
                member_name_state: "",
                member_lname_state: "",
                member_tel_state: ""
              });
              break;
            default:
              //failed
              self.showNotification(
                "warning",
                "ไม่สามารถเปลี่ยนแปลงข้อมูลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง"
              );
              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  render() {
    const { classes, toggleLng } = this.props;

    return (
      <div>
        <GridContainer>
          <Snackbar
            place={this.state.place}
            color={this.state.color}
            message={this.state.message}
            open={this.state.open}
            closeNotification={() => this.setState({ open: false })}
            close
          />
          <GridItem xs={12} sm={12} md={4}>
            <Card profile lg={12}>
              <CardAvatar profile>
                <a onClick={e => e.preventDefault()}>
                  <img
                    src={this.state.allinfo.member_profimg}
                    alt="..."
                    width="150px"
                    height="150px"
                  />
                </a>
              </CardAvatar>
              <CardBody>
                <Button color="rose">
                  {switchIntl(toggleLng).userprofile.upload_new_profile}
                  <input
                    type="file"
                    onChange={this.handleImageChange}
                    ref={this.fileInput}
                    accept="image/x-png,image/gif,image/jpeg"
                    style={{
                      fontSize: "100px",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      opacity: 0
                    }}
                  />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4>{switchIntl(toggleLng).userprofile.header}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <p>{switchIntl(toggleLng).userprofile.username}</p>
                    <label>{this.state.member_username}</label>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={switchIntl(toggleLng).userprofile.firstname}
                      success={this.state.member_name_state === "success"}
                      error={this.state.member_name_state === "error"}
                      id="first-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, "member_name", "text"),
                        value: this.state.allinfo.member_name
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={switchIntl(toggleLng).userprofile.lastname}
                      success={this.state.member_lname_state === "success"}
                      error={this.state.member_lname_state === "error"}
                      id="last-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, "member_lname", "text"),
                        value: this.state.allinfo.member_lname
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={switchIntl(toggleLng).userprofile.phone}
                      success={this.state.member_tel_state === "success"}
                      error={this.state.member_tel_state === "error"}
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, "member_tel", "tel"),
                        value: this.state.allinfo.member_tel
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <p>{switchIntl(toggleLng).userprofile.email}</p>
                    <label>{this.state.allinfo.member_email}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}><br></br></GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <p>{switchIntl(toggleLng).userprofile.company}</p>
                    <label>{this.state.allinfo.org_name}</label>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.address}</p>
                    <label>{this.state.allinfo.org_address}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.buildingname}</p>
                    <label>{this.state.allinfo.org_namebuilding}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.floor}</p>
                    <label>{this.state.allinfo.org_floor}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.soi}</p>
                    <label>{this.state.allinfo.org_soi}</label>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.road}</p>
                    <label>{this.state.allinfo.org_road}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.prov}</p>
                    <label>{this.state.allinfo.org_province}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.dis}</p>
                    <label>{this.state.allinfo.org_district}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <p>{switchIntl(toggleLng).userprofile.subdis}</p>
                    <label>{this.state.allinfo.org_subdistrict}</label>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <p>{switchIntl(toggleLng).userprofile.tel}</p>
                    <label>{this.state.allinfo.org_tel}</label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <p>{switchIntl(toggleLng).userprofile.fax}</p>
                    <label>{this.state.allinfo.org_fax}</label>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={6}>
                    <p>{switchIntl(toggleLng).userprofile.set}</p>
                    <label>{this.state.allinfo.org_saletel}</label>
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={12}>
                    <p>{switchIntl(toggleLng).userprofile.web}</p>
                    <label>{this.state.allinfo.org_website}</label>
                  </GridItem>
                </GridContainer>

                <Button
                  color="rose"
                  disabled={this.state.nochange}
                  className={classes.updateProfileButton}
                  onClick={() => this.verifyAll()}
                >
                  {switchIntl(toggleLng).userprofile.submit}
                </Button>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object
};
const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(UserProfile)
);
