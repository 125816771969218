/*eslint-disable*/

import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

//External Lib
import CryptoJS from "crypto-js";
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import HouseTable from "views/Project/Home/HouseTable.jsx";
import Button from "components/CustomButtons/Button.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

//redux 
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setHouseSection1 from "DataControl/actions/setHouseSection1.js";
import switchIntl from "translate/switchIntl.js";
//API
import { API_saveSRP, API_gen_secretkey } from "config/API.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      design: false,
      code: false,
      develop: false,
      tokenData: null
    };

    this.UniqueInArray = this.UniqueInArray.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.houseInfoSection1 !== this.props.houseInfoSection1) {
      this.showDate(moment(this.props.houseInfoSection1.srp_selldate,'MM/DD/YYYY').format('DD/MM/YYYY'));
    }
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  sendState() {
    return this.state;
  }

  UniqueInArray = inputArray =>
  {
      let result = 0;
      for(var i=0; i< inputArray.length;i++)
      {
          result ^= inputArray[i];
      }
                      
      return (result>0 ? result : -1);
  }
  
  isValidated() {
    if(!this.props.houseInfoSection2) {
      console.log("กรุณาตรวจสอบข้อมูลในส่วนที่ 2 ก่อนทำการส่งข้อมูล");
      return false;
    } else {
      // console.log(this.props.houseInfoSection2);
      // console.log(this.props.dropdown_house_section2);
      

      let selected_subtype_section1 = this.props.dropdown_house_section2.map(props => {
        return parseInt(props.value);
      });
      selected_subtype_section1 = selected_subtype_section1.sort();

      const distinct = (value, index, self) => {
        return self.indexOf(value) === index;
      }
      let selected_subtype_section2 = this.props.houseInfoSection2.srpunit_cat.filter(distinct);
      selected_subtype_section2 = selected_subtype_section2.sort();

      if(JSON.stringify(selected_subtype_section1) === JSON.stringify(selected_subtype_section2)) {
        console.log("รอเช็คหัวราคา...");
        if(this.checkMustSelectedPrice()){ 
          return true;
        }else {
          alert("กรุณาใส่ช่วงราคาให้ครบถ้วน (ราคาต่ำสุด - สูงสุดในแต่ละประเภท)");
          return false;
        }
      } else {
        alert("กรุณาเพิ่มรายะเอียดประเภทที่อยู่อาศัยให้ครบตามที่ท่านเลือกในส่วนที่ 1")
        return false;
      }
    }
  }

  checkMustSelectedPrice () {
    try {
      let data_section2 = this.props.houseInfoSection2;
      let mustSelectedPrice = this.props.mustSelectedPrice;
      
      console.log(data_section2.srpunit_cat);
      //get unique selected cate type
      let cate_type = data_section2.srpunit_cat.filter((v, i, a) => a.indexOf(v) === i);

      const rowdata_input = this.props.store_priceid;   

      let uplow_data = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: []
      };
      for (var i=0;i< cate_type.length;i++) {
        let lower = mustSelectedPrice[cate_type[i]][0].props.value;
        let upper = mustSelectedPrice[cate_type[i]][0].props.value;
        let id = cate_type[i];
        if(mustSelectedPrice[cate_type[i]].length === 2) {
          upper = mustSelectedPrice[cate_type[i]][1].props.value;
        }
        uplow_data[id] = [lower, upper];
      }

      console.log("========>>>>>")
      console.log(rowdata_input); // ของเรา
      console.log(uplow_data); //upper / lowwer

      console.log("======xxxxxx")
      const arr_length = Object.values(uplow_data);
      //เขียน MATCH

      let result_ja = true;
      for (var i=1; i<arr_length.length ; i++) {

        if(uplow_data[i].length !== 0 ) {
          let min = uplow_data[i][0];
          let max = uplow_data[i][1];

          if(rowdata_input[i].indexOf(min) === -1){
            result_ja = false;
          } 

          if(rowdata_input[i].indexOf(max) === -1){
            result_ja = false;
          } 
        }
      }

      console.log(result_ja);
      return result_ja;

    } catch(err) {
      console.log(err);
      return true;
    }
  }

  saveData() {
    let self = this;
    let data_section1 = this.props.houseInfoSection1;
    let data_section2 = this.props.houseInfoSection2;
    let all_data = Object.assign({}, data_section1, data_section2);

    console.log(all_data);
    var request = new Request(API_saveSRP, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData
      }),
      body: JSON.stringify(all_data)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          switch (data.code) {
            case 200:
              //success
              self.setState({
                alert: (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => self.hideAlert()}
                    onCancel={() => self.hideAlert()}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    {switchIntl(self.props.toggleLng).basic_word.save_success}
                  </SweetAlert>
                )
              });
              break;
            default:
              self.setState({
                alert: (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => self.hideAlert()}
                    onCancel={() => self.hideAlert()}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    {switchIntl(self.props.toggleLng).basic_word.save_failed}
                  </SweetAlert>
                )
              });
              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  validateField = () => {
    let self = this;
    if(!this.props.houseInfoSection2) {
      self.setState({
        alert: (
          <SweetAlert
            info
            style={{ display: "block", marginTop: "-100px" }}
            title={""}
            onConfirm={() => self.hideAlert()}
            onCancel={() => self.hideAlert()}
            confirmBtnText={
              switchIntl(self.props.toggleLng).basic_word.ok
            }
          >
            กรุณาตรวจสอบข้อมูลในส่วนที่ 2 ก่อนทำการบันทึก
          </SweetAlert>
        )
      });
    } else {
      this.getToken();
    }
  }

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData
          });

          self.saveData();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  showDate(dateStr) {
    console.log("format data === ");
    console.log(dateStr);
    const DATE_REGEXP = new RegExp('^(0?[1-9]|[1-2][0-9]|3[0-1])/(0?[1-9]|1[0-2])/([0-9]{4})$', 'gi');
    dateStr = dateStr.replace(DATE_REGEXP,(str, day, month, year) => {
        return `${day}/${month}/${parseInt(year, 10) + 543}`;
    });
    this.setState({salesDate: dateStr});
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <h3 className={classes.infoText}>รายงานข้อมูลโครงการ{}</h3>
        <GridContainer justify="center">
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>รหัสอ้างอิง</span>}
              id="project_id"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                // eslint-disable-next-line react/prop-types
                value: this.props.houseInfoSection1.srp_projectid,
                disabled: true
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>ชื่อโครงการ</span>}
              id="project_name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.props.houseInfoSection1.srp_name,
                disabled: true
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>วันที่เปิดขาย</span>}
              id="salesDate"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.state.salesDate,
                disabled: true
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>ที่ตั้งโครงการ</span>}
              id="address"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.props.houseInfoSection1.srp_address,
                disabled: true
              }}
            ></CustomInput>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <HouseTable tokenData={this.state.tokenData} assetType={this.props.stepProps.assetType}></HouseTable>
        </GridContainer>
        <GridContainer>
        <GridItem xs={12} sm={12}>
          <div style={{ float: "right" }}>
            <Button color="info" onClick={() => this.validateField()}>
              {" "}
              บันทึก
            </Button>
          </div>
        </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setHouseSection1: payload => dispatch(setHouseSection1(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Step2));
