import React from "react";
import PropTypes from "prop-types";

import BootstrapTable from "react-bootstrap-table-next";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Folder";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CryptoJS from "crypto-js";

import Map from "views/Map/MapDashBoard.jsx";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setLoading from "DataControl/actions/setLoading.js";
import setNoOfProjects from "DataControl/actions/setNoOfProjects.js";
import setPointsOnMap from "DataControl/actions/setPointsOnMap.js";

// switch language function
import switchIntl from "translate/switchIntl.js";

import paginationFactory from "react-bootstrap-table2-paginator";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { API_view_all_project, API_gen_secretkey } from "config/API.jsx";
import GHBankLoading from "assets/img/reicLoading.gif";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      projects: [],
      tokenData: null,
      loadingScreen: true
    };
  }

  componentDidMount() {
    this.getToken();
  }

  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
      //
    }
  }

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData
          });
          this.loadProject();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  loadProject = () => {
    var param = {
      srp_name: 0
    };

    let self = this;
    var request = new Request(API_view_all_project, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          switch (data.code) {
            case 200:
              //success
              let rawData = data.data[0].jsonb_build_object.features;
              let featureCollection = JSON.stringify(
                data.data[0].jsonb_build_object
              );
              console.log(rawData)
              console.log(featureCollection)
              self.countProjectsType(rawData);
              self.props.setPointsOnMap(featureCollection);
              self.formatData(rawData);
              self.setState({loadingScreen: false});
              break;
            default:
              //failed
              
              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
    return false;
  };

  formatData(rawdata) {
    let arr = [];
    for (var i = 0; i < rawdata.length; i++) {
      let tmpData = {
        id: i,
        name: rawdata[i].properties.srp_name,
        status: rawdata[i].properties.cstats_name
      };
      arr.push(tmpData);
    }
    this.setState({
      projects: arr
    });
  }

  countProjectsType(rawdata) {
    let all = 0;
    let sent = 0;
    let saved = 0;

    for (var i = 0; i < rawdata.length; i++) {
      let approval = rawdata[i].properties.srp_approved;
      if (approval <= 3) {
        sent += 1;
      } else {
        saved += 1;
      }
    }

    all = sent + saved;

    let result = {
      no_of_allproject: all,
      no_of_send_project: sent,
      no_of_saved_project: saved
    };
    this.props.setNoOfProjects(result);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;

    const columns = [
      {
        dataField: "name",
        text: switchIntl(this.props.toggleLng).home.project_table_header_col1,
        sort: true
      },
      {
        dataField: "status",
        text: switchIntl(this.props.toggleLng).home.project_table_header_col2,
        sort: true
        // formatter: (cellContent, row) => {
        //   if (cellContent == 1) {
        //     return (
        //       <Button color="success" size="sm" round>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เปิดขาย
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //       </Button>
        //     );
        //   } else {
        //     return (
        //       <Button color="danger" size="sm" round>
        //         ต่ำกว่า 6 unit
        //       </Button>
        //     );
        //   }
        // }
      }
    ];

    const { SearchBar } = Search;

    const defaultSorted = [
      {
        dataField: "name",
        order: "asc"
      }
    ];

    return (
      <div>
        <GridContainer>
          {this.loadingScreen(this.state.loadingScreen)}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <h2 style={{ color: "black" }}>
              {switchIntl(this.props.toggleLng).home.appname}
            </h2>
            <h3 style={{ color: "black" }}>
              {switchIntl(this.props.toggleLng).home.project_status}
            </h3>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="info">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {switchIntl(this.props.toggleLng).home.project_all}
                </p>
                <h2 className={classes.cardTitle}>
                  {this.props.noOfProjects.no_of_allproject}
                </h2>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {switchIntl(this.props.toggleLng).home.project_sent}
                </p>
                <h2 className={classes.cardTitle}>
                  {this.props.noOfProjects.no_of_send_project}
                </h2>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {switchIntl(this.props.toggleLng).home.project_saved}
                </p>
                <h2 className={classes.cardTitle}>
                  {this.props.noOfProjects.no_of_saved_project}
                </h2>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <h3 className={classes.cardIconTitle}>
                  {switchIntl(this.props.toggleLng).home.project_map_header}
                </h3>
              </CardHeader>
              <CardBody>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={6}>
                    <Map></Map>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <ToolkitProvider
                      keyField="id"
                      data={this.state.projects}
                      columns={columns}
                      search
                    >
                      {props => (
                        <div>
                          <div>
                            <h5>
                              {
                                switchIntl(this.props.toggleLng).home
                                  .project_header_2
                              }
                              <SearchBar {...props.searchProps} />
                            </h5>
                          </div>
                          <BootstrapTable
                            {...props.baseProps}
                            striped
                            hover
                            condensed
                            bordered={false}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setLoading: bool => dispatch(setLoading(bool)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setNoOfProjects: payload => dispatch(setNoOfProjects(payload)),
  setPointsOnMap: payload => dispatch(setPointsOnMap(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(dashboardStyle)(Dashboard)));
