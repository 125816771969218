import TH from "translate/TH.json";
import EN from "translate/EN.json";

const switchIntl = (intl) => {
    if (intl == "TH"){
        return TH
    } else {
        return EN
    }
}
  
export default switchIntl;