import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
// @material-ui/icons
import ChartIcon from "@material-ui/icons/BarChart";
import TableChart from "@material-ui/icons/TableChart";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import moment from "moment";


import Graph from "views/Report/Graph.jsx";
import Graph2 from "views/Report/Graph2.jsx";
import FilterMain from "views/Report/FilterMain.jsx";
import FilterMainP from "views/Report/FilterMainP.jsx";
import FilterMainAll from "views/Report/FilterMainAll.jsx";
import FilterMainAlltoP from "views/Report/FilterMainAlltoP.jsx";
import FilterMainCP from "views/Report/FilterMainCP.jsx";
import FilterMainCP2 from "views/Report/FilterMainCP2.jsx";
import TableView from "views/Report/TableView.jsx";
import TableViewCP from "views/Report/TableViewCP.jsx";
import TableViewCP2 from "views/Report/TableViewCP2.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Button from "components/CustomButtons/Button.jsx";
//redux
import { connect } from "react-redux";
import setGraphData from "DataControl/actions/setGraphData.js";

import Map from "@material-ui/icons/Map";
import MapView from "views/Report/MapView.jsx";
import CryptoJS from "crypto-js";
import setPointsOnMap from "DataControl/actions/setPointsOnMap.js";
import Card from "components/Card/Card.jsx";

import {
  API_gen_secretkey,
  API_view_all_project,
  API_report_sell
} from "config/API.jsx";
import CardBody from "components/Card/CardBody";

const white = {
  color: "white"
};

const right = {
  float: "right"
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    multipleSelect: [],
    projectStatusSelect: [],
    prov: "",
    amphoe: "",
    tambon: "",
    selectedValue: "a",
    asset_type: "",
    view: 1
  };

  componentDidMount() {
    moment.locale("th");
  }
  handleFilter = (event, value) => {
    this.setState({ selectedValue: event.target.value });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  handleMultiple = event => {
    this.setState({ multipleSelect: event.target.value });
  };
  handleProjectStatus = event => {
    this.setState({ projectStatusSelect: event.target.value });
  };
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAssetType = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showGraph = type => {
    if (type === 1) {
      this.setState({ view: 1 });
    } else if (type === 2) {
      this.setState({ view: 2 });
    } else {
      this.setState({ view: 3 });
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Tabs
        headerColor="info"
        tabs={[
          {
            tabName: (
              <h4>
                <b>รายงานข้อมูลสมาชิก</b>
              </h4>
            ),
            tabContent: (
              <div>
                <GridContainer>
                  <GridItem xs={12}>
                    <Button
                      color={
                        this.state.view === 1 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(1)}
                    >
                      {" "}
                      <ChartIcon /> กราฟ
                    </Button>
                    <Button
                      color={
                        this.state.view === 2 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(2)}
                    >
                      {" "}
                      <TableChart /> ตาราง
                    </Button>
                    <Button
                      color={
                        this.state.view === 3 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(3)}
                    >
                      {" "}
                      <Map /> แผนที่
                    </Button>
                  </GridItem>
                  <GridItem xs={12}>
                    {this.state.view !== 3 && (
                      <CardBody>
                        <FilterMain />
                      </CardBody>
                    )}
                  </GridItem>
                </GridContainer>
                {this.state.view === 1 && <Graph />}
                {this.state.view === 2 && <TableView />}
                {this.state.view === 3 && (
                  <div style={{ height: "70vh", marginTop: "15px" }}>
                    <MapView></MapView>
                  </div>
                )}
              </div>
            )
          },
         
          // {
          //   tabName: (
          //     <h4>
          //       <b>รายงานข้อมูลสมาชิกทั้งหมด</b>
          //     </h4>
          //   ),
          //   tabContent: (
          //     <div>
          //       <GridContainer>
          //         <GridItem xs={12}>
          //           <Button
          //             color={
          //               this.state.view === 1 ? "info" : classes.classTable
          //             }
          //             onClick={() => this.showGraph(1)}
          //           >
          //             {" "}
          //             <ChartIcon /> กราฟ
          //           </Button>
          //           <Button
          //             color={
          //               this.state.view === 2 ? "info" : classes.classTable
          //             }
          //             onClick={() => this.showGraph(2)}
          //           >
          //             {" "}
          //             <TableChart /> ตาราง
          //           </Button>
          //           <Button
          //             color={
          //               this.state.view === 3 ? "info" : classes.classTable
          //             }
          //             onClick={() => this.showGraph(3)}
          //           >
          //             {" "}
          //             <Map /> แผนที่
          //           </Button>
          //         </GridItem>
          //         <GridItem xs={12}>
          //           {this.state.view !== 3 && (
          //             <CardBody>
                   
          //               <FilterMainAll />
          //             </CardBody>
          //           )}
          //         </GridItem>
          //       </GridContainer>
          //       {this.state.view === 1 && <Graph />}
          //       {this.state.view === 2 && <TableView />}
          //       {this.state.view === 3 && (
          //         <div style={{ height: "70vh", marginTop: "15px" }}>
          //           <MapView></MapView>
          //         </div>
          //       )}
          //     </div>
          //   )
          // },
          //  {
          //   tabName: (
          //     <h4>
          //       <b>ภาพรวมตลาด</b>
          //     </h4>
          //   ),
          //   tabContent: (
          //     <div>
          //       <GridContainer>
          //         <GridItem xs={12}>
          //           <Button
          //             color={
          //               this.state.view === 1 ? "info" : classes.classTable
          //             }
          //             onClick={() => this.showGraph(1)}
          //           >
          //             {" "}
          //             <ChartIcon /> กราฟ
          //           </Button>
          //           <Button
          //             color={
          //               this.state.view === 2 ? "info" : classes.classTable
          //             }
          //             onClick={() => this.showGraph(2)}
          //           >
          //             {" "}
          //             <TableChart /> ตาราง
          //           </Button>
          //           <Button
          //             color={
          //               this.state.view === 3 ? "info" : classes.classTable
          //             }
          //             onClick={() => this.showGraph(3)}
          //           >
          //             {" "}
          //             <Map /> แผนที่
          //           </Button>
          //         </GridItem>
          //         <GridItem xs={12}>
          //           {this.state.view !== 3 && (
          //             <CardBody>
          //               <FilterMainCP />
          //             </CardBody>
          //           )}
          //         </GridItem>
          //       </GridContainer>
          //       {this.state.view === 1 && <Graph />}
          //       {this.state.view === 2 && <TableViewCP />}
          //       {this.state.view === 3 && (
          //         <div style={{ height: "70vh", marginTop: "15px" }}>
          //           <MapView></MapView>
          //         </div>
          //       )}
          //     </div>
          //   )
          // },
          {
            tabName: (
              <h4>
                <b>ภาพรวมตลาด(2)</b>
              </h4>
            ),
            tabContent: (
              <div>
                <GridContainer>
                  <GridItem xs={12}>
                    <Button
                      color={
                        this.state.view === 1 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(1)}
                    >
                      {" "}
                      <ChartIcon /> กราฟ
                    </Button>
                    <Button
                      color={
                        this.state.view === 2 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(2)}
                    >
                      {" "}
                      <TableChart /> ตาราง
                    </Button>
                    <Button
                      color={
                        this.state.view === 3 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(3)}
                    >
                      {" "}
                      <Map /> แผนที่
                    </Button>
                  </GridItem>
                  <GridItem xs={12}>
                    {this.state.view !== 3 && (
                      <CardBody>
                        <FilterMainCP2 />
                      </CardBody>
                    )}
                  </GridItem>
                </GridContainer>
                {this.state.view === 1 && <Graph />}
                {this.state.view === 2 && <TableViewCP />}
                {this.state.view === 3 && (
                  <div style={{ height: "70vh", marginTop: "15px" }}>
                    <MapView></MapView>
                  </div>
                )}
              </div>
            )
          },
        ]}
      />
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  setGraphData: token => dispatch(setGraphData(token)),
  setPointsOnMap: payload => dispatch(setPointsOnMap(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
