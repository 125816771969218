import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// redux imports start
import { Provider } from "react-redux";
import configureStore from "DataControl/store/index.js";
// redux imports stop
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import Reset from "layouts/Reset.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";
// GHBank style sheet
// import "ghbank-style.css";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "assets/scss/custom.css";

const hist = createBrowserHistory();
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("session") !== null ? (
        <Component {...props} />
      ) : (
        <Redirect to="/auth" />
      )
    }
  />
);
const AuthenRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("session") !== null ? (
        <Redirect to="" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const ResetPasswordRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("session") !== null ? (
        <Redirect to="/auth" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

ReactDOM.render(
  <Provider store={configureStore()}>
    <Router history={hist} basename="/">
      <Switch>
        {/* <Route path="/auth" component={AuthLayout} />
        <Route path="" component={AdminLayout} /> */}
        <ResetPasswordRoute path="/reset" component={Reset} />
        <AuthenRoute path="/auth" component={AuthLayout} />
        <PrivateRoute path="" component={AdminLayout} />
        {localStorage.getItem("session") !== null ? (
          <Redirect from="/" to="/home" />
        ) : (
          <Redirect from="/" to="/auth/login" />
        )}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);