//ปิดการขาย
import * as L from 'leaflet';

const icon_house_red = L.icon({
    iconUrl: require('assets/img/icon/h_red.png'),
    iconSize:     [38, 40],
    iconAnchor:   [22, 38],
    popupAnchor:  [-3, -76]
});

// //อยู่ระหว่างขาย
// const icon_house_green_dark = L.icon({
//     iconUrl: require('assets/img/icon/h_green_dark.png'),
//     iconSize:     [38, 95],
//     iconAnchor:   [22, 94],
//     popupAnchor:  [-3, -76]
// });

// //เปิดขายใหม่ในช่วงสำรวจ
// const icon_house_green_light = L.icon({
//     iconUrl: require('assets/img/icon/h_green_light.png'),
//     iconSize:     [38, 95],
//     iconAnchor:   [22, 94],
//     popupAnchor:  [-3, -76]
// });

// //โครงการในอนาคต
// const icon_house_blue = L.icon({
//     iconUrl: require('assets/img/icon/h_blue.png'),
//     iconSize:     [38, 95],
//     iconAnchor:   [22, 94],
//     popupAnchor:  [-3, -76]
// });
// //โครงการชะลอการขาย
// const icon_house_yellow = L.icon({
//     iconUrl: require('assets/img/icon/h_yellow.png'),
//     iconSize:     [38, 95],
//     iconAnchor:   [22, 94],
//     popupAnchor:  [-3, -76]
// });

// //โครงการเปิดและปิดการขายในช่วงสำรวจ
// const icon_house_pink = L.icon({
//     iconUrl: require('assets/img/icon/h_pink.png'),
//     iconSize:     [38, 95],
//     iconAnchor:   [22, 94],
//     popupAnchor:  [-3, -76]
// });

export { icon_house_red };
