/* eslint-disable no-console */
/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as config from "config/API.jsx";
import CryptoJS from "crypto-js";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Place from "@material-ui/icons/Place";
import Language from "@material-ui/icons/Language";
import Call from "@material-ui/icons/Call";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import GHbankMap from "assets/img/map.jpg";
import SweetAlert from "react-bootstrap-sweetalert";
// switch language function
import switchIntl from "translate/switchIntl.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const initialState = {
  value: 0,
  alert: null,
  isSubmited: false,
  key: null,
  showForm: true,
  contact_name_state: null,
  contact_org_state: null,
  contact_phone_state: null,
  contact_email_state: null,
  contact_text_state: null,
  showFullPic: false
};

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.warningAlert = this.warningAlert.bind(this);
    this.successAlert = this.successAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.getSecretKey = this.getSecretKey.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
    loadingSet: PropTypes.string.isRequired
  };

  // handleChange = (event, value) => {
  //   this.setState({ value });
  // };
  // handleChangeIndex = index => {
  //   this.setState({ value: index });
  // };

  componentDidMount() {
    this.getSecretKey();
  }

  handleSubmit = () => {
    if (
      this.state.contact_name_state === "success" &&
      this.state.contact_org_state === "success" &&
      this.state.contact_phone_state === "success" &&
      this.state.contact_email_state === "success" &&
      this.state.contact_text_state === "success"
    ) {
      this.sendEmail();
      this.setState({ isSubmited: true });
    } else {
      this.warningAlert(
        switchIntl(this.props.toggleLng).contact.warn_fill_all_msg
      );
    }
  };

  openLink = value => {
    switch (value) {
      case "fb":
        window.open("https://www.facebook.com/REICFan");
        break;
      case "twt":
        window.open("https://twitter.com/REICFan");
        break;
      case "line":
        window.open("https://line.me/R/ti/p/@kfu5011k");
        break;
      case "yt":
        window.open("https://www.youtube.com/user/REICPR");
        break;
      default:
        break;
    }
  };

  validateThaiEnCharacters = (event, stateName) => {
    var input_data = event.target.value;
    const regex = /^[A-Za-zก-๙ ]*$/;
    let validCharacters = regex.test(input_data);
    if (validCharacters) {
      this.setState({ [stateName + "_state"]: "success" });
    } else {
      this.setState({ [stateName + "_state"]: "error" });
    }
    this.setState({ [stateName]: input_data });
  };

  validateEmail = (event, stateName) => {
    var email = event.target.value;
    const regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;
    let validEmail = regex.test(email);
    if (validEmail) {
      this.setState({ [stateName + "_state"]: "success" });
    } else {
      this.setState({ [stateName + "_state"]: "error" });
    }
    this.setState({ [stateName]: email });
  };

  validateMessage = (event, stateName) => {
    let msg = event.target.value;
    const regex = /^[. /0-9A-Za-zก-๙ ]*$/;
    let ValidCharacters = regex.test(msg);
    if ((event.target.value.length < 10) | !ValidCharacters) {
      this.setState({ [stateName + "_state"]: "error" });
    } else {
      this.setState({ [stateName + "_state"]: "success" });
    }
    this.setState({ [stateName]: msg });
  };

  validateNumber = (event, stateName) => {
    var tel = event.target.value;
    const regex = /^0[0-9]*$/;
    let validNumber = regex.test(tel);

    if (tel.length === 0 || !validNumber || tel.length > 11 || tel.length < 9) {
      this.setState({ [stateName + "_state"]: "error" });
    } else {
      this.setState({ [stateName + "_state"]: "success" });
    }
    this.setState({ [stateName]: tel });
  };

  warningAlert = msg => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={switchIntl(this.props.toggleLng).contact.sendmail}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          {msg}
        </SweetAlert>
      )
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={switchIntl(this.props.toggleLng).contact.get_msg}
          onConfirm={() => {
            this.hideAlert();
            this.reload();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          {switchIntl(this.props.toggleLng).contact.thankyou}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  reload = () => {
    this.setState({
      showForm: false
    });

    setTimeout(() => {
      this.setState(initialState);
    }, 100);
  };
  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });

    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          this.setState({
            key: responseJson.data
          });
        }
      })
      .catch(error => {
        // console.log(error);
      });
  }

  sendEmail = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    let self = this;
    let param = {
      name: this.state.contact_name,
      department: this.state.contact_org,
      tel: this.state.contact_phone,
      email: this.state.contact_email,
      msg: this.state.contact_text
    };

    console.log(param);

    var request = new Request(config.API_contact_us, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData
      }),
      body: JSON.stringify(param)
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          switch (data.code) {
            case 200:
              //success
              self.successAlert();
              break;
            default:
              //failed
              self.warningAlert(
                switchIntl(self.props.toggleLng).contact.try_again
              );
              break;
          }
        });
      })
      .catch(function(err) {
        console.log(err);
      });
    return false;
  };

  render() {
    const { classes } = this.props;
    let hrefLink = "#";

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });

    const google_map = (
      <iframe
        title="ghbank"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.281140314384!2d100.56771551483057!3d13.761912090340916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29e8953db66ef%3A0x54443a512159cbd4!2z4Lio4Li54LiZ4Lii4LmM4LiC4LmJ4Lit4Lih4Li54Lil4Lit4Liq4Lix4LiH4Lir4Liy4Lij4Li04Lih4LiX4Lij4Lix4Lie4Lii4LmMIOC4mOC4meC4suC4hOC4suC4o-C4reC4suC4hOC4suC4o-C4quC4h-C5gOC4hOC4o-C4suC4sOC4q-C5jA!5e0!3m2!1sen!2sth!4v1561955366377!5m2!1sen!2sth"
        width="100%"
        height="360px"
        frameBorder="0"
        style={{ border: 0, borderRadius: "6px", padding: "1px" }}
        allowFullScreen
      ></iframe>
    );

    const srp_content = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <a href={hrefLink} onClick={() => this.setState({ showFullPic: true })}>
          <img
            src={GHbankMap}
            alt="logo"
            style={{ width: "auto", height: "360px" }}
          />
        </a>
      </div>
    );

    return (
      <div>
        <GridContainer>
          {this.state.alert}
          <GridItem xs={12} sm={12} md={5} lg={4}>
            {this.state.showForm ? (
              <Card>
                <CardHeader>
                  <h4>{switchIntl(this.props.toggleLng).contact.sendmail}</h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <CustomInput
                      labelText={
                        switchIntl(this.props.toggleLng).contact.sendmail_name
                      }
                      id="contact_name"
                      success={this.state.contact_name_state === "success"}
                      error={this.state.contact_name_state === "error"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: event =>
                          this.validateThaiEnCharacters(event, "contact_name")
                      }}
                    />
                    <CustomInput
                      labelText={
                        switchIntl(this.props.toggleLng).contact.sendmail_org
                      }
                      id="contact_org"
                      success={this.state.contact_org_state === "success"}
                      error={this.state.contact_org_state === "error"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        autoComplete: "off",
                        onChange: event =>
                          this.validateThaiEnCharacters(event, "contact_org")
                      }}
                    />
                    <CustomInput
                      labelText={
                        switchIntl(this.props.toggleLng).contact.sendmail_phone
                      }
                      id="contact_phone"
                      success={this.state.contact_phone_state === "success"}
                      error={this.state.contact_phone_state === "error"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        autoComplete: "off",
                        onChange: event =>
                          this.validateNumber(event, "contact_phone")
                      }}
                    />
                    <CustomInput
                      labelText={
                        switchIntl(this.props.toggleLng).contact.sendmail_email
                      }
                      id="contact_email"
                      success={this.state.contact_email_state === "success"}
                      error={this.state.contact_email_state === "error"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        autoComplete: "off",
                        onChange: event =>
                          this.validateEmail(event, "contact_email")
                      }}
                    />
                    <p>
                      {switchIntl(this.props.toggleLng).contact.sendmail_text}{" "}
                    </p>
                    {this.state.contact_text_state === "error" ? (
                      <label style={{ color: "red" }}>
                        {switchIntl(this.props.toggleLng).contact.moredetail}
                      </label>
                    ) : null}
                    <textarea
                      // class="form-control"
                      style={{ width: "100%", borderColor: "#c3c3c3" }}
                      rows="5"
                      id="contact_text"
                      onChange={event =>
                        this.validateMessage(event, "contact_text")
                      }
                    ></textarea>
                    <Button color="rose" onClick={() => this.handleSubmit()}>
                      {switchIntl(this.props.toggleLng).contact.sendmail_bt}
                    </Button>
                  </form>
                </CardBody>
              </Card>
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={12} md={7} lg={8}>
            <Card>
              <CardHeader>
                <h3 style={{ fontWeight: "100", marginBottom: "20px" }}>
                  {switchIntl(this.props.toggleLng).contact.title}
                </h3>
              </CardHeader>
              <CardBody>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    className={classes.cardCategory}
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      color: "black",
                      paddingBottom: "10px"
                    }}
                  >
                    <Place /> {switchIntl(this.props.toggleLng).contact.address}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>
                    <a
                      href="https://www.reic.or.th/"
                      className={classes.cardCategory}
                      style={{
                        marginLeft: "10px",
                        fontSize: "18px",
                        color: "black",
                        paddingBottom: "10px"
                      }}
                    >
                      <Language />{" "}
                      {switchIntl(this.props.toggleLng).contact.url}
                    </a>
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    className={classes.cardCategory}
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      color: "black",
                      paddingBottom: "10px"
                    }}
                  >
                    <Call /> {switchIntl(this.props.toggleLng).contact.phone}
                  </p>
                </div>
                <div>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button
                      justIcon
                      round
                      color="facebook"
                      onClick={() => this.openLink.bind(this, "fb")}
                    >
                      <i className={" fab fa-facebook"} />
                    </Button>
                    <Button
                      justIcon
                      round
                      color="twitter"
                      onClick={() => this.openLink.bind(this, "twt")}
                    >
                      <i className={" fab fa-twitter"} />
                    </Button>
                    <Button
                      justIcon
                      round
                      color="success"
                      onClick={() => this.openLink.bind(this, "line")}
                    >
                      <i className={" fab fa-line"} />
                    </Button>
                    <Button
                      justIcon
                      round
                      color="danger"
                      onClick={() => this.openLink.bind(this, "yt")}
                    >
                      <i className={" fab fa-youtube"} />
                    </Button>
                  </GridItem>
                </div>

                <NavPills
                  color="warning"
                  tabs={[
                    {
                      tabButton: switchIntl(this.props.toggleLng).contact
                        .googlemap,
                      tabContent: google_map
                    },
                    {
                      tabButton: switchIntl(this.props.toggleLng).contact
                        .graphicmap,
                      tabContent: srp_content
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
          {this.state.showFullPic ? (
            <Dialog
              fullScreen
              open={this.state.showFullPic}
              transition={Transition}
              style={{ width: "100%" }}
              keepMounted
              onClose={() => this.setState({ showFullPic: false })}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description"
            >
              <DialogContent
                style={{ backgroundColor: "#eeeeee" }}
                id="modal-slide-description"
                className={classes.modalBody}
              >
                <Button
                  onClick={() => this.setState({ showFullPic: false })}
                  justIcon
                  round
                  color="white"
                  style={{ position: "absolute", right: "50px", zIndex: "100" }}
                >
                  <Close className={classes.icons} />
                </Button>
                <Card>
                  <img
                    src={GHbankMap}
                    alt="logo"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Card>
              </DialogContent>
            </Dialog>
          ) : null}
        </GridContainer>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(withStyles(sweetAlertStyle)(Contact));
