import * as React from "react";

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css"; // sass
import domtoimage from "dom-to-image";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import "assets/scss/custom.css";
import CryptoJS from "crypto-js";
//redux
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setLatlon from "DataControl/actions/setLatlon.js";
import setProv from "DataControl/actions/setProvAmphoeTambon.js";
import setMapImagePath from "DataControl/actions/setMapImagePath.js";

import { API_gen_secretkey,API_get_project_id,API_address_code, API_add_image, URL_SERVER } from "config/API";
import setProjectIDHome from "DataControl/actions/setProjectIDHome.js";
import { heremap_appID, heremap_appCode } from "config/API.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import "leaflet-switch-scale-control";
import "leaflet-switch-scale-control/src/L.Control.SwitchScaleControl.css";
import "leaflet.locatecontrol"

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const initialState = {
  path: require("assets/img/icon/h_red.png"),
  bbox_layer: L.layerGroup(),
  error: false,
  markerLayer: L.layerGroup(),
  mapPic: null,
  open: false,
  place: "tr",
  color: "success",
  message: "",
  tokenData: null,
};

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    let self = this;
    let osm_map = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      {
        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 19,
        minZoom: 8
      }
    );

    let url_heremap =
      "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png?app_id=" +
      heremap_appID +
      "&app_code=" +
      heremap_appCode;

    // let url_heremap = "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"

    let powermap = L.tileLayer(
      "https://search.map.powermap.in.th/api/v2/map/vtile/thailand_th/{z}/{x}/{y}.png?access_token=943364ad7b8956345d870a14c7855117d0dad5ee",
      {
        attribution: '&copy; <a href="http://powermap.in.th">Powermap</a>',
        maxZoom: 19,
        minZoom: 8
      }
    );

    let sattelite = L.tileLayer(url_heremap,
      {
        attribution: '&copy; <a href="https://www.here.com">HERE Technologies | HERE </a>',
        maxZoom: 19,
        minZoom: 8
      }
    );

    self.map = L.map(self.refs.map, {
      layers: [sattelite]
    }).setView([13, 100], 17);

    let baseMaps = {
      // eslint-disable-next-line prettier/prettier
      'ภาพดาวเทียม': sattelite,
      // eslint-disable-next-line prettier/prettier
      'OpenStreetMap': osm_map,
      // eslint-disable-next-line prettier/prettier
      'Powermap': powermap
    };

    self.state.markerLayer.addTo(self.map);

    self.state.bbox_layer.addTo(self.map);

    self.map.addControl(new L.Control.SwitchScaleControl({
      ratioCustomItemText: "1: ระบุเลข scale",
      updateWhenIdle: true,
      scales: [1000, 2500, 5000, 10000, 20000, 40000, 70000, 140000, 300000, 600000, 1200000, 2300000]
    }));
    
    L.control.locate().addTo(self.map);

    L.control.layers(baseMaps).addTo(self.map);

    self.map.on("click", function(e) {
      self.state.markerLayer.clearLayers();
      var popLocation = {
        lat: e.latlng.lat.toFixed(6),
        lng: e.latlng.lng.toFixed(6)
      };
      var popupContent =
        "{ " + e.latlng.lat.toFixed(6) + " " + e.latlng.lng.toFixed(6) + " }";
      var marker = L.marker(popLocation);
      marker.bindPopup(popupContent);
      self.state.markerLayer.addLayer(marker);
      self.props.setLatlon(popLocation);
    });
  }
  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    });
    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
            memberID : jsonDec.member_id
          });
          self.getProjectID();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getProjectID() {
    let self = this;
    var request = new Request(API_get_project_id, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData
      }),
      body:JSON.stringify({currentid:0})
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          console.log(data)
          self.setState({ loadingScreen: false });
          switch (data.code) {
            case 200:
              self.props.setProjectIDHome(data.data);
              break;
            default:
              //failed
              alert("Sorry, something went wrong...");
              break;
          }
        });
      })
      .catch(function() {});
  }
  componentDidUpdate(previousProps) {
    let self = this;
    
    if (previousProps.bbox !== this.props.bbox) {
      this.zoomto();
    }
    if (
      previousProps.latlon.lat !== this.props.latlon.lat ||
      previousProps.latlon.lng !== this.props.latlon.lng
    ) {
      this.state.markerLayer.clearLayers();
      var popLocation = {
        lat: this.props.latlon.lat,
        lng: this.props.latlon.lng
      };

      var popupContent =
        "{ " + this.props.latlon.lat + " " + this.props.latlon.lng + " }";
      var marker = L.marker(popLocation);
      marker.bindPopup(popupContent);
      this.state.markerLayer.addLayer(marker);
      this.resetErrorState();
      this.getAddressCode(this.props.latlon.lat, this.props.latlon.lng);
      this.map.setView(popLocation);
      if (this.props.latlon.lng !== 0 && this.props.latlon.lat !== 0) {
        
        self.showNotification("info", "กำลังบันทึกรูปภาพแผนที่ กรุณารอสักครู่");
        setTimeout(() => {
          self.makeImage();
        }, 2000);
      }
    }
  }

  getAddressCode(lat, lon) {
    let self = this;
    let data = {
      lat: lat,
      lon: lon
    };
    var request = new Request(API_address_code, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data)
    });
    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code === 200) {
          let address = data.data[0];
          self.setAddress(address);
        } else {
          self.setState({
            error: true
          });
        }
      });
  }

  resetErrorState() {
    this.setState({
      error: false
    });
  }

  setAddress(address) {
    let info = {
      prov_id: address.pro_id,
      amp_id: address.amp_id,
      tam_id: address.tam_id,
      prov_name: address.prov_name,
      amp_name: address.amp_name,
      tam_name: address.tam_name
    };
    this.props.setProv(info);
  }

  zoomto() {
    let self = this;
    try {
      let geojson = L.geoJson(JSON.parse(self.props.bbox));
      self.state.bbox_layer.clearLayers();
      geojson.addTo(self.state.bbox_layer);
      self.map.fitBounds(geojson.getBounds());
    } catch (err) {
      self.state.bbox_layer.clearLayers();
    }
  }

  makeImage() {
    var node = this.refs.map; // get the DOM node
    let that = this;
    domtoimage
      .toPng(node)
      .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;

        let img_base64 = dataUrl.split(",");

        that.setState(
          {
            mapPic: img_base64[1]
          },
          () => {
            that.saveMapImageToServer();
          }
        );
      })
      .catch(function(error) {
        // that.showNotification(
        //   "warning",
        //   "เกิดข้อผิดพลาดในการบันทึกภาพแผนที่ กรุณาปักหมุดใหม่"
        // );
        console.error("oops, something went wrong!", error);
      });
  }

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  saveMapImageToServer() {
    let self = this;
    let data = {
      projectid: self.props.projectIDHome,
      img: [self.state.mapPic],
      cat: "google_map",
      form: "house"
    };
    var request = new Request(API_add_image, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data)
    });
    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log(data);
        if (data.code === 200) {
          let picpath = data.data[0];
          self.props.setMapImagePath(picpath);
          self.showNotification("success", "บันทึกรูปภาพเรียบร้อย");
          if(!self.props.projectIDHome){
            self.getToken();
          }
        } else {
          self.setState({
            error: true
          });
        }
      });
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        {this.state.error && (
          <p style={{ color: "red" }}>
            พิกัดไม่ได้อยู่ในประเทศไทย กรุณาตรวจสอบพิกัดอีกครั้ง
          </p>
        )}
         {!this.props.isOld? null : (
          <div
            style={{
              cursor: "not-allowed",
              position: "absolute",
              height: "500px",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.1)",
              zIndex: "9999999"
            }}
          ></div>
        )}
        <div
          // eslint-disable-next-line react/no-string-refs
          ref="map"
          style={{ height: "500px", width: "100%", position: "relative" }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setLatlon: payload => dispatch(setLatlon(payload)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setProv: payload => dispatch(setProv(payload)),
  setMapImagePath: payload => dispatch(setMapImagePath(payload)),
  setProjectIDHome: payload => dispatch(setProjectIDHome(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);
