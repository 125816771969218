import Dashboard from "views/Dashboard/Dashboard.jsx";
import AllProject from "views/Project/AllProject.jsx";
import Report from "views/Report/Report.jsx";
import FAQ from "views/FAQ.jsx";
import UploadFile from "views/UploadFile.jsx";
import Manual from "views/Manual.jsx";
import ContactUs from "views/ContactUs.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import Login from "views/Pages/LoginPage.jsx";
import ForgetPasswordPage from "views/Pages/ForgetPassword.jsx";
import NewPasswordPage from "views/Pages/NewPassword.jsx";
import Profile from "views/Profile.jsx";
// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import ProjectIcon from "@material-ui/icons/Folder";
import ChartIcon from "@material-ui/icons/BarChart";
import FAQIcon from "@material-ui/icons/ContactSupport";
import ContactUsIcon from "@material-ui/icons/PermPhoneMsg";
import LibraryBook from "@material-ui/icons/LibraryBooks";

var dashRoutes = [
  {
    path: "/login",
    layout: "/auth",
    component: Login,
    invisible: true,
  },
  {
    path: "/home",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: HomeIcon,
    component: Dashboard,
    layout: "",
    thName: "หน้าหลัก"
  },
  {
    path: "/project",
    name: "Project",
    rtlName: "لوحة القيادة",
    icon: ProjectIcon,
    component: AllProject,
    layout: "",
    thName: "โครงการ"
  },
  {
    path: "/report",
    name: "Report",
    icon: ChartIcon,
    component: Report,
    layout: "",
    thName: "รายงาน"
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: FAQIcon,
    component: FAQ,
    layout: "",
    thName: "คำถามที่พบบ่อย"
  },
  {
    path: "/uploadfile",
    name: "Upload",
    icon: FAQIcon,
    component: UploadFile,
    layout: "",
    invisible: true,
    thName: "อัพโหลดโครงการเก่า"
  },
  {
    path: "/manual",
    name: "Manual",
    icon: LibraryBook,
    component: Manual,
    layout: "",
    thName: "คู่มือการใช้งาน"
  },
  {
    path: "/contact",
    name: "Contact us",
    icon: ContactUsIcon,
    component: ContactUs,
    layout: "",
    thName: "ติดต่อเรา"
  },
  {
    path: "/register-page",
    name: "Register Page",
    rtlName: "تسجيل",
    mini: "R",
    rtlMini: "صع",
    component: RegisterPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/reset-page",
    name: "Reset password Page",
    rtlName: "صفحة الخطأ",
    mini: "E",
    rtlMini: "البريد",
    component: ForgetPasswordPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/",
    name: "Reset password Page",
    rtlName: "صفحة الخطأ",
    mini: "E",
    rtlMini: "البريد",
    component: NewPasswordPage,
    layout: "/reset",
    invisible: true
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "صفحة الخطأ",
    mini: "P",
    rtlMini: "البريد",
    component: Profile,
    layout: "",
    invisible: true,
    thName: "ข้อมูลส่วนตัว"
  }
  // {
  //   path: "/profile",
  //   name: "ติดต่อเรา",
  //   icon: ContactUsIcon,
  //   component: Profile,
  //   layout: ""
  // }
];
export default dashRoutes;
