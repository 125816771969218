import React from "react";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setBoundary from "DataControl/actions/setBoundary.js";
import setProv from "DataControl/actions/setProvAmphoeTambon.js";

import { API_get_prov } from "config/API";
import { API_bbox_address } from "config/API";

class Address extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      selected_prov: 0,
      selected_amphoe: 0,
      selected_tambon: 0,
      province: [],
      amphoe: [],
      tambon: [],
      default_val: []
    };
    this.handleSelectProvince = this.handleSelectProvince.bind(this);
    this.handleSelectAmphoe = this.handleSelectAmphoe.bind(this);
    this.handleSelectTambon = this.handleSelectTambon.bind(this);
    this.getProvince = this.getProvince.bind(this);
    this.getAmphoe = this.getAmphoe.bind(this);
    this.getTambon = this.getTambon.bind(this);
    this.setAmphoe_tambon = this.setAmphoe_tambon.bind(this);
  }

  componentDidMount() {
    this.getProvince();
  }

  getProvince() {
    let self = this;
    let data = {
      pro_id: 0,
      amp_id: 0,
      tam_id: 0
    };
    var request = new Request(API_get_prov, {
      method: "POST",
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data)
    });
    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code === 200) {
          let prov=data.data.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
          this.setState({
            province: prov,
            selected_prov: prov[0].value
          });
          self.getAmphoe(0,0);
        }
      });
  }

  async getAmphoe(default_val_amp, default_val_tam) {
    let self = this;
    let province_id = parseInt(self.state.selected_prov);
    let province = {
      pro_id: province_id,
      amp_id: 0,
      tam_id: 0
    };
    var request = new Request(API_get_prov, {
      method: "POST",
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(province)
    });
    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code === 200) {
          let amphoe = data.data;
          this.setState({
            amphoe: amphoe
          });
          if (default_val_amp === 0) {
            this.setState({
              selected_amphoe: amphoe[0].value
            });
          } else {
            this.setState({
              selected_amphoe: default_val_amp
            });
          }
          self.getTambon(default_val_tam);
        }
      });
  }

  getTambon(default_val_tam) {
    let self = this;
    let province_id = parseInt(self.state.selected_prov);
    let amphoe_id = parseInt(self.state.selected_amphoe);
    let province_amphoe = {
      pro_id: province_id,
      amp_id: amphoe_id,
      tam_id: 0
    };

    var request = new Request(API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(province_amphoe)
    });

    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code === 200) {
          let tambon = data.data;
          this.setState({
            tambon: tambon
          });

          if (default_val_tam === 0) {
            this.setState({
              selected_tambon: tambon[0].value
            });
          } else {
            this.setState({
              selected_tambon: default_val_tam
            });
          }
        }
      });
  }

  handleSelectProvince(event) {
    this.setState(
      {
        selected_prov: event.target.value
      },
      () => {
        this.getAmphoe(0, 0);
        this.queryZoomExtend(event.target.value, 0, 0);
      }
    );
  }

  handleSelectAmphoe(event) {
    this.setState(
      {
        selected_amphoe: event.target.value
      },
      () => {
        let prov = this.state.province;
        let amphoe = event.target.value;
        this.getTambon(0);
        this.queryZoomExtend(prov, amphoe, 0);
      }
    );
  }

  handleSelectTambon(event) {
    this.setState(
      {
        selected_tambon: event.target.value
      },
      () => {
        let prov = this.state.province;
        let amphoe = this.state.amphoe;
        let tambon = event.target.value;
        this.queryZoomExtend(prov, amphoe, tambon);
      }
    );
  }

  queryZoomExtend(prov, amphoe, tambon) {
    let self = this;
    let data = {
      pro_id: prov,
      amp_id: amphoe,
      tam_id: tambon
    };

    var request = new Request(API_bbox_address, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data)
    });

    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code === 200) {
          //zoom extend...
          self.props.setBoundary(JSON.stringify(data.data));
        }
      });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.prov_amphoe_tambon !== this.props.prov_amphoe_tambon) {
      let prov_id = this.props.prov_amphoe_tambon.prov_id;
      let amp_id = this.props.prov_amphoe_tambon.amp_id;
      let tam_id = this.props.prov_amphoe_tambon.tam_id;

      if(prov_id && amp_id && tam_id){
        this.setState(
          {
            selected_prov: prov_id,
            selected_amphoe: amp_id,
            selected_tambon: tam_id
          },
          () => {
            this.setAmphoe_tambon(amp_id, tam_id);
          }
        );
      }
    }
  }

  async setAmphoe_tambon(amp_id, tam_id) {
    this.getAmphoe(amp_id, tam_id);
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    let province = this.state.province;
    let amphoe = this.state.amphoe;
    let tambon = this.state.tambon;

    // eslint-disable-next-line prettier/prettier
    let optionItems = province.map((prov) =>
      // eslint-disable-next-line react/jsx-key
      <MenuItem
        key={prov.value}
        id={prov.label}
        classes={{ root: classes.selectMenuItem }}
        value={prov.value}
      >
        {prov.label}
      </MenuItem>
    );

    let optionItems_amphoe = amphoe.map((amp) =>
      // eslint-disable-next-line react/jsx-key
      <MenuItem
        key={amp.value}
        id={amp.label}
        classes={{ root: classes.selectMenuItem }}
        value={amp.value}
      >
        {amp.label}
      </MenuItem>
    );

    let optionItems_tambon = tambon.map((tam) =>
      // eslint-disable-next-line react/jsx-key
      <MenuItem
        key={tam.value}
        id={tam.label}
        classes={{ root: classes.selectMenuItem }}
        value={tam.value}
      >
        {tam.label}
      </MenuItem>
    );

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="province" className={classes.selectLabel}>
                จังหวัด
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.selected_prov}
                onChange={this.handleSelectProvince}
                inputProps={{
                  name: "province",
                  id: "province"
                }}
              >
                {optionItems}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                อำเภอ
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.selected_amphoe}
                onChange={this.handleSelectAmphoe}
                inputProps={{
                  name: "amphoe",
                  id: "amphoe"
                }}
              >
                {optionItems_amphoe}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}>
                ตำบล
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.selected_tambon}
                onChange={this.handleSelectTambon}
                inputProps={{
                  name: "tambon",
                  id: "tambon"
                }}
              >
                {optionItems_tambon}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Address.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  tokenSession: token => dispatch(tokenSession(token)),
  setBoundary: payload => dispatch(setBoundary(payload)),
  setLanguage: payload => dispatch(setLanguage(payload)),
  setProv: payload => dispatch(setProv(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Address));